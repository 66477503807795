import { Euler, MathUtils, Vector3 } from 'three'

import { AnnotationType, UniqueAIObjectConnectionType } from '@tabeeb/enums'
import { Units } from '@tabeeb/modules/pointCloud/constants'
import { FEET_IN_METER } from '@tabeeb/modules/pointCloud/utils/measurements'

import { AutofillSource } from '../enums'

const PRECISION = 2

const getShape = (object) => {
  return object?.shape
}

const toPrecision = (value, precision = PRECISION) => {
  return parseFloat(value.toFixed(precision), 10)
}

export const getWidth = (object, scale) => {
  return Math.abs(getShape(object)?.Scale?.X ?? 0) * scale
}

export const getHeight = (object, scale) => {
  return Math.abs(getShape(object)?.Scale?.Y ?? 0) * scale
}

export const getDepth = (object, scale) => {
  return Math.abs(getShape(object)?.Scale?.Z ?? 0) * scale
}

const getDirectionVector = (object) => {
  const shape = getShape(object)
  if (shape.Type === AnnotationType.Polyline) {
    if (shape.Points.length < 2) {
      return null
    }

    const start = shape.Points[0]
    const end = shape.Points[shape.Points.length - 1]

    return new Vector3(end.X, end.Y, end.Z).sub(new Vector3(start.X, start.Y, start.Z)).normalize()
  }

  if (shape.Type === AnnotationType.Box || shape.Type === AnnotationType.Cylinder) {
    const normal = new Vector3(0, 1, 0)
    if (shape.Rotation) {
      normal.applyEuler(new Euler(shape.Rotation.X, shape.Rotation.Y, shape.Rotation.Z, 'XYZ'))
    }

    return normal
  }

  return null
}

export const getRollAngle = (object) => {
  const shape = getShape(object)
  if (!shape?.Rotation) {
    return 0
  }

  return MathUtils.radToDeg(MathUtils.euclideanModulo(shape.Rotation.X, 2 * Math.PI))
}

export const getPitchAngle = (object) => {
  const shape = getShape(object)
  if (!shape?.Rotation) {
    return 0
  }

  return MathUtils.radToDeg(MathUtils.euclideanModulo(shape.Rotation.Y, 2 * Math.PI))
}

export const getYawAngle = (object) => {
  const shape = getShape(object)
  if (!shape?.Rotation) {
    return 0
  }

  return MathUtils.radToDeg(MathUtils.euclideanModulo(shape.Rotation.Z, 2 * Math.PI))
}

export const getAzimuth = (object) => {
  const direction = getDirectionVector(object)
  if (direction === null) {
    return 0
  }

  if (direction.x === 0 && direction.z === 0) {
    return 0
  }

  direction.y = 0
  direction.normalize()

  const azimuth = Math.atan2(direction.x, direction.z)

  return MathUtils.radToDeg(MathUtils.euclideanModulo(Math.PI - azimuth, Math.PI * 2))
}

const findObjectById = (id, objects) => {
  return objects.find((o) => o.id === id)
}

export const getParent = (object, objects, root = false) => {
  if (!object) {
    return null
  }

  let current = findObjectById(object.id, objects)
  if (!current || current.uniqueAIObject.ParentConnections.length === 0) {
    return null
  }

  do {
    for (const connection of current.uniqueAIObject.ParentConnections) {
      if (connection.Type === UniqueAIObjectConnectionType.Projection) {
        continue
      }

      current = findObjectById(connection.Parent.Id, objects)
      if (current) {
        break
      }
    }
  } while (current && current.uniqueAIObject.ParentConnections.length > 0 && root)

  return current
}

export const getChildren = (object, objects) => {
  if (!object) {
    return []
  }

  return objects.filter((o) =>
    o.uniqueAIObject.ParentConnections.some(
      (c) => c.Type !== UniqueAIObjectConnectionType.Projection && c.Parent.Id === object?.uniqueAIObject?.Id
    )
  )
}

export const getAnchorPoint = (shape, worldRotation = new Euler(0, 0, 0)) => {
  if (shape.Anchor) {
    return new Vector3(shape.Anchor.X, shape.Anchor.Y, shape.Anchor.Z).applyEuler(worldRotation)
  }

  if (shape.Points?.length > 0) {
    return new Vector3(shape.Points[0].X, shape.Points[0].Y, shape.Points[0].Z).applyEuler(worldRotation)
  }

  return null
}

export const getAttachmentPoint = (shape, worldRotation = new Euler(0, 0, 0)) => {
  const anchor = getAnchorPoint(shape, worldRotation)
  if (!anchor) {
    return null
  }

  const up = new Vector3(0, shape.Scale.Y, 0).applyEuler(
    new Euler(
      shape.Rotation.X + worldRotation.x,
      shape.Rotation.Y + worldRotation.y,
      shape.Rotation.Z + worldRotation.z
    )
  )

  return anchor.add(up)
}

const getHorizontalDistance = (source, target, worldRotation) => {
  const sourceAnchor = getAnchorPoint(getShape(source), worldRotation)
  const targetAnchor = getAnchorPoint(getShape(target), worldRotation)

  if (!sourceAnchor || !targetAnchor) {
    return 0
  }

  return Math.sqrt(Math.pow(sourceAnchor.x - targetAnchor.x, 2) + Math.pow(sourceAnchor.z - targetAnchor.z, 2))
}

export const getHorizontalOffset = (object, objects, scale, worldRotation) => {
  const parent = getParent(object, objects)
  if (!parent) {
    return 0
  }

  return getHorizontalDistance(object, parent, worldRotation) * scale
}

const getVerticalDistance = (source, target, worldRotation) => {
  const sourceAnchor = getAnchorPoint(getShape(source), worldRotation)
  const targetAnchor = getAnchorPoint(getShape(target), worldRotation)

  if (!sourceAnchor || !targetAnchor) {
    return 0
  }

  return Math.abs(sourceAnchor.y - targetAnchor.y)
}

const getVerticalOffset = (object, objects, scale, worldRotation) => {
  const parent = getParent(object, objects)
  if (!parent) {
    return 0
  }

  return getVerticalDistance(object, parent, worldRotation) * scale
}

export const getElevation = (object, objects, scale, worldRotation) => {
  const parent = getParent(object, objects, true)
  if (!parent) {
    return 0
  }

  return getVerticalDistance(object, parent, worldRotation) * scale
}

export const getUniqueAIObjectPropertyAutofillValue = (
  object,
  property,
  { worldRotation = new Euler(0, 0, 0), worldScale = 1, unit = Units.Feet, objects = [] } = {}
) => {
  const unitScale = unit === Units.Meters ? 1 : FEET_IN_METER

  const scale = worldScale * unitScale

  const value = (() => {
    switch (property.AutofillSource) {
      case AutofillSource.Height:
        return getHeight(object, scale)
      case AutofillSource.Width:
        return getWidth(object, scale)
      case AutofillSource.Depth:
        return getDepth(object, scale)
      case AutofillSource.Azimuth:
        return getAzimuth(object)
      case AutofillSource.RollAngle:
        return getRollAngle(object)
      case AutofillSource.PitchAngle:
        return getPitchAngle(object)
      case AutofillSource.YawAngle:
        return getYawAngle(object)
      case AutofillSource.Elevation:
        return getElevation(object, objects, scale, worldRotation)
      case AutofillSource.HorizontalOffset:
        return getHorizontalOffset(object, objects, scale, worldRotation)
      case AutofillSource.VerticalOffset:
        return getVerticalOffset(object, objects, scale, worldRotation)
      default:
        return 0
    }
  })()

  return toPrecision(value, PRECISION)
}
