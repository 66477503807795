import { ControlType, FormItemType } from '../../../Enums'
import { generateId } from './generateTempId'

export function createTab(tabsList, mode, formId, tabNames) {
  let newTab = {}

  newTab.TempId = generateId(tabsList)
  newTab.Name = 'Tab Panel'
  newTab.Type = 'Tab'
  newTab.FormItemType = FormItemType.Tab
  newTab.Order = tabsList.length
  newTab.Tooltip = ''
  newTab.ControlType = ControlType.Tabs
  newTab.FormId = formId

  if (mode === 'import') {
    const tabs = []
    tabNames.map((tabName, index) => {
      const newTab = {
        Id: index + 1,
        Value: tabName,
        Order: index,
      }
      tabs.push(newTab)
    })
    newTab.Tabs = tabs
  } else {
    newTab.Tabs = [
      { TempId: 1, Value: 'Tab 1', Order: 0 },
      { TempId: 2, Value: 'Tab 2', Order: 1 },
    ]
  }

  return newTab
}
