import PropTypes from 'prop-types'
import { Stop, Mic, Close } from '@material-ui/icons'
import { Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'

import styles from './styles'

const useStyles = makeStyles(styles)

function ChatInput({
  currentMessage,
  isRecording,
  isFileReady,
  supportAudioMessages,
  recordingDuration,
  onStartRecording,
  onStopRecording,
  onKeyPress,
  onChangeText,
  onRemoveAudioFile,
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper component='form' className={classes.container}>
        {supportAudioMessages &&
          (isRecording ? (
            <IconButton className={classes.iconButton} onClick={onStopRecording} title='Stop recording'>
              <Stop className={classes.stopIcon} />
            </IconButton>
          ) : (
            <IconButton className={classes.iconButton} onClick={onStartRecording} title='Start recording'>
              <Mic />
            </IconButton>
          ))}

        {isRecording && <Typography>{recordingDuration}</Typography>}

        {!isRecording &&
          (isFileReady ? (
            <div className={classes.audioFile}>
              <Typography>{recordingDuration} Audio Recording</Typography>
              <IconButton className={classes.removeFileButton} onClick={onRemoveAudioFile} title='Remove recording'>
                <Close />
              </IconButton>
            </div>
          ) : (
            <InputBase
              className={classes.input}
              placeholder='Input Text Here'
              onKeyDown={onKeyPress}
              onChange={onChangeText}
              value={currentMessage}
            />
          ))}
      </Paper>
    </div>
  )
}

ChatInput.propTypes = {
  currentMessage: PropTypes.string.isRequired,
  isRecording: PropTypes.bool.isRequired,
  isFileReady: PropTypes.bool.isRequired,
  supportAudioMessages: PropTypes.bool.isRequired,
  recordingDuration: PropTypes.string.isRequired,
  onStartRecording: PropTypes.func.isRequired,
  onStopRecording: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onRemoveAudioFile: PropTypes.func.isRequired,
}

export default ChatInput
