import { put, takeLatest, select, all } from 'redux-saga/effects'

import loadGallery from '@tabeeb/modules/gallery/sagas/loadGallery'
import { ContentSharingType, ReviewStatus, TabPanel, TopicType } from '@tabeeb/enums'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { getIsCurrentUserReviewer } from '@tabeeb/modules/../users/selectors'
import { getContentSuccess } from '@tabeeb/modules/shared/content/actions'
import { onAddErrorNotification, onAddInfoNotification } from '@tabeeb/modules/notification/actions'
import { getChatMessagesByContentIdRequest } from '@tabeeb/modules/chat/actions'
import { getLoadedFormAnswersIdsFromChats } from '@tabeeb/modules/chat/selectors'
import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'
import { getGalleryItemRequest } from '@tabeeb/modules/gallery/actions'
import { getReviewByContentSharingId, getSourceContentReviews } from '@tabeeb/modules/contentSharings/selectors'
import { getIsTabOpen } from '@tabeeb/modules/sessionTabs/selectors'
import { setDefaultLeftPanel } from '@tabeeb/modules/sessionTabs/sagas'
import * as rawActions from '../actions'
import { getCurrentUserReview } from '../selectors'
import { signalrEvents, signalrConstants } from '../../signalr'
import contentDescription from './contentDescription'
import { getContentFormsRequest, getDeletedUserIdsWithAnsweredQuestions } from '../../forms/actions'

function* onGetContentSuccess({ payload }) {
  const { contentId } = payload

  yield put(rawActions.getContentReviewStatusRequest({ contentId }))
  yield put(rawActions.getContentReviewsRequest({ contentId }))
  yield put(getDeletedUserIdsWithAnsweredQuestions.request({ contentId }))
}

function* getContentReviewsSuccess({ response, payload }) {
  const reviewsList = response.data
  yield put(rawActions.setReviews(reviewsList))
}

function* requestRework() {
  const review = yield select(getCurrentUserReview)
  if (review) {
    yield put(rawActions.sendReviewFeedbackRequest(review))
  }
}

function* reloadSharedData(formAnswersIds) {
  const contentId = yield select(getContentId)
  const selectedGalleryItemId = yield select(getSelectedGalleryItemId)

  yield loadGallery({ preserveSelectedPage: true })
  yield put(getGalleryItemRequest({ pageId: selectedGalleryItemId }))

  for (const formAnswerId of formAnswersIds) {
    yield put(
      getChatMessagesByContentIdRequest({
        topicId: formAnswerId,
        topicType: TopicType.FormAnswer,
        contentId,
        messagesSkip: 0,
        messagesTake: 25,
      })
    )
  }
}

function* onReviewFeedbackSent({ payload }) {
  const review = payload
  const isReviewer = yield select(getIsCurrentUserReviewer)
  const contentId = yield select(getContentId)

  yield put(rawActions.updateContentReview({ review }))

  if (!isReviewer && contentId !== review.TargetContentId) {
    const formAnswersIds = review.FormAnswers.map((fa) => fa.Id)
    yield reloadSharedData(formAnswersIds)
  }
}

function* onContentReviewCreated({ payload }) {
  yield put(rawActions.addContentReview(payload))
}

function* completeReview({ payload }) {
  const { contentSharingId, isCopyRequired } = payload
  let review = null

  if (contentSharingId !== undefined) {
    review = yield select((state) => getReviewByContentSharingId(state, { contentSharingId }))
  } else {
    review = yield select(getCurrentUserReview)
  }

  if (review) {
    yield put(rawActions.completeReviewRequest({ ContentReviewData: review, isCopyRequired: isCopyRequired || false }))
  }
}

function* onContentReviewResumed({ payload }) {
  const review = payload
  const isReviewer = yield select(getIsCurrentUserReviewer)
  const contentId = yield select(getContentId)
  const selectedGalleryItemId = yield select(getSelectedGalleryItemId)
  yield put(rawActions.updateContentReview({ review }))

  if (isReviewer) {
    yield loadGallery({ preserveSelectedPage: true })
    yield put(getGalleryItemRequest({ pageId: selectedGalleryItemId }))
    yield put(getContentFormsRequest({ contentId }))
  }

  if (review.Type === ContentSharingType.MergeForReview) {
    const formAnswersIds = yield select((state) =>
      getLoadedFormAnswersIdsFromChats(state, { topicType: TopicType.FormAnswer })
    )

    yield reloadSharedData(formAnswersIds)
    yield put(getContentFormsRequest({ contentId }))
  }
}

function* onContentReviewCompleted({ payload }) {
  const review = payload
  const isReviewer = yield select(getIsCurrentUserReviewer)
  const contentId = yield select(getContentId)

  if (!isReviewer && contentId !== review.TargetContentId) {
    const formAnswersIds = review.FormAnswers.map((fa) => fa.Id)
    yield reloadSharedData(formAnswersIds)
  }

  const reviews = yield select(getSourceContentReviews)
  const isContentReviewsTabOpen = yield select((state) => getIsTabOpen(state, TabPanel.ContentReviews))
  if (reviews.length === 0 && isContentReviewsTabOpen) {
    yield setDefaultLeftPanel()
  }
}

function* revokeReviewSuccess() {
  const sessionName = yield select((state) => state.contentState.name)
  const message = `'${sessionName}' has been revoked successfully.`
  yield put(onAddInfoNotification({ message }))
}

function* revokeReviewFailed() {
  const sessionName = yield select((state) => state.contentState.name)
  const message = `Failed to revoke '${sessionName}'.`
  yield put(onAddErrorNotification({ message }))
}

function* onContentReworkRequested(action) {
  const [model] = action.payload

  yield put(rawActions.onReviewFeedbackSent(model))
}

function* onReviewCreated(action) {
  const [model] = action.payload

  yield put(rawActions.onContentReviewCreated(model))
}

function* onReviewCompleted(action) {
  const [model] = action.payload

  yield put(rawActions.onContentReviewCompleted(model))
}

function* onReviewRevoked(action) {
  const [model] = action.payload

  yield put(rawActions.onContentReviewRevoked(model))
}

function* onReviewResumed(action) {
  const [model] = action.payload

  yield put(rawActions.onContentReviewResumed(model))
}

function* onReviewUpdated(action) {
  const [updatedReview] = action.payload
  const existingReview = yield select((state) => getCurrentUserReview(state))
  const contentId = yield select((state) => state.contentState.contentId)

  let updateGallery = false
  let updateContentForms = false

  if (existingReview && updatedReview.Id === existingReview.Id) {
    if (updatedReview.PagesIds.length !== existingReview.PagesIds.length) {
      updateGallery = true
    }

    if (updatedReview.ContentFormsIds.length !== existingReview.ContentFormsIds.length) {
      updateContentForms = true
    }
  }

  yield put(rawActions.updateContentReview({ review: updatedReview }))

  if (updateGallery) {
    yield loadGallery({ preserveSelectedPage: true })
  }
  if (updateContentForms) {
    yield put(getContentFormsRequest({ contentId }))
  }
}

function* onContentReviewStatusChange(action) {
  const [newStatus] = action.payload
  yield put(rawActions.setContentReviewStatus(newStatus))
}

function* onBeginInContentReviewSuccess(action) {
  yield put(rawActions.setContentReviewStatus(ReviewStatus.InReview))
}

function* onSignalRReviewFeedbackSent(action) {
  const [contentName] = action.payload
  yield put(onAddInfoNotification({ message: `Feedback sent for '${contentName}'` }))
}

function* contentReviewsSaga() {
  yield all([
    takeLatest(getContentSuccess, onGetContentSuccess),
    takeLatest(rawActions.getContentReviewsSuccess, getContentReviewsSuccess),
    takeLatest(rawActions.requestRework, requestRework),
    takeLatest(rawActions.onReviewFeedbackSent, onReviewFeedbackSent),
    takeLatest(rawActions.onContentReviewCreated, onContentReviewCreated),
    takeLatest(rawActions.completeReview, completeReview),
    takeLatest(rawActions.onContentReviewResumed, onContentReviewResumed),
    takeLatest(rawActions.onContentReviewCompleted, onContentReviewCompleted),
    takeLatest(rawActions.revokeReviewSuccess, revokeReviewSuccess),
    takeLatest(rawActions.revokeReviewFailed, revokeReviewFailed),
    takeLatest(rawActions.beginInContentReviewSuccess, onBeginInContentReviewSuccess),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onContentReworkRequested, onContentReworkRequested),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onReviewCreated, onReviewCreated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onReviewCompleted, onReviewCompleted),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onReviewRevoked, onReviewRevoked),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onReviewResumed, onReviewResumed),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onReviewUpdated, onReviewUpdated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onContentReviewStatusChange, onContentReviewStatusChange),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onReviewFeedbackSent, onSignalRReviewFeedbackSent),
    contentDescription(),
  ])
}

export default contentReviewsSaga
