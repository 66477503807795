import { FormControl, FormLabel, styled } from '@mui/material'

export const OutlinedSliderFormControl = styled(FormControl)(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'rgba(0, 0, 0, 0.23)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  position: 'relative',
}))

export const OutlinedSliderFormControlLabel = styled(FormLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'absolute',
  top: -10,
  left: 10,
  paddingInline: theme.spacing(0.5),
  background: theme.palette.background.paper,
  ...theme.typography.caption,
}))
