import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { createAction } from 'redux-actions'

export const resetGalleryState = createAction('RESET_GALLERY_STATE')

export const setDeleteGalleryItem = createAction('SET_DELETE_GALLERY_ITEM')

export const renameGalleryItemRequest = createAction('RENAME_GALLERY_ITEM_REQUEST')
export const renameGalleryItemSuccess = createAction('RENAME_GALLERY_ITEM_SUCCESS')

export const deleteGalleryItem = createAction('DELETE_GALLERY_ITEM')
export const deleteGalleryItemRequest = createAction('DELETE_GALLERY_ITEM_REQUEST')
export const deleteGalleryItemSuccess = createAction('DELETE_GALLERY_ITEM_SUCCESS')
export const onDeleteGalleryItem = createAction('ON_DELETE_GALLERY_ITEM')

export const getGalleryItemRequest = createAction('GET_GALLERY_ITEM_REQUEST')
export const getGalleryItemSuccess = createAction('GET_GALLERY_ITEM_SUCCESS')

export const setGalleryItems = createAction('SET_GALLERY_ITEMS')
export const addGalleryItems = createAction('ADD_GALLERY_ITEMS')
export const updateGalleryItem = createAction('UPDATE_GALLERY_ITEM')
export const updateGalleryItemThumbnail = createAction('UPDATE_GALLERY_ITEM_THUMBNAIL')
export const updatePageAsset = createAction('UPDATE_PAGE_ASSET')
export const updateGalleryItemName = createAction('UPDATE_GALLERY_ITEM_NAME')
export const addGalleryItem = createAction('ADD_GALLERY_ITEM')
export const addVideoByUrl = createAction('ADD_VIDEO_BY_URL')

export const selectGalleryItem = createAction('SELECT_GALLERY_ITEM')
export const onSelectGalleryItem = createAction('ON_SELECT_GALLERY_ITEM')
export const onGalleryItemSwitching = createAction('ON_GALLERY_ITEM_SWITCHING')
export const onGalleryItemSwitched = createAction('ON_GALLERY_ITEM_SWITCHED')

export const changeGalleryOpenedMenu = createAction('CHANGE_GALLERY_OPENED_MENU')
export const changeGalleryDisplayAddVideoDialog = createAction('CHANGE_GALLERY_DISPLAY_ADD_VIDEO_DIALOG')

export const uploadGalleryVideoByUrlRequest = createAction('UPLOAD_GALLERY_VIDEO_BY_URL_REQUEST')
export const uploadGalleryVideoByUrlSuccess = createAction('UPLOAD_GALLERY_VIDEO_BY_URL_SUCCESS')
export const uploadGalleryVideoByUrlFailed = createAction('UPLOAD_GALLERY_VIDEO_BY_URL_FAILED')
export const onGalleryVideoByUrlUploaded = createAction('ON_GALLERY_VIDEO_BY_URL_UPLOADED')

export const addPageRequest = createAction('ADD_PAGE_REQUEST')
export const addPageSuccess = createAction('ADD_PAGE_SUCCESS')
export const addPageFailed = createAction('ADD_PAGE_FAILED')

export const addPagesRequest = createAction('ADD_PAGES_REQUEST')
export const addPagesSuccess = createAction('ADD_PAGES_SUCCESS')
export const addPagesFailed = createAction('ADD_PAGES_FAILED')

export const addDrawingPage = createAction('ADD_DRAWING_PAGE')
export const addDrawingPageRequest = createAction('ADD_DRAWING_PAGE_REQUEST')
export const addDrawingPageSuccess = createAction('ADD_DRAWING_PAGE_SUCCESS')

export const addPointCloudModel = createApiActions('ADD_POINT_CLOUD_MODEL')
export const addAutodeskPage = createApiActions('ADD_AUTODESK_PAGE')

export const showAddWebPageDialog = createAction('SHOW_ADD_WEB_PAGE_DIALOG')
export const hideAddWebPageDialog = createAction('HIDE_ADD_WEB_PAGE_DIALOG')

export const addWebPageUsingUrlRequest = createAction('ADD_WEB_PAGE_USING_URL_REQUEST')
export const addWebPageUsingUrlSuccess = createAction('ADD_WEB_PAGE_USING_URL_SUCCESS')
export const addWebPageUsingUrlFailed = createAction('ADD_WEB_PAGE_USING_URL_FAILED')

export const addGalleryUploadFileCancel = createAction('ADD_GALLERY_UPLOAD_FILE_CANCEL')

export const addGalleryFiles = createAction('ADD_GALLERY_FILES')
export const addGalleryUploadFiles = createAction('ADD_GALLERY_UPLOAD_FILES')
export const addGalleryUploadFilesList = createAction('ADD_GALLERY_UPLOAD_FILES_LIST')
export const addGalleryUploadFilesSuccess = createAction('ADD_GALLERY_UPLOAD_FILES_SUCCESS')
export const addGalleryUploadFilesFailed = createAction('ADD_GALLERY_UPLOAD_FILES_FAILED')
export const addGalleryUploadFilesCompleted = createAction('ADD_GALLERY_UPLOAD_FILES_COMPLETED')

export const addGalleryUploadFileFailed = createAction('ADD_GALLERY_UPLOAD_FILE_FAILED')
export const addGalleryUploadFileProgress = createAction('ADD_GALLERY_UPLOAD_FILE_PROGRESS')
export const addGalleryUploadFileSuccess = createAction('ADD_GALLERY_UPLOAD_FILE_SUCCESS')

export const addGalleryImagesUploadFiles = createAction('ADD_GALLERY_IMAGES_UPLOAD_FILES')
export const addGalleryImagesUploadFilesSuccess = createAction('ADD_GALLERY_IMAGES_UPLOAD_FILES_SUCCESS')
export const addGalleryImagesUploadFilesFailed = createAction('ADD_GALLERY_IMAGES_UPLOAD_FILES_FAILED')

export const addGalleryWordDocumentsUploadFiles = createAction('ADD_GALLERY_WORD_DOCUMENTS_UPLOAD_FILES')
export const addGalleryWordDocumentsUploadFilesSuccess = createAction('ADD_GALLERY_WORD_DOCUMENTS_UPLOAD_FILES_SUCCESS')
export const addGalleryWordDocumentsUploadFilesFailed = createAction('ADD_GALLERY_WORD_DOCUMENTS_UPLOAD_FILES_FAILED')

export const addGalleryImagesUploadParallelRequests = createAction('ADD_GALLERY_IMAGES_UPLOAD_PARALLEL_REQUESTS')
export const addGalleryImagesUploadParallelSuccess = createAction('ADD_GALLERY_IMAGES_UPLOAD_PARALLEL_SUCCESS')

export const addGalleryPdfDocumentsUploadFiles = createAction('ADD_GALLERY_PDF_DOCUMENTS_UPLOAD_FILES')
export const addGalleryPdfDocumentsUploadFilesSuccess = createAction('ADD_GALLERY_PDF_DOCUMENTS_UPLOAD_FILES_SUCCESS')
export const addGalleryPdfDocumentsUploadFilesFailed = createAction('ADD_GALLERY_PDF_DOCUMENTS_UPLOAD_FILES_FAILED')

export const addGalleryPdfDocumentRequest = createAction('ADD_GALLERY_PDF_DOCUMENT_REQUEST')
export const addGalleryPdfDocumentSuccess = createAction('ADD_GALLERY_PDF_DOCUMENT_SUCCESS')
export const addGalleryPdfDocumentFailed = createAction('ADD_GALLERY_PDF_DOCUMENT_FAILED')

export const addGalleryVideosUploadFiles = createAction('ADD_GALLERY_VIDEOS_UPLOAD_FILES')
export const addGalleryVideosUploadFilesSuccess = createAction('ADD_GALLERY_VIDEOS_UPLOAD_FILES_SUCCESS')
export const addGalleryVideosUploadFilesFailed = createAction('ADD_GALLERY_VIDEOS_UPLOAD_FILES_FAILED')

export const addGalleryVideosUploadParallelRequests = createAction('ADD_GALLERY_VIDEOS_UPLOAD_PARALLEL_REQUESTS')
export const addGalleryVideosUploadParallelSuccess = createAction('ADD_GALLERY_VIDEOS_UPLOAD_PARALLEL_SUCCESS')

export const getGalleryImagesBlobParallelRequests = createAction('GET_GALLERY_IMAGES_BLOB_PARALLEL_REQUESTS')
export const getGalleryImagesBlobParallelSuccess = createAction('GET_GALLERY_IMAGES_BLOB_PARALLEL_SUCCESS')

export const selectPdfRecipients = createAction('SELECT_PDF_RECIPIENTS')
export const exportAsPdf = createAction('EXPORT_AS_PDF')
export const exportAsPdfRequest = createAction('EXPORT_AS_PDF_REQUEST')

export const exportImagesRequest = createAction('EXPORT_IMAGES_REQUEST')
export const exportImagesSuccess = createAction('EXPORT_IMAGES_SUCCESS')

export const exportImagesArchiveToFtpRequest = createAction('EXPORT_IMAGES_ARCHIVE_TO_FTP_REQUEST')
export const exportImagesArchiveToFtpSuccess = createAction('EXPORT_IMAGES_ARCHIVE_TO_FTP_SUCCESS')
export const exportImagesArchiveToFtpFailed = createAction('EXPORT_IMAGES_ARCHIVE_TO_FTP_FAILED')

export const addGallerySnapshotsUploadFiles = createAction('ADD_GALLERY_SNAPSHOTS_UPLOAD_FILES')
export const addGallerySnapshotsUploadFilesSuccess = createAction('ADD_GALLERY_SNAPSHOTS_UPLOAD_FILES_SUCCESS')

export const addGallerySnapshotsUploadParallelRequests = createAction('ADD_GALLERY_SNAPSHOTS_UPLOAD_PARALLEL_REQUESTS')
export const addGallerySnapshotsUploadParallelSuccess = createAction('ADD_GALLERY_SNAPSHOTS_UPLOAD_PARALLEL_SUCCESS')
export const searchInPdf = createAction('SEARCH_IN_PDF')
export const updateSearchText = createAction('UPDATE_SEARCH_TEXT')
export const clearSearchText = createAction('CLEAR_SEARCH_TEXT')

export const updatePdfSearchResults = createAction('UPDATE_PDF_SEARCH_RESULTS')
export const clearPdfSearchResults = createAction('CLEAR_PDF_SEARCH_RESULTS')
export const showPdfSearchResults = createAction('SHOW_PDF_SEARCH_RESULTS')
export const hidePdfSearchResults = createAction('HIDE_PDF_SEARCH_RESULTS')

export const setSelectedPdfSearchText = createAction('SET_SELECTED_PDF_SEARCH_TEXT')

export const setSelectedSearchResult = createAction('SET_SELECTED_SEARCH_RESULT')
export const clearSelectedSearchResult = createAction('CLEAR_SELECTED_SEARCH_RESULT')

export const setPdfSearchMatchesCount = createAction('SET_PDF_SEARCH_MATCHES_COUNT')

export const togglePageResults = createAction('TOGGLE_PAGE_RESULTS')
export const addPageIndex = createAction('ADD_PAGE_INDEX')
export const removePageIndex = createAction('REMOVE_PAGE_INDEX')
export const clearDisabledPagesList = createAction('CLEAR_DISABLED_PAGES_LIST')

export const startLoading = createAction('START_LOADING')
export const stopLoading = createAction('STOP_LOADING')

export const openExportImagesDialog = createAction('OPEN_EXPORT_IMAGES_DIALOG')
export const closeExportImagesDialog = createAction('CLOSE_EXPORT_IMAGES_DIALOG')

export const setPageAsLoaded = createAction('SET_PAGE_AS_LOADED')

export const setGalleryItemThumbnailIsLoaded = createAction('SET_GALLERY_ITEM_THUMBNAIL_IS_LOADED')

export const getAssetsByContentIdRequest = createAction('GET_ASSETS_BY_CONTENT_ID_REQUEST')
export const getAssetsByContentIdSuccess = createAction('GET_ASSETS_BY_CONTENT_ID_SUCCESS')
export const getAssetsByContentIdFailed = createAction('GET_ASSETS_BY_CONTENT_ID_FAILED')

export const exportImages = createAction('EXPORT_IMAGES')
export const setExportImagesWithAnnotations = createAction('EXPORT_IMAGES_WITH_ANNOTATIONS')
export const setRequestInProgress = createAction('SET_REQUEST_IN_PROGRESS')
export const exportImagesIfTheyExist = createAction('EXPORT_IMAGES_IF_THEY_EXIST')

export const controllerPageSwitched = createAction('CONTROLLER_PAGE_SWITCHED')

export const addAnnotation = createAction('ADD_ANNOTATION')
export const addAnnotations = createAction('ADD_ANNOTATIONS')
export const updateAnnotation = createAction('UPDATE_ANNOTATION')
export const deleteAnnotations = createAction('DEL_ANNOTATION')

export const addEmailReceiver = createAction('ADD_EMAIL_RECEIVER')
export const deleteEmailReceiver = createAction('DELETE_EMAIL_RECEIVER')
export const setEmailRecipientsDialogState = createAction('SET_EMAIL_RECIPIENTS_DIALOG_STATE')

export const setExportEmailRecipients = createAction('SET_EXPORT_EMAIL_RECIPIENTS')

export const updatePageNameRequest = createAction('UPDATE_PAGE_NAME_REQUEST')
export const updatePageNameSuccess = createAction('UPDATE_PAGE_NAME_SUCCESS')

export const openPdfOptionsDialog = createAction('OPEN_PDF_OPTIONS_DIALOG')
export const closePdfOptionsDialog = createAction('CLOSE_PDF_OPTIONS_DIALOG')

export const setPdfOptionsDialogState = createAction('SET_PDF_OPTIONS_DIALOG_STATE')

export const openCustomVideoOptionsDialog = createAction('OPEN_CUSTOM_VIDEO_OPTIONS_DIALOG')
export const closeCustomVideoOptionsDialog = createAction('CLOSE_CUSTOM_VIDEO_OPTIONS_DIALOG')
export const setCustomVideoOptionsDialogState = createAction('SET_CUSTOM_VIDEO_OPTIONS_DIALOG_STATE')

export const openExportGalleryOptionsDialog = createAction('OPEN_EXPORT_GALLERY_OPTIONS_DIALOG')
export const closeExportGalleryOptionsDialog = createAction('CLOSE_EXPORT_GALLERY_OPTIONS_DIALOG')
export const setExportGalleryOption = createAction('SET_EXPORT_GALLERY_OPTION')

export const selectSessionFolder = createAction('SELECT_SESSION_FOLDER')

export const setFoldersList = createAction('SET_FOLDERS_LIST')

export const addSessionFolder = createAction('ADD_SESSION_FOLDER')
export const removeSessionFolder = createAction('REMOVE_SESSION_FOLDER')

export const addFolderToSpatialModel = createAction('ADD_FOLDER_TO_SPATIAL_VIEW')
export const addFolderToCustomVideo = createAction('ADD_FOLDER_TO_CUSTOM_VIDEO')
export const addFolderTo3dModel = createAction('ADD_FOLDER_TO_3D_MODEL')
export const addFolderToBentleyModel = createAction('ADD_FOLDER_TO_BENTLEY_MODEL')
export const addFolderForSharing = createAction('ADD_FOLDER_FOR_SHARING')
export const addFolderToStructureModel = createAction('ADD_FOLDER_TO_STRUCTURE_MODEL')
export const addFolderForReviewing = createAction('ADD_FOLDER_FOR_REVIEWING')
export const addFolderForMerging = createAction('ADD_FOLDER_FOR_MERGING')
export const addFolderToClassificationAnnotations = createAction('ADD_FOLDER_TO_CLASSIFICATION_ANNOTATIONS')
export const addFolderToSplatModel = createAction('ADD_FOLDER_TO_SPLAT_MODEL')

export const setDeleteSessionFolderId = createAction('SET_DELETE_SESSION_FOLDER_ID')
export const clearDeleteSessionFolderId = createAction('CLEAR_DELETE_SESSION_FOLDER_ID')

export const createSessionFolderRequest = createAction('CREATE_SESSION_FOLDER_REQUEST')
export const createSessionFolderFailed = createAction('CREATE_SESSION_FOLDER_FAILED')
export const createSessionFolderSuccess = createAction('CREATE_SESSION_FOLDER_SUCCESS')

export const onDeleteSessionFolder = createAction('ON_DELETE_SESSION_FOLDER')
export const deleteSessionFolderRequest = createAction('DELETE_SESSION_FOLDER_REQUEST')
export const deleteSessionFolderFailed = createAction('DELETE_SESSION_FOLDER_FAILED')
export const deleteSessionFolderSuccess = createAction('DELETE_SESSION_FOLDER_SUCCESS')

export const updateSessionFolder = createAction('UPDATE_SESSION_FOLDER')
export const renameSessionFolderRequest = createAction('RENAME_SESSION_FOLDER_REQUEST')
export const renameSessionFolderFailed = createAction('RENAME_SESSION_FOLDER_FAILED')
export const renameSessionFolderSuccess = createAction('RENAME_SESSION_FOLDER_SUCCESS')

export const updatePageSessionFolderRequest = createAction('UPDATE_PAGE_SESSION_FOLDER_REQUEST')
export const updatePageSessionFolderFailed = createAction('UPDATE_PAGE_SESSION_FOLDER_FAILED')
export const updatePageSessionFolderSuccess = createAction('UPDATE_PAGE_SESSION_FOLDER_SUCCESS')

export const onAddPageToSelection = createAction('ON_ADD_PAGE_TO_SELECTION')
export const addPageToSelection = createAction('ADD_PAGE_TO_SELECTION')
export const addPagesToSelection = createAction('ADD_PAGES_TO_SELECTION')

export const onRemovePageFromSelection = createAction('ON_REMOVE_PAGE_FROM_SELECTION')
export const removePageFromSelection = createAction('REMOVE_PAGE_FROM_SELECTION')
export const removePagesFromSelection = createAction('REMOVE_PAGES_FROM_SELECTION')

export const clearSelection = createAction('CLEAR_SELECTION')

export const onStartDraggingPages = createAction('ON_START_DRAGGING_PAGES')

export const enableExpandedGallery = createAction('ENABLE_EXPANDED_GALLERY')
export const disableExpandedGallery = createAction('DISABLE_EXPANDED_GALLERY')

export const addFilesToQueue = createAction('ADD_FILES_TO_QUEUE')
export const clearFilesQueue = createAction('CLEAR_FILES_QUEUE')
export const dequeueFilesFromQueue = createAction('DEQUEUE_FILES_FROM_QUEUE')

export const updateAiAnnotationsText = createAction('UPDATE_AI_ANNOTATIONS_TEXT')

export const setGalleryIsLoading = createAction('SET_GALLERY_IS_LOADING')

export const getPagesByContentIdRequest = createAction('GET_PAGES_BY_CONTENT_ID_REQUEST')
export const getPagesByContentIdSuccess = createAction('GET_PAGES_BY_CONTENT_ID_SUCCESS')
export const getPagesByContentIdFailed = createAction('GET_PAGES_BY_CONTENT_ID_FAILED')

export const galleryAddStructureModel = createAction('GALLERY_ADD_STRUCTURE_MODEL')
export const galleryAddTowerSideProfile = createAction('GALLERY_ADD_TOWER_SIDE_PROFILE')
export const galleryAdd2dLineDrawing = createAction('GALLERY_ADD_2D_LINE_DRAWING')
export const galleryAddSpatialModel = createAction('GALLERY_ADD_SPATIAL_MODEL')
export const galleryAdd3dModel = createAction('GALLERY_ADD_3D_MODEL')
export const galleryAddBentleyModel = createAction('GALLERY_ADD_BENTLEY_MODEL')
export const galleryAddBentleyAnalyticsReport = createAction('GALLERY_ADD_BENTLEY_ANALYTICS_REPORT')
export const galleryAddClassificationAnnotations = createAction('GALLERY_ADD_CLASSIFICATION_ANNOTATIONS')
export const galleryAddSplatModel = createAction('GALLERY_ADD_SPLAT_MODEL')

export const handleAddButtonMenuClick = createAction('HANDLE_ADD_BUTTON_MENU_CLICK')
export const handleExportButtonMenuClick = createAction('HANDLE_EXPORT_BUTTON_MENU_CLICK')
export const handleShareSessionOptionsButtonMenuClick = createAction('HANDLE_SHARE_SESSION_OPTIONS_BUTTON_MENU_CLICK')

export const getPagePosterLinkRequest = createAction('GET_PAGE_POSTER_LINK_REQUEST')
export const getPagePosterLinkSuccess = createAction('GET_PAGE_POSTER_LINK_SUCCESS')
export const getPagePosterLinkFailed = createAction('GET_PAGE_POSTER_LINK_FAILED')

export const getSessionFoldersRequest = createAction('GET_SESSION_FOLDERS_REQUEST')
export const getSessionFoldersSuccess = createAction('GET_SESSION_FOLDERS_SUCCESS')
export const getSessionFoldersFailed = createAction('GET_SESSION_FOLDERS_FAILED')

export const openEditFolderDialog = createAction('OPEN_EDIT_FOLDER_DIALOG')
export const closeEditFolderDialog = createAction('CLOSE_EDIT_FOLDER_DIALOG')

export const getContentUsersSessionFolderPermissions = createApiActions('GET_CONTENT_USERS_SESSION_FOLDER_PERMISSIONS')
export const updateSessionFolderPrivacy = createApiActions('UPDATE_SESSION_FOLDER_PRIVACY')
export const setPageFolderPermissions = createApiActions('SET_PAGE_FOLDER_PERMISSIONS')

export const setContentUsersPageFolderPermissions = createAction('SET_CONTENT_USERS_PAGE_FOLDER_PERMISSIONS')

export const updateOrAddAsset = createAction('UPDATE_OR_ADD_ASSET')

export const sendForTableRecognition = createApiActions('SEND_FOR_TABLE_RECOGNITION')

export const getPageMeasurementSettings = createApiActions('GET_PAGE_MEASUREMENT_SETTINGS')
export const updatePageMeasurementSettings = createApiActions('UPDATE_PAGE_MEASUREMENT_SETTINGS')

export const getSourcePagesAssets = createApiActions('GET_SOURCE_PAGES_ASSETS')
