import { put, takeLatest, select, all, race, take } from 'redux-saga/effects'

import { DisplayAnswersMode, ExportFormsToCsvOptions } from '@tabeeb/enums'
import getErrorMessageFromResponse from '@tabeeb/shared/utils/getErrorMessageFromResponse'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'

import * as rawActions from '../actions'

function* exportFormToPdfError({ response }) {
  const message = getErrorMessageFromResponse(response.data)
  yield put(onAddErrorNotification({ message }))
}

function exportFormAnswers(action) {
  const { data } = action.response
  const link = data
  window.open(link)
}

function* exportFormToCsv() {
  const contentId = yield select(getContentId)
  const { currentForm, selectedUserId, displayAnswersMode, exportFormsToCsvOptionState } = yield select(
    (state) => state.forms
  )
  const pageId = yield select((state) => state.gallery.galleryState.selectedGalleryItemId)
  const currentUserId = yield select(getCurrentUserId)

  if (!currentForm.ContentFormId) return

  let userId
  let selectedPageId
  switch (displayAnswersMode) {
    case DisplayAnswersMode.AllLatest:
      userId = null
      selectedPageId = null
      break
    case DisplayAnswersMode.AllMyLatest:
      userId = currentUserId
      selectedPageId = null
      break
    case DisplayAnswersMode.ByUser:
      userId = selectedUserId
      selectedPageId = pageId
      break
    default:
  }

  const model = {
    contentId,
    userId,
    pageId: selectedPageId,
    formsIds: exportFormsToCsvOptionState === ExportFormsToCsvOptions.CurrentForm ? [currentForm.FormId] : null,
  }

  yield put(rawActions.exportFormToCsvRequest(model))
}

function* exportFormToPdf() {
  const { currentForm, selectedUserId, displayAnswersMode } = yield select((state) => state.forms)
  const pageId = yield select((state) => state.gallery.galleryState.selectedGalleryItemId)
  const currentUserId = yield select(getCurrentUserId)

  if (!currentForm.ContentFormId) return

  const { selectedTabs } = currentForm

  let userId
  let selectedPageId
  switch (displayAnswersMode) {
    case DisplayAnswersMode.AllLatest:
      userId = null
      selectedPageId = null
      break
    case DisplayAnswersMode.AllMyLatest:
      userId = currentUserId
      selectedPageId = null
      break
    case DisplayAnswersMode.ByUser:
      userId = selectedUserId
      selectedPageId = pageId
      break
    default:
  }

  const model = {
    userId,
    contentFormId: currentForm.ContentFormId,
    formTabs: selectedTabs,
    pageId: selectedPageId,
  }

  yield put(rawActions.exportFormToPdfRequest(model))
}

function* handleExportFormToCustomCsv() {
  const contentFormId = yield select((state) => state.forms.currentForm.ContentFormId)
  if (!contentFormId) return
  const contentId = yield select(getContentId)

  yield put(
    rawActions.apiExportFormToCustomCsv.request({
      contentId,
      contentFormId,
    })
  )

  const { success } = yield race({
    success: take(rawActions.apiExportFormToCustomCsv.success),
    failed: take(rawActions.apiExportFormToCustomCsv.failed),
  })

  if (success) {
    const linkToReport = success.response.data

    window.open(linkToReport)
  } else {
    yield put(onAddErrorNotification({ message: 'Invalid form is selected' }))
  }
}

function* exportForm() {
  yield all([
    takeLatest(rawActions.exportFormToCsv, exportFormToCsv),
    takeLatest(rawActions.exportFormToCustomCsv, handleExportFormToCustomCsv),
    takeLatest(rawActions.exportFormToPdf, exportFormToPdf),
    takeLatest(rawActions.exportFormToPdfFailed, exportFormToPdfError),
    takeLatest([rawActions.exportFormAnswersSuccess, rawActions.exportFormToCsvSuccess], exportFormAnswers),
  ])
}

export default exportForm
