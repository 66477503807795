import { put, takeLatest, select, fork, all, take } from 'redux-saga/effects'

import selector from 'modules/shared/utils/selector'
import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'

import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { getIsCurrentUserReviewer } from '@tabeeb/modules/../users/selectors'
import { onAddInfoNotification, onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import * as rawActions from '../actions'
import { signalrEvents, signalrActions, signalrConstants } from '../../signalr'
import { getCurrentForm } from '../selectors'

function* setAvailableForms(formId, attached) {
  const { availableForms } = yield select((state) => state.forms)
  const updatedAvailableForms = availableForms.map((form) => {
    if (form.FormId === formId) {
      return {
        ...form,
        attached,
      }
    }
    return form
  })
  yield put(rawActions.updateAvailableForms(updatedAvailableForms))
}

function* prepareAttachingFormToContent(action) {
  const formId = action.payload.FormId
  const contentId = yield select(getContentId)
  const currentUserId = yield select(getCurrentUserId)

  const attachModel = {
    ContentId: contentId,
    FormId: formId,
    UserId: currentUserId,
  }

  yield put(rawActions.attachFormRequest(attachModel))
  yield fork(setAvailableForms, formId, true)
}

function* attachFormSuccess(action) {
  const attachModel = action.payload
  const formId = attachModel.FormId
  const contentId = yield select(getContentId)
  const formTitle = yield select(getFormTitle)

  yield put(onAddInfoNotification({ message: `${formTitle} attached to session successfully` }))
  yield put(signalrActions.invokeHubAction({ method: 'AttachForm', args: [contentId, formId] }))
  yield put(rawActions.setLastAttachedForm({ formId, isAttachedFromCondition: !!attachModel.isAttachedFromCondition }))
  yield put(rawActions.getContentFormRequest(attachModel))
}

function* formAttached(action) {
  const [{ formId, userId, showSwitchFormDialog }] = action.payload

  const isReviewer = yield select((state) => getIsCurrentUserReviewer(state))
  if (isReviewer) return

  const currentUserId = yield select(getCurrentUserId)
  const contentId = yield select(getContentId)
  const formTitle = yield select(getFormTitle)

  const isAttachedFromCondition = showSwitchFormDialog && currentUserId === userId

  yield put(onAddInfoNotification({ message: `${formTitle} attached to session successfully` }))
  yield put(rawActions.setLastAttachedForm({ formId, isAttachedFromCondition }))
  yield put(rawActions.getContentFormRequest({ ContentId: contentId, FormId: formId, UserId: userId }))

  yield fork(setAvailableForms, formId, true)
}

function* formDetached(action) {
  const [, userId, formId] = action.payload
  if (!formId) {
    return
  }

  const currentUserId = yield select(getCurrentUserId)
  if (currentUserId === userId) {
    return
  }

  yield put(rawActions.removeContentForm(formId))

  const formTitle = yield select(getFormTitle)

  yield put(onAddInfoNotification({ message: `${formTitle} detached from session successfully` }))
  yield fork(setAvailableForms, formId, false)
  const { currentForm, contentForms } = yield select((state) => state.forms)

  if (currentForm.FormId === formId && contentForms.length > 0) {
    yield put(rawActions.getFormControls.request({ contentFormId: contentForms[0].Id }))
  }

  if (contentForms.length === 0) {
    yield put(rawActions.unsetCurrentForm())
  }
}

function* formUpdated(action) {
  const formId = action.payload[1]
  const { currentForm, contentForms } = yield select((state) => state.forms)

  const updatedForm = contentForms.find((form) => form.Id === formId)

  if (!updatedForm) return

  if (currentForm && currentForm.Id === formId) {
    yield put(rawActions.updateReattachCount())
    yield put(rawActions.getFormControls.request({ contentFormId: updatedForm.Id }))

    const result = yield take([rawActions.getFormControls.success, rawActions.getFormControls.failed])
    if (result.type === rawActions.getFormControls.success().type) {
      const formTitle = yield select(getFormTitle)
      yield put(onAddInfoNotification({ message: `${formTitle} '${updatedForm.Name}' has been updated` }))
    }
  }

  updatedForm.ReattachedNumberOfTimes += 1
}

function* prepareDetachingFormToContent(action) {
  const { formId } = action.payload
  const contentState = yield select(selector.getContent)
  const { contentId } = contentState
  yield put(rawActions.detachFormRequest({ contentId, formId }))

  yield fork(setAvailableForms, formId, false)
}

function* handleDetachFormFailed({
  payload: { formId },
  response: {
    data: {
      Error: { Message },
    },
  },
}) {
  yield put(onAddErrorNotification({ message: Message }))
  yield fork(setAvailableForms, formId, true)
}

function* handleDetachFormSuccess({ payload: { formId } }) {
  const formTitle = yield select(getFormTitle)

  yield put(onAddInfoNotification({ message: `${formTitle} detached from session successfully` }))

  const contentId = yield select(getContentId)
  yield put(signalrActions.invokeHubAction({ method: 'DetachForm', args: [contentId, formId] }))

  yield put(rawActions.removeContentForm(formId))
  const { currentForm, contentForms } = yield select((state) => state.forms)

  if (currentForm.FormId === formId && contentForms.length > 0) {
    yield put(rawActions.getFormControls.request({ contentFormId: contentForms[0].Id }))
  }

  if (contentForms.length === 0) {
    yield put(rawActions.unsetCurrentForm())
  }
}

function* attachFormFailed({
  payload: { FormId },
  response: {
    data: {
      Error: { Message },
    },
  },
}) {
  yield put(onAddErrorNotification({ message: Message }))
  yield fork(setAvailableForms, FormId, false)
}

function* updateAvailableForms(action) {
  const { data } = action.response
  const { contentForms } = yield select((state) => state.forms)
  const updatedAvailableForms = data.map((form) => ({
    ...form,
    attached: !!contentForms.find((contentForm) => contentForm.FormId === form.FormId),
  }))
  yield put(rawActions.updateAvailableForms(updatedAvailableForms))
}

function* detachCurrentForm() {
  const contentId = yield select(getContentId)
  const currentForm = yield select(getCurrentForm)

  yield put(rawActions.detachFormRequest({ contentId, formId: currentForm.FormId }))
}

function* attachDetachForm() {
  yield all([
    takeLatest(rawActions.attachFormSuccess, attachFormSuccess),
    takeLatest(rawActions.attachFormFailed, attachFormFailed),
    takeLatest(rawActions.detachFormSuccess, handleDetachFormSuccess),
    takeLatest(rawActions.detachFormFailed, handleDetachFormFailed),
    takeLatest(rawActions.prepareAttachingForm, prepareAttachingFormToContent),
    takeLatest(rawActions.prepareDetachingForm, prepareDetachingFormToContent),
    takeLatest(
      [rawActions.getAvailableFormsSuccess, rawActions.getAvailableFormsByFolderIdSuccess],
      updateAvailableForms
    ),
    takeLatest(rawActions.detachCurrentForm, detachCurrentForm),
    // signalr
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onFormAttached, formAttached),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onFormDetached, formDetached),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onFormUpdated, formUpdated),
  ])
}

export default attachDetachForm
