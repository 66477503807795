import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, Divider, Grid } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { addressSchema } from '@tabeeb/modules/account/components/address'
import { FormikSelect, FormikTextField } from '@tabeeb/shared/forms'
import { GenderDisplayName, Genders } from '@tabeeb/enums'

import * as healthActions from '@tabeeb/modules/healthData/actions'
import { createInputProps, validateMobileUserEmail } from '@tabeeb/modules/healthData/helper'

import useStyles from './styles'

const changePhoneOrAddressSchema = Yup.object().shape({
  DateOfBirth: Yup.date()
    .min(new Date(Date.UTC(1900, 0, 0)), 'Date of birth is invalid')
    .max(new Date(), 'Date of birth is invalid')
    .typeError('Date of birth is invalid')
    .nullable(),
  Gender: Yup.string().nullable(),
  Address: addressSchema.fields.Address.nullable(),
})

const genderOptions = Object.keys(Genders).map((gender) => ({
  value: Genders[gender],
  name: GenderDisplayName[gender],
}))

const UserProfileForm = ({ patient, handleFormDisabling }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleSubmit = useCallback(
    (data) => {
      const userSetting = {
        ...data,
        FirstName: data.FirstName?.trim(),
        LastName: data.LastName?.trim(),
        DateOfBirth:
          data.DateOfBirth && data.DateOfBirth.length !== 0 ? new Date(data.DateOfBirth).toDateString() : null,
      }
      dispatch(healthActions.onUpdatePatientInfo(userSetting))
      handleFormDisabling()
    },
    [dispatch, handleFormDisabling]
  )

  return (
    <Formik
      initialValues={patient}
      enableReinitialize
      validationSchema={changePhoneOrAddressSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, dirty, resetForm, values, setFieldValue, errors }) => (
        <Form autoComplete='off'>
          <Grid item className={classes.flexCenteredGrid}>
            <KeyboardDatePicker
              style={{ width: '100%' }}
              autoOk
              inputVariant='standard'
              format='MM/dd/yyyy'
              name='DateOfBirth'
              value={(values.DateOfBirth && new Date(values.DateOfBirth)) || null}
              onChange={(date) => setFieldValue('DateOfBirth', date)}
              error={Boolean(errors.DateOfBirth)}
              helperText={errors.DateOfBirth}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              title='The required date format is MM/DD/YYYY'
              InputProps={createInputProps('Date of birth', classes.adornmentText)}
            />
          </Grid>
          <Grid item className={classes.flexCenteredGrid}>
            <Field
              name='PhoneNumber'
              variant='standard'
              component={FormikTextField}
              disabled
              InputProps={createInputProps('Phone', classes.adornmentText)}
              className={classes.inputText}
            />
          </Grid>
          {!validateMobileUserEmail(patient.Email) && (
            <Grid item className={classes.flexCenteredGrid}>
              <Field
                name='Email'
                variant='standard'
                disabled
                InputProps={createInputProps('Email', classes.adornmentText)}
                component={FormikTextField}
                className={classes.inputText}
              />
            </Grid>
          )}
          <Grid item>
            <Field
              name='Gender'
              variant='standard'
              selectInputProps={createInputProps('Gender', classes.adornmentText)}
              component={FormikSelect}
              options={genderOptions}
              value={values.Gender ?? ''}
              className={classes.inputText}
            />
          </Grid>
          <Divider className={classes.divider} />
          <Grid item container direction='column'>
            <Field
              name='Address.Country'
              variant='standard'
              InputProps={createInputProps('Country', classes.adornmentText)}
              component={FormikTextField}
              className={classes.inputText}
            />
            <Field
              name='Address.State'
              variant='standard'
              InputProps={createInputProps('State/Province', classes.adornmentText)}
              component={FormikTextField}
              className={classes.inputText}
            />
            <Field
              name='Address.City'
              variant='standard'
              InputProps={createInputProps('City', classes.adornmentText)}
              component={FormikTextField}
              className={classes.inputText}
            />
            <Field
              name='Address.PostalCode'
              variant='standard'
              InputProps={createInputProps('Zip/Postal Code', classes.adornmentText)}
              component={FormikTextField}
              className={classes.inputText}
            />
            <Field
              name='Address.Line1'
              variant='standard'
              InputProps={createInputProps('Address1', classes.adornmentText)}
              component={FormikTextField}
              className={classes.inputText}
            />
          </Grid>
          <Grid item className={classes.flexCenteredGrid}>
            <Field
              name='Address.Line2'
              variant='standard'
              InputProps={createInputProps('Address2', classes.adornmentText)}
              component={FormikTextField}
              className={classes.inputText}
            />
          </Grid>
          <Grid item container direction='row' spacing={2} className={classes.buttons}>
            <Grid item xs={6}>
              <Button
                color='primary'
                fullWidth
                variant='outlined'
                onClick={() => {
                  handleFormDisabling()
                  resetForm()
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button disabled={!(isValid && dirty)} fullWidth color='primary' variant='outlined' type='submit'>
                Submit
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Form>
      )}
    </Formik>
  )
}

UserProfileForm.propTypes = {
  patient: PropTypes.shape({
    PhoneNumber: PropTypes.string,
    Email: PropTypes.string.isRequired,
    Gender: PropTypes.number,
    Address: PropTypes.shape({
      Country: PropTypes.string,
      State: PropTypes.string,
      City: PropTypes.string,
      PostalCode: PropTypes.string,
      Line1: PropTypes.string,
      Line2: PropTypes.string,
    }),
    SsoOrMobileUser: PropTypes.bool.isRequired,
  }).isRequired,
  handleFormDisabling: PropTypes.func.isRequired,
}

export default UserProfileForm
