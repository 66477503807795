import PropTypes from 'prop-types'

import MDEditor from '@uiw/react-md-editor'
import { FormHelperText } from '@mui/material'
import { useCallback } from 'react'

const MarkdownEditor = ({ error, value, onChange, ...rest }) => {
  const commandsFilter = useCallback((command) => {
    if (command.keyCommand === 'image') {
      return false
    }

    return command
  }, [])

  return (
    <>
      <MDEditor {...rest} data-color-mode='light' commandsFilter={commandsFilter} value={value} onChange={onChange} />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  )
}

MarkdownEditor.propTypes = {
  error: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MarkdownEditor
