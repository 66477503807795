/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AIAnnotationExternalCreateRequest {
  /**
   *
   *
   * 0 = StrokeDraw
   *
   * 1 = StrokeErase
   *
   * 2 = Laser
   *
   * 3 = Text
   *
   * 4 = Audio
   *
   * 5 = Text_Callout
   *
   * 6 = HotSpot
   *
   * 7 = LinkedPage
   *
   * 8 = AI
   *
   * 9 = AIHotspot
   *
   * 10 = Box
   *
   * 11 = Distance
   *
   * 12 = Model
   *
   * 13 = Height
   *
   * 14 = Area
   *
   * 15 = Cylinder
   *
   * 16 = Polyline
   *
   * 100 = StrokeDraw_Shape_Line
   *
   * 101 = StrokeDraw_Shape_Rectangle
   *
   * 102 = StrokeDraw_Shape_Ellipse
   *
   * 103 = StrokeDraw_Shape_Rectangle_Filled
   *
   * 104 = StrokeDraw_Shape_Ellipse_Filled
   *
   * 105 = StrokeDraw_Shape_Line_ArrowEnd
   *
   * 106 = StrokeDraw_Shape_Line_ArrowBoth
   *
   * 107 = Polygon
   */
  Type?: AnnotationType
  /**
   * Unique id of the AI object within the system
   * @format int32
   */
  AIObjectId: number
  /**
   * Unique id of the unique AI object within the system
   * @format int32
   */
  UniqueAIObjectId?: number | null
  /**
   * Coordinates of the annotation. Required for annotations with types:
   * <br>StrokeDraw,
   * <br>StrokeDraw_Shape_Line,
   * <br>StrokeDraw_Shape_Rectangle,
   * <br>StrokeDraw_Shape_Ellipse,
   * <br>StrokeDraw_Shape_Rectangle_Filled,
   * <br>StrokeDraw_Shape_Ellipse_Filled,
   * <br>StrokeDraw_Shape_Line_ArrowEnd,
   * <br>StrokeDraw_Shape_Line_ArrowBoth,
   * <br>Polygon
   */
  Coordinates?: Coordinates[] | null
  Anchor?: Point
  Rotation?: EulerRotation
  Scale?: Scale
  /** Annotation name */
  Name?: string | null
  /**
   * Unique id of the page within the system
   * @format int32
   */
  PageId: number
  /**
   * The time on video/audio when an annotation has been created. Optional
   * @format float
   * @default -1
   */
  Timestamp?: number
  /**
   * The color of the annotation. Optional
   * @default "#FB000E"
   */
  Color?: string | null
  /**
   * The width of the annotation lines/font. Optional
   * @format int32
   * @default 3
   */
  Width?: number
  /**
   * Unique id of the parent annotation within the system. Optional
   * @format int32
   */
  ParentAnnotationId?: number | null
}

export interface AIExpressionCompletionResponse {
  /** Expression name */
  Name?: string | null
  /** Expression description */
  Description?: string | null
  /** Expression nodes */
  Nodes?: AIExpressionNodeCompletionResponse[] | null
  /** Expression edges */
  Edges?: AIExpressionEdgeCreateRequest[] | null
}

export interface AIExpressionCreateRequest {
  /**
   * @minLength 1
   * @maxLength 128
   */
  Name: string
  /** @maxLength 512 */
  Description?: string | null
  /** @minItems 1 */
  Nodes: AIExpressionNodeCreateRequest[]
  Edges?: AIExpressionEdgeCreateRequest[] | null
}

export interface AIExpressionDetailedResponse {
  Nodes?: AIExpressionNodeResponse[] | null
  Edges?: AIExpressionEdgeResponse[] | null
  Name?: string | null
  Description?: string | null
  /** @format date-time */
  CreatedOn?: string | null
  IsCreatedByAI?: boolean
  /** @format int32 */
  Id?: number
}

export interface AIExpressionEdgeCreateRequest {
  /**
   * @format int32
   * @min 0
   * @max 1
   * @default 0
   */
  Order?: number
  /**
   * Source node tag
   * @minLength 1
   */
  SourceNodeTag: string
  /**
   * Target node tag
   * @minLength 1
   */
  TargetNodeTag: string
}

export interface AIExpressionEdgeResponse {
  /**
   * Edge order
   * @format int32
   */
  Order?: number
  /**
   * Source node id
   * @format int32
   */
  SourceNodeId?: number
  /**
   * Target node id
   * @format int32
   */
  TargetNodeId?: number
  /** @format int32 */
  Id?: number
}

export interface AIExpressionExecuteRequest {
  /**
   * Image url
   * @minLength 1
   */
  ImageUrl: string
}

export interface AIExpressionNodeCompletionResponse {
  Class?: AIObjectResponse
  Model?: AIModelAttachResponse
  /**
   * Node id
   * @format int32
   */
  Id?: number | null
  /** Unique tag */
  Tag?: string | null
  /**
   * Unique id of the AI Object within the system
   * @format int32
   */
  ClassId?: number | null
  /**
   * Unique id of the AI Model within the system
   * @format int32
   */
  ModelId?: number | null
  /**
   *
   *
   * 0 = Multiply
   *
   * 1 = Add
   *
   * 2 = Subtract
   */
  ArithmeticOperation?: ArithmeticOperation
  /**
   *
   *
   * 0 = And
   *
   * 1 = Or
   */
  LogicalOperation?: LogicalOperation
  /**
   *
   *
   * 0 = Count
   *
   * 1 = Max
   *
   * 2 = Min
   *
   * 3 = Sum
   */
  ReductionOperation?: ReductionOperation
  /**
   *
   *
   * 0 = Union
   *
   * 1 = Intersection
   */
  SetOperation?: SetOperation
  /**
   *
   *
   * 0 = Contains
   *
   * 1 = Matches
   *
   * 2 = Overlaps
   */
  BoundingBoxOperation?: BoundingBoxOperation
  /**
   *
   *
   * 0 = Equals
   *
   * 1 = NotEquals
   *
   * 2 = Greater
   *
   * 3 = GreaterOrEquals
   *
   * 4 = Less
   *
   * 5 = LessOrEquals
   */
  ComparisonOperation?: ComparisonOperation
  /**
   * String value
   * @maxLength 512
   */
  StringValue?: string | null
  /** Boolean value */
  BooleanValue?: boolean | null
  /**
   * Integer value
   * @format int32
   */
  IntegerValue?: number | null
  /**
   * X coordinate of node on viewer
   * @format double
   * @min -99999.99
   * @max 99999.99
   */
  X: number
  /**
   * Y coordinate of node on viewer
   * @format double
   * @min -99999.99
   * @max 99999.99
   */
  Y: number
  /**
   *
   *
   * 1 = ClassFilter
   *
   * 2 = ModelRunner
   *
   * 3 = Reducer
   *
   * 4 = SetComposer
   *
   * 5 = LogicalValuesComposer
   *
   * 6 = ConstantNumber
   *
   * 7 = ConstantString
   *
   * 8 = ConstantBoolean
   *
   * 9 = PairSetComposer
   *
   * 10 = ReductionFilter
   *
   * 11 = Projector
   *
   * 12 = ValuesComparator
   *
   * 13 = ValuesCalculator
   */
  Type: AIExpressionNodeType
  /**
   *
   *
   * 1 = Boolean
   *
   * 2 = Date
   *
   * 3 = DateTime
   *
   * 4 = Decimal
   *
   * 5 = Float
   *
   * 6 = Integer
   *
   * 7 = String
   *
   * 8 = Set
   *
   * 9 = PairSet
   */
  ResultType: AIExpressionNodeResultType
  /**
   * Prediction threshold
   * @format double
   */
  PredictionThreshold?: number | null
  /**
   * Intersection over Union (IoU)
   * @format double
   */
  IntersectionOverUnion?: number | null
}

export interface AIExpressionNodeCreateRequest {
  /**
   * Node id
   * @format int32
   */
  Id?: number | null
  /** Unique tag */
  Tag?: string | null
  /**
   * Unique id of the AI Object within the system
   * @format int32
   */
  ClassId?: number | null
  /**
   * Unique id of the AI Model within the system
   * @format int32
   */
  ModelId?: number | null
  /**
   *
   *
   * 0 = Multiply
   *
   * 1 = Add
   *
   * 2 = Subtract
   */
  ArithmeticOperation?: ArithmeticOperation
  /**
   *
   *
   * 0 = And
   *
   * 1 = Or
   */
  LogicalOperation?: LogicalOperation
  /**
   *
   *
   * 0 = Count
   *
   * 1 = Max
   *
   * 2 = Min
   *
   * 3 = Sum
   */
  ReductionOperation?: ReductionOperation
  /**
   *
   *
   * 0 = Union
   *
   * 1 = Intersection
   */
  SetOperation?: SetOperation
  /**
   *
   *
   * 0 = Contains
   *
   * 1 = Matches
   *
   * 2 = Overlaps
   */
  BoundingBoxOperation?: BoundingBoxOperation
  /**
   *
   *
   * 0 = Equals
   *
   * 1 = NotEquals
   *
   * 2 = Greater
   *
   * 3 = GreaterOrEquals
   *
   * 4 = Less
   *
   * 5 = LessOrEquals
   */
  ComparisonOperation?: ComparisonOperation
  /**
   * String value
   * @maxLength 512
   */
  StringValue?: string | null
  /** Boolean value */
  BooleanValue?: boolean | null
  /**
   * Integer value
   * @format int32
   */
  IntegerValue?: number | null
  /**
   * X coordinate of node on viewer
   * @format double
   * @min -99999.99
   * @max 99999.99
   */
  X: number
  /**
   * Y coordinate of node on viewer
   * @format double
   * @min -99999.99
   * @max 99999.99
   */
  Y: number
  /**
   *
   *
   * 1 = ClassFilter
   *
   * 2 = ModelRunner
   *
   * 3 = Reducer
   *
   * 4 = SetComposer
   *
   * 5 = LogicalValuesComposer
   *
   * 6 = ConstantNumber
   *
   * 7 = ConstantString
   *
   * 8 = ConstantBoolean
   *
   * 9 = PairSetComposer
   *
   * 10 = ReductionFilter
   *
   * 11 = Projector
   *
   * 12 = ValuesComparator
   *
   * 13 = ValuesCalculator
   */
  Type: AIExpressionNodeType
  /**
   *
   *
   * 1 = Boolean
   *
   * 2 = Date
   *
   * 3 = DateTime
   *
   * 4 = Decimal
   *
   * 5 = Float
   *
   * 6 = Integer
   *
   * 7 = String
   *
   * 8 = Set
   *
   * 9 = PairSet
   */
  ResultType: AIExpressionNodeResultType
  /**
   * Prediction threshold
   * @format double
   */
  PredictionThreshold?: number | null
  /**
   * Intersection over Union (IoU)
   * @format double
   */
  IntersectionOverUnion?: number | null
}

export interface AIExpressionNodeResponse {
  /** @format int32 */
  ClassId?: number | null
  Class?: AIObjectResponse
  /** @format int32 */
  ModelId?: number | null
  Model?: AIModelAttachResponse
  /**
   *
   *
   * 1 = Boolean
   *
   * 2 = Date
   *
   * 3 = DateTime
   *
   * 4 = Decimal
   *
   * 5 = Float
   *
   * 6 = Integer
   *
   * 7 = String
   *
   * 8 = Set
   *
   * 9 = PairSet
   */
  ResultType?: AIExpressionNodeResultType
  StringValue?: string | null
  BooleanValue?: boolean | null
  /** @format int32 */
  IntegerValue?: number | null
  /** @format double */
  X?: number
  /** @format double */
  Y?: number
  /**
   *
   *
   * 1 = ClassFilter
   *
   * 2 = ModelRunner
   *
   * 3 = Reducer
   *
   * 4 = SetComposer
   *
   * 5 = LogicalValuesComposer
   *
   * 6 = ConstantNumber
   *
   * 7 = ConstantString
   *
   * 8 = ConstantBoolean
   *
   * 9 = PairSetComposer
   *
   * 10 = ReductionFilter
   *
   * 11 = Projector
   *
   * 12 = ValuesComparator
   *
   * 13 = ValuesCalculator
   */
  Type?: AIExpressionNodeType
  /**
   *
   *
   * 0 = Multiply
   *
   * 1 = Add
   *
   * 2 = Subtract
   */
  ArithmeticOperation?: ArithmeticOperation
  /**
   *
   *
   * 0 = Union
   *
   * 1 = Intersection
   */
  BoundingBoxOperation?: SetOperation
  /**
   *
   *
   * 0 = Equals
   *
   * 1 = NotEquals
   *
   * 2 = Greater
   *
   * 3 = GreaterOrEquals
   *
   * 4 = Less
   *
   * 5 = LessOrEquals
   */
  ComparisonOperation?: ComparisonOperation
  /**
   *
   *
   * 0 = And
   *
   * 1 = Or
   */
  LogicalOperation?: LogicalOperation
  /**
   *
   *
   * 0 = Count
   *
   * 1 = Max
   *
   * 2 = Min
   *
   * 3 = Sum
   */
  ReductionOperation?: ReductionOperation
  /**
   *
   *
   * 0 = Union
   *
   * 1 = Intersection
   */
  SetOperation?: SetOperation
  /** @format double */
  IntersectionOverUnion?: number
  /** @format double */
  PredictionThreshold?: number
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 1 = Boolean
 *
 * 2 = Date
 *
 * 3 = DateTime
 *
 * 4 = Decimal
 *
 * 5 = Float
 *
 * 6 = Integer
 *
 * 7 = String
 *
 * 8 = Set
 *
 * 9 = PairSet
 * @format int32
 */
export enum AIExpressionNodeResultType {
  Boolean = 1,
  Date = 2,
  DateTime = 3,
  Decimal = 4,
  Float = 5,
  Integer = 6,
  String = 7,
  Set = 8,
  PairSet = 9,
}

/**
 *
 *
 * 1 = ClassFilter
 *
 * 2 = ModelRunner
 *
 * 3 = Reducer
 *
 * 4 = SetComposer
 *
 * 5 = LogicalValuesComposer
 *
 * 6 = ConstantNumber
 *
 * 7 = ConstantString
 *
 * 8 = ConstantBoolean
 *
 * 9 = PairSetComposer
 *
 * 10 = ReductionFilter
 *
 * 11 = Projector
 *
 * 12 = ValuesComparator
 *
 * 13 = ValuesCalculator
 * @format int32
 */
export enum AIExpressionNodeType {
  ClassFilter = 1,
  ModelRunner = 2,
  Reducer = 3,
  SetComposer = 4,
  LogicalValuesComposer = 5,
  ConstantNumber = 6,
  ConstantString = 7,
  ConstantBoolean = 8,
  PairSetComposer = 9,
  ReductionFilter = 10,
  Projector = 11,
  ValuesComparator = 12,
  ValuesCalculator = 13,
}

export interface AIExpressionPredictionRequest {
  /** @maxLength 512 */
  Description?: string | null
}

export interface AIExpressionResponse {
  Name?: string | null
  Description?: string | null
  /** @format date-time */
  CreatedOn?: string | null
  IsCreatedByAI?: boolean
  /** @format int32 */
  Id?: number
}

export interface AIExpressionsPagedResponse {
  Expressions?: AIExpressionResponse[] | null
  /** @format int32 */
  TotalCount?: number
}

export interface AIModelAttachResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  Description?: string | null
  /** @format date-time */
  CreatedOn?: string | null
  /**
   *
   *
   * 0 = CustomVision
   *
   * 1 = External
   *
   * 2 = InternalTrained
   *
   * 3 = HuggingFace
   *
   * 4 = Roboflow
   */
  Type?: AIModelType
}

export interface AIModelCreateRequest {
  /** A key (token) to access the model. */
  Key?: string | null
  /**
   *
   *
   * 0 = CustomVision
   *
   * 1 = External
   *
   * 2 = InternalTrained
   *
   * 3 = HuggingFace
   *
   * 4 = Roboflow
   */
  Type?: AIModelType
  /** Process a page uploaded to a gallery. */
  RunOnPageUpload?: boolean
  /** Process a page which has an answer on a form. */
  RunOnFormAnswer?: boolean
  AIObjects: AIObjectPredictionSetUpdateRequest[]
  /**
   * The URL to the model.
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   * The name of the model.
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * The description of the model.
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
}

/**
 *
 *
 * 0 = CustomVision
 *
 * 1 = External
 *
 * 2 = InternalTrained
 *
 * 3 = HuggingFace
 *
 * 4 = Roboflow
 * @format int32
 */
export enum AIModelType {
  CustomVision = 0,
  External = 1,
  InternalTrained = 2,
  HuggingFace = 3,
  Roboflow = 4,
}

export interface AIModelUpdateRequest {
  /**
   * The URL to the model.
   * @format uri
   */
  Url?: string | null
  /** The access token to the model. */
  Key?: string | null
  /**
   * Unique id of the model within the system
   * @format int32
   */
  Id?: number
  /**
   * The name of the model.
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * The description of the model.
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
}

export interface AIObjectForContentResponse {
  Name?: string | null
  IsDeleted?: boolean
  /** @format date-time */
  CreatedOn?: string
  /** @maxLength 100 */
  ImagePredictionId?: string | null
  /** @maxLength 100 */
  Iteration?: string | null
  /** @maxLength 100 */
  Project?: string | null
  /** @format double */
  Probability?: number | null
  /** @format int32 */
  Id?: number
}

export interface AIObjectForIterationResponse {
  Name?: string | null
  /** @format int32 */
  Id?: number
}

export interface AIObjectForProjectResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  /** @format int32 */
  TotalCount?: number
}

export interface AIObjectPredictionResponse {
  /** @format int32 */
  Threshold?: number | null
  IsActive?: boolean
  /** @format int32 */
  AIModelId?: number
  /** @format int32 */
  AIModelAIObjectId?: number
  Name?: string | null
  Color?: string | null
  /**
   *
   *
   * 0 = None
   *
   * 1 = Node
   *
   * 2 = Edge
   *
   * 3 = EdgeDirected
   */
  GraphElementType?: GraphElementType
  IsProjectionEnabled?: boolean
  /** @format int32 */
  ObjectLevel?: number | null
  /** @format date-time */
  CreatedOn?: string | null
  Properties?: AIObjectPropertyResponse[] | null
  /** @format int32 */
  Id?: number
}

export interface AIObjectPredictionSetUpdateRequest {
  /** @format int32 */
  AIObjectId: number
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  Threshold?: number
}

export interface AIObjectPropertyResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  Ordinal?: number
  /**
   *
   *
   * 0 = Json
   *
   * 1 = String
   *
   * 2 = IntNumber
   *
   * 3 = DoubleNumber
   *
   * 4 = DateTime
   *
   * 5 = Dropdown
   *
   * 6 = Checkbox
   *
   * 7 = Url
   *
   * 8 = Boolean
   *
   * 9 = Latitude
   *
   * 10 = Longitude
   */
  Type?: AIObjectPropertyType
  DefaultValue?: any
  IsDisabled?: boolean
  IsEditable?: boolean
  IsHidden?: boolean
  /** @format int32 */
  SelectionListId?: number | null
  /**
   *
   *
   * 0 = None
   *
   * 1 = Height
   *
   * 2 = Width
   *
   * 3 = Depth
   *
   * 4 = Azimuth
   *
   * 5 = RollAngle
   *
   * 6 = Elevation
   *
   * 7 = PitchAngle
   *
   * 8 = HorizontalOffset
   *
   * 9 = VerticalOffset
   *
   * 10 = YawAngle
   */
  AutofillSource?: AutofillSource
}

/**
 *
 *
 * 0 = Json
 *
 * 1 = String
 *
 * 2 = IntNumber
 *
 * 3 = DoubleNumber
 *
 * 4 = DateTime
 *
 * 5 = Dropdown
 *
 * 6 = Checkbox
 *
 * 7 = Url
 *
 * 8 = Boolean
 *
 * 9 = Latitude
 *
 * 10 = Longitude
 * @format int32
 */
export enum AIObjectPropertyType {
  Json = 0,
  String = 1,
  IntNumber = 2,
  DoubleNumber = 3,
  DateTime = 4,
  Dropdown = 5,
  Checkbox = 6,
  Url = 7,
  Boolean = 8,
  Latitude = 9,
  Longitude = 10,
}

export interface AIObjectResponse {
  Name?: string | null
  Color?: string | null
  /**
   *
   *
   * 0 = None
   *
   * 1 = Node
   *
   * 2 = Edge
   *
   * 3 = EdgeDirected
   */
  GraphElementType?: GraphElementType
  IsProjectionEnabled?: boolean
  /** @format int32 */
  ObjectLevel?: number | null
  /** @format date-time */
  CreatedOn?: string | null
  Properties?: AIObjectPropertyResponse[] | null
  /** @format int32 */
  Id?: number
}

export interface AIReportFilter {
  Value?: string | null
  /**
   *
   *
   * 0 = Unset
   *
   * 1 = Equals
   *
   * 2 = Contains
   *
   * 3 = NotEquals
   *
   * 4 = Empty
   *
   * 5 = NotEmpty
   */
  Operation?: FilterOperation
}

export interface AIReportSorting {
  /** @format int32 */
  Priority?: number
  /**
   *
   *
   * 0 = Unset
   *
   * 1 = Ascending
   *
   * 2 = Descending
   */
  Direction?: SortingDirection
}

export interface AddClassificationAnnotationsRequest {
  /**
   * Unique id of the session within the system.
   * @format int32
   */
  ContentId: number
  /**
   * List of unique ids of the pages within the system.
   * @minItems 1
   */
  PageIds: number[]
  /**
   * List of unique ids of the AIObjects within the system.
   * @minItems 1
   */
  AIObjectIds: number[]
}

export interface AddUsersContentRequest {
  /**
   * The id of a particular session.
   * @format int32
   */
  ContentId: number
  /**
   * The ids of the added users.
   * @minItems 1
   */
  UserIds: number[]
  /**
   *
   *
   * 0 = User
   *
   * 1 = Owner
   *
   * 2 = AIUser
   *
   * 3 = Reviewer
   *
   * 4 = Viewer
   *
   * 5 = Labeler
   *
   * 6 = Approver
   *
   * 7 = Guest
   */
  Role: ContentUserRole
}

export interface AdminUsersSearchRequest {
  Sorting?: Sorting
  Filtering?: Filtering
  /**
   * @format int32
   * @default 1
   */
  PageNumber?: number
  /**
   * @format int32
   * @default 25
   */
  PageSize?: number
}

/**
 *
 *
 * 1 = Name
 *
 * 2 = Email
 *
 * 3 = Role
 *
 * 4 = Tenant
 *
 * 5 = IsDisabled
 *
 * 6 = IsCreatedByAdmin
 * @format int32
 */
export enum AdminUsersSortingColumnEnum {
  Name = 1,
  Email = 2,
  Role = 3,
  Tenant = 4,
  IsDisabled = 5,
  IsCreatedByAdmin = 6,
}

export interface AnnotationInfoResponse {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  PageId?: number
  /** @format int32 */
  UserId?: number
  /** @format int32 */
  ParentAnnotationId?: number | null
  /** @format int32 */
  CopyOfId?: number | null
  /**
   *
   *
   * 0 = StrokeDraw
   *
   * 1 = StrokeErase
   *
   * 2 = Laser
   *
   * 3 = Text
   *
   * 4 = Audio
   *
   * 5 = Text_Callout
   *
   * 6 = HotSpot
   *
   * 7 = LinkedPage
   *
   * 8 = AI
   *
   * 9 = AIHotspot
   *
   * 10 = Box
   *
   * 11 = Distance
   *
   * 12 = Model
   *
   * 13 = Height
   *
   * 14 = Area
   *
   * 15 = Cylinder
   *
   * 16 = Polyline
   *
   * 100 = StrokeDraw_Shape_Line
   *
   * 101 = StrokeDraw_Shape_Rectangle
   *
   * 102 = StrokeDraw_Shape_Ellipse
   *
   * 103 = StrokeDraw_Shape_Rectangle_Filled
   *
   * 104 = StrokeDraw_Shape_Ellipse_Filled
   *
   * 105 = StrokeDraw_Shape_Line_ArrowEnd
   *
   * 106 = StrokeDraw_Shape_Line_ArrowBoth
   *
   * 107 = Polygon
   */
  Type?: AnnotationType
  IsDeleted?: boolean
  /** @format date-time */
  CreatedOn?: string | null
  IsProcessed?: boolean
  /** @format date-time */
  UpdatedOn?: string | null
  /** @format date-time */
  DeletedOn?: string | null
  UniqueAIObject?: UniqueAIObjectForContentResponse
  AIObject?: AIObjectForContentResponse
  Anchor?: Point
  Color?: string | null
  Coordinates?: Coordinates[] | null
  /** @format int32 */
  FontSize?: number
  Text?: string | null
  /** @format float */
  Timestamp?: number
  TopLeft?: Point
  /** @format float */
  Duration?: number
  /** @format int32 */
  FormAnswerId?: number | null
  /** @format int32 */
  FormAnswererId?: number | null
  /** @format int32 */
  FormControlId?: number | null
  FormTabIds?: number[] | null
  /** @format int32 */
  FormId?: number | null
  /** @format int32 */
  LinkedPageId?: number | null
  /** @format int64 */
  Size?: number
  /** @format float */
  TargetTimestamp?: number
  /** @format int32 */
  Width?: number
}

export interface AnnotationResponse {
  /** @format int32 */
  UserId?: number
  User?: UserInfoResponse
  /** @format int32 */
  PageId?: number
  /** @format int32 */
  ContentId?: number
  /**
   *
   *
   * 0 = StrokeDraw
   *
   * 1 = StrokeErase
   *
   * 2 = Laser
   *
   * 3 = Text
   *
   * 4 = Audio
   *
   * 5 = Text_Callout
   *
   * 6 = HotSpot
   *
   * 7 = LinkedPage
   *
   * 8 = AI
   *
   * 9 = AIHotspot
   *
   * 10 = Box
   *
   * 11 = Distance
   *
   * 12 = Model
   *
   * 13 = Height
   *
   * 14 = Area
   *
   * 15 = Cylinder
   *
   * 16 = Polyline
   *
   * 100 = StrokeDraw_Shape_Line
   *
   * 101 = StrokeDraw_Shape_Rectangle
   *
   * 102 = StrokeDraw_Shape_Ellipse
   *
   * 103 = StrokeDraw_Shape_Rectangle_Filled
   *
   * 104 = StrokeDraw_Shape_Ellipse_Filled
   *
   * 105 = StrokeDraw_Shape_Line_ArrowEnd
   *
   * 106 = StrokeDraw_Shape_Line_ArrowBoth
   *
   * 107 = Polygon
   */
  Type?: AnnotationType
  /** @format date-time */
  CreatedOn?: string
  Color?: string | null
  /** @format double */
  Elevation?: number
  /** @format int32 */
  Width?: number
  Coordinates?: Coordinates[] | null
  /** @format int32 */
  FontSize?: number
  TopLeft?: Point
  /** @format double */
  Azimuth?: number
  Anchor?: Point
  Name?: string | null
  Text?: string | null
  /** @format double */
  Tilt?: number
  /** @format float */
  Timestamp?: number
  /** @format float */
  TargetTimestamp?: number
  Url?: string | null
  /** @format int32 */
  ParentAnnotationId?: number | null
  Points?: Point[] | null
  Rotation?: EulerRotation
  Scale?: Scale
  /** @format int32 */
  LinkedPageId?: number | null
  /** @format float */
  Duration?: number
  /** @format int64 */
  Size?: number
  IsAIDetectCorrect?: boolean | null
  UserFeedback?: string | null
  /** @format int32 */
  UniqueAIObjectId?: number | null
  /** @format int32 */
  AIObjectId?: number | null
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 0 = StrokeDraw
 *
 * 1 = StrokeErase
 *
 * 2 = Laser
 *
 * 3 = Text
 *
 * 4 = Audio
 *
 * 5 = Text_Callout
 *
 * 6 = HotSpot
 *
 * 7 = LinkedPage
 *
 * 8 = AI
 *
 * 9 = AIHotspot
 *
 * 10 = Box
 *
 * 11 = Distance
 *
 * 12 = Model
 *
 * 13 = Height
 *
 * 14 = Area
 *
 * 15 = Cylinder
 *
 * 16 = Polyline
 *
 * 100 = StrokeDraw_Shape_Line
 *
 * 101 = StrokeDraw_Shape_Rectangle
 *
 * 102 = StrokeDraw_Shape_Ellipse
 *
 * 103 = StrokeDraw_Shape_Rectangle_Filled
 *
 * 104 = StrokeDraw_Shape_Ellipse_Filled
 *
 * 105 = StrokeDraw_Shape_Line_ArrowEnd
 *
 * 106 = StrokeDraw_Shape_Line_ArrowBoth
 *
 * 107 = Polygon
 * @format int32
 */
export enum AnnotationType {
  StrokeDraw = 0,
  StrokeErase = 1,
  Laser = 2,
  Text = 3,
  Audio = 4,
  TextCallout = 5,
  HotSpot = 6,
  LinkedPage = 7,
  AI = 8,
  AIHotspot = 9,
  Box = 10,
  Distance = 11,
  Model = 12,
  Height = 13,
  Area = 14,
  Cylinder = 15,
  Polyline = 16,
  StrokeDrawShapeLine = 100,
  StrokeDrawShapeRectangle = 101,
  StrokeDrawShapeEllipse = 102,
  StrokeDrawShapeRectangleFilled = 103,
  StrokeDrawShapeEllipseFilled = 104,
  StrokeDrawShapeLineArrowEnd = 105,
  StrokeDrawShapeLineArrowBoth = 106,
  Polygon = 107,
}

export interface ApiErrorResponse {
  Error: Error
}

export interface AppointmentSlotBookRequest {
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId?: number | null
  /**
   * Title of the event
   * @maxLength 120
   */
  Title?: string | null
  /**
   * Description of the event
   * @maxLength 120
   */
  Description?: string | null
  /**
   * Start date of the event
   * @format date-time
   */
  StartDate: string
  /**
   * End date of the event
   * @format date-time
   */
  EndDate: string
}

export interface AppointmentSlotsCreateRequest {
  /**
   * Appointments slots interval in minutes
   * @format int32
   * @min 1
   * @max 2147483647
   */
  Interval?: number
  /**
   * Title of the event
   * @maxLength 120
   */
  Title?: string | null
  /**
   * Description of the event
   * @maxLength 120
   */
  Description?: string | null
  /**
   * Start date of the event
   * @format date-time
   */
  StartDate: string
  /**
   * End date of the event
   * @format date-time
   */
  EndDate: string
}

/**
 *
 *
 * 0 = Multiply
 *
 * 1 = Add
 *
 * 2 = Subtract
 * @format int32
 */
export enum ArithmeticOperation {
  Multiply = 0,
  Add = 1,
  Subtract = 2,
}

export interface AssetRequest {
  /** @maxLength 256 */
  Name?: string | null
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Image
   *
   * 2 = Video
   *
   * 3 = Preview
   *
   * 4 = Audio
   *
   * 5 = CloudFile
   *
   * 6 = RecordedVideo
   *
   * 7 = Thumbnail
   *
   * 10 = Snapshot
   *
   * 11 = ConcatenatedVideo
   *
   * 12 = WebPage
   *
   * 13 = SpatialModelImage
   *
   * 14 = DocDocument
   *
   * 15 = PptDocument
   *
   * 16 = ExcelDocument
   *
   * 17 = File
   *
   * 18 = StructureModel
   *
   * 20 = TwoDLineDrawing
   *
   * 22 = AutodeskFile
   *
   * 23 = PointCloud
   *
   * 24 = PotreePointCloud
   *
   * 101 = UnencodedVideo
   *
   * 102 = UnencodedRecordedVideo
   *
   * 103 = UnencodedConcatenatedVideo
   *
   * 104 = RecordedCloudFileVideo
   *
   * 105 = StreamVideo
   */
  Type?: AssetType
  /** @maxLength 4000 */
  Url?: string | null
  CompressedMediaUrls?: CompressedMediaUrlResponse[] | null
  Metadata?: MetadataResponse
  /** @format int32 */
  PageId?: number
  /** @format double */
  Latitude?: number | null
  /** @format double */
  Longitude?: number | null
  /** @format int64 */
  Size?: number | null
  /** @format int32 */
  Duration?: number | null
  /** @format date-time */
  OriginalCreatedOn?: string | null
  /** @format int32 */
  Order?: number
  /** @format int32 */
  LinkedPageId?: number | null
  /** @format int32 */
  CopyOfId?: number | null
  /**
   *
   *
   * 0 = NotCreated
   *
   * 1 = ModelCreating
   *
   * 2 = ModelCreated
   */
  MeasurementStatus?: PointCloudModelStatus
  /** @format int32 */
  Id?: number
}

export interface AssetResponse {
  /** @format int32 */
  Id?: number
  /** @maxLength 256 */
  Name?: string | null
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Image
   *
   * 2 = Video
   *
   * 3 = Preview
   *
   * 4 = Audio
   *
   * 5 = CloudFile
   *
   * 6 = RecordedVideo
   *
   * 7 = Thumbnail
   *
   * 10 = Snapshot
   *
   * 11 = ConcatenatedVideo
   *
   * 12 = WebPage
   *
   * 13 = SpatialModelImage
   *
   * 14 = DocDocument
   *
   * 15 = PptDocument
   *
   * 16 = ExcelDocument
   *
   * 17 = File
   *
   * 18 = StructureModel
   *
   * 20 = TwoDLineDrawing
   *
   * 22 = AutodeskFile
   *
   * 23 = PointCloud
   *
   * 24 = PotreePointCloud
   *
   * 101 = UnencodedVideo
   *
   * 102 = UnencodedRecordedVideo
   *
   * 103 = UnencodedConcatenatedVideo
   *
   * 104 = RecordedCloudFileVideo
   *
   * 105 = StreamVideo
   */
  Type?: AssetType
  /** @maxLength 4000 */
  Url?: string | null
  CompressedMediaUrls?: CompressedMediaUrlResponse[] | null
  Metadata?: MetadataResponse
  /** @format int32 */
  PageId?: number
  /** @format double */
  Latitude?: number | null
  /** @format double */
  Longitude?: number | null
  /** @format int64 */
  Size?: number | null
  /** @format int32 */
  Duration?: number | null
  /** @format date-time */
  OriginalCreatedOn?: string | null
  /** @format int32 */
  Order?: number
  /** @format int32 */
  LinkedPageId?: number | null
  /** @format int32 */
  CopyOfId?: number | null
  /**
   *
   *
   * 0 = NotCreated
   *
   * 1 = ModelCreating
   *
   * 2 = ModelCreated
   */
  MeasurementStatus?: PointCloudModelStatus
}

/**
 *
 *
 * 0 = Unknown
 *
 * 1 = Image
 *
 * 2 = Video
 *
 * 3 = Preview
 *
 * 4 = Audio
 *
 * 5 = CloudFile
 *
 * 6 = RecordedVideo
 *
 * 7 = Thumbnail
 *
 * 10 = Snapshot
 *
 * 11 = ConcatenatedVideo
 *
 * 12 = WebPage
 *
 * 13 = SpatialModelImage
 *
 * 14 = DocDocument
 *
 * 15 = PptDocument
 *
 * 16 = ExcelDocument
 *
 * 17 = File
 *
 * 18 = StructureModel
 *
 * 20 = TwoDLineDrawing
 *
 * 22 = AutodeskFile
 *
 * 23 = PointCloud
 *
 * 24 = PotreePointCloud
 *
 * 101 = UnencodedVideo
 *
 * 102 = UnencodedRecordedVideo
 *
 * 103 = UnencodedConcatenatedVideo
 *
 * 104 = RecordedCloudFileVideo
 *
 * 105 = StreamVideo
 * @format int32
 */
export enum AssetType {
  Unknown = 0,
  Image = 1,
  Video = 2,
  Preview = 3,
  Audio = 4,
  CloudFile = 5,
  RecordedVideo = 6,
  Thumbnail = 7,
  Snapshot = 10,
  ConcatenatedVideo = 11,
  WebPage = 12,
  SpatialModelImage = 13,
  DocDocument = 14,
  PptDocument = 15,
  ExcelDocument = 16,
  File = 17,
  StructureModel = 18,
  TwoDLineDrawing = 20,
  AutodeskFile = 22,
  PointCloud = 23,
  PotreePointCloud = 24,
  UnencodedVideo = 101,
  UnencodedRecordedVideo = 102,
  UnencodedConcatenatedVideo = 103,
  RecordedCloudFileVideo = 104,
  StreamVideo = 105,
}

export interface AttachFormRequest {
  /**
   * The id of a session.
   * @format int32
   */
  ContentId: number
  /**
   * The id of attached form.
   * @format int32
   */
  FormId: number
  IsAttachedFromCondition?: boolean
}

export interface AttachFormsRequest {
  /**
   * The id of a session.
   * @format int32
   */
  ContentId: number
  /** The list of Ids of attached forms. */
  FormsId: number[]
}

/**
 *
 *
 * 0 = None
 *
 * 1 = Height
 *
 * 2 = Width
 *
 * 3 = Depth
 *
 * 4 = Azimuth
 *
 * 5 = RollAngle
 *
 * 6 = Elevation
 *
 * 7 = PitchAngle
 *
 * 8 = HorizontalOffset
 *
 * 9 = VerticalOffset
 *
 * 10 = YawAngle
 * @format int32
 */
export enum AutofillSource {
  None = 0,
  Height = 1,
  Width = 2,
  Depth = 3,
  Azimuth = 4,
  RollAngle = 5,
  Elevation = 6,
  PitchAngle = 7,
  HorizontalOffset = 8,
  VerticalOffset = 9,
  YawAngle = 10,
}

export interface BaseUserInfoResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  UserName?: string | null
  FirstName?: string | null
  LastName?: string | null
  Email?: string | null
  AvatarUrl?: string | null
  IsAccountDeleted?: boolean
}

export interface BaseUserInfoWithoutAvatarResponse {
  AvatarUrl?: string | null
  /** @format int32 */
  Id?: number
  Name?: string | null
  UserName?: string | null
  FirstName?: string | null
  LastName?: string | null
  Email?: string | null
  IsAccountDeleted?: boolean
}

export interface BoundingBoxModel {
  /** @format double */
  X1?: number
  /** @format double */
  X2?: number
  /** @format double */
  Y1?: number
  /** @format double */
  Y2?: number
}

/**
 *
 *
 * 0 = Contains
 *
 * 1 = Matches
 *
 * 2 = Overlaps
 * @format int32
 */
export enum BoundingBoxOperation {
  Contains = 0,
  Matches = 1,
  Overlaps = 2,
}

export interface CalendarEventAttachedContentModel {
  ThumbnailUrl?: string | null
  /** @format date-time */
  CreatedOn?: string
  Owner?: BaseUserInfoWithoutAvatarResponse
  Description?: string | null
  /** @format int32 */
  Id?: number
}

export interface CalendarEventAttendeeModel {
  /** @format int32 */
  UserId?: number
  User?: BaseUserInfoResponse
  /**
   *
   *
   * 0 = Pending
   *
   * 1 = Declined
   *
   * 2 = Accepted
   */
  Status?: CalendarEventAttendeeStatus
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 0 = Pending
 *
 * 1 = Declined
 *
 * 2 = Accepted
 * @format int32
 */
export enum CalendarEventAttendeeStatus {
  Pending = 0,
  Declined = 1,
  Accepted = 2,
}

export interface CalendarEventBaseResponse {
  /**
   * Creation time of the event
   * @format date-time
   */
  CreatedOn?: string | null
  /**
   * Last modification time of the event
   * @format date-time
   */
  UpdatedOn?: string | null
  IsDeleted?: boolean
  /**
   * Start date of the event
   * @format date-time
   */
  StartDate?: string
  /**
   * End date of the event
   * @format date-time
   */
  EndDate?: string
  /** Title of the event */
  Title?: string | null
  /**
   *
   *
   * 0 = Public
   *
   * 1 = Private
   */
  Visibility?: CalendarEventVisibility
  /**
   *
   *
   * 0 = Opaque
   *
   * 1 = Transparent
   */
  Transparency?: CalendarEventTransparency
  Recurrence?: string | null
  /**
   *
   *
   * 0 = Event
   *
   * 1 = Appointment
   *
   * 2 = AppointmentSlots
   */
  Type?: CalendarEventType
  /**
   * Appointment slots interval in minutes
   * @format int32
   */
  Interval?: number | null
  /**
   * Event creator id
   * @format int32
   */
  UserId?: number
  /** @format int32 */
  Id?: number
}

export interface CalendarEventContentUpdateRequest {
  /** @format int32 */
  ContentId?: number | null
}

export interface CalendarEventCreateRequest {
  /**
   *
   *
   * 0 = Public
   *
   * 1 = Private
   */
  Visibility?: CalendarEventVisibility
  /**
   *
   *
   * 0 = Opaque
   *
   * 1 = Transparent
   */
  Transparency?: CalendarEventTransparency
  /**
   * List of RRULE, EXRULE, RDATE and EXDATE lines for a recurring event, as specified in RFC5545. This field is omitted for single events.
   *
   * Examples:
   * - "RRULE:FREQ=DAILY;UNTIL=20231110T023500Z" - A recurring event that occurs daily until 10th of November 10, 2023;
   * - "RRULE:FREQ=WEEKLY;COUNT=10;BYDAY=MO,WE,FR" - A recurring event that repeats weekly on Mondays, Wednesdays, and Fridays for a total of 10 occurrences;
   * - "RRULE:FREQ=MONTHLY;COUNT=12" - A recurring event that repeats monthly, producing a total of 12 occurrences;
   * - "RRULE:FREQ=YEARLY" - A recurring event that repeats yearly.
   */
  Recurrence?: string | null
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId?: number | null
  /** Participants ids */
  Attendees?: number[] | null
  /**
   * Title of the event
   * @maxLength 120
   */
  Title?: string | null
  /**
   * Description of the event
   * @maxLength 120
   */
  Description?: string | null
  /**
   * Start date of the event
   * @format date-time
   */
  StartDate: string
  /**
   * End date of the event
   * @format date-time
   */
  EndDate: string
}

export interface CalendarEventDateUpdateRequest {
  /**
   * Event start date
   * @format date-time
   */
  StartDate: string
  /**
   * Event end date
   * @format date-time
   */
  EndDate: string
}

export interface CalendarEventOwnerUpdateRequest {
  /** @format int32 */
  UserId: number
}

export interface CalendarEventResponse {
  User?: BaseUserInfoResponse
  /** Description of the event */
  Description?: string | null
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId?: number | null
  Content?: CalendarEventAttachedContentModel
  /** Event attendees */
  Attendees?: CalendarEventAttendeeModel[] | null
  /**
   * Creation time of the event
   * @format date-time
   */
  CreatedOn?: string | null
  /**
   * Last modification time of the event
   * @format date-time
   */
  UpdatedOn?: string | null
  IsDeleted?: boolean
  /**
   * Start date of the event
   * @format date-time
   */
  StartDate?: string
  /**
   * End date of the event
   * @format date-time
   */
  EndDate?: string
  /** Title of the event */
  Title?: string | null
  /**
   *
   *
   * 0 = Public
   *
   * 1 = Private
   */
  Visibility?: CalendarEventVisibility
  /**
   *
   *
   * 0 = Opaque
   *
   * 1 = Transparent
   */
  Transparency?: CalendarEventTransparency
  Recurrence?: string | null
  /**
   *
   *
   * 0 = Event
   *
   * 1 = Appointment
   *
   * 2 = AppointmentSlots
   */
  Type?: CalendarEventType
  /**
   * Appointment slots interval in minutes
   * @format int32
   */
  Interval?: number | null
  /**
   * Event creator id
   * @format int32
   */
  UserId?: number
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 0 = Opaque
 *
 * 1 = Transparent
 * @format int32
 */
export enum CalendarEventTransparency {
  Opaque = 0,
  Transparent = 1,
}

/**
 *
 *
 * 0 = Event
 *
 * 1 = Appointment
 *
 * 2 = AppointmentSlots
 * @format int32
 */
export enum CalendarEventType {
  Event = 0,
  Appointment = 1,
  AppointmentSlots = 2,
}

export interface CalendarEventUpdateRequest {
  /**
   * Title of the event
   * @maxLength 120
   */
  Title?: string | null
  /**
   * Description of the event
   * @maxLength 120
   */
  Description?: string | null
  /**
   * Start date of the event
   * @format date-time
   */
  StartDate: string
  /**
   * End date of the event
   * @format date-time
   */
  EndDate: string
  /**
   *
   *
   * 0 = Public
   *
   * 1 = Private
   */
  Visibility?: CalendarEventVisibility
  /**
   *
   *
   * 0 = Opaque
   *
   * 1 = Transparent
   */
  Transparency?: CalendarEventTransparency
  /**
   * Appointments slots interval in minutes
   * @format int32
   * @min 1
   * @max 2147483647
   */
  Interval?: number | null
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId?: number | null
  /** Event attendees */
  Attendees?: number[] | null
}

/**
 *
 *
 * 0 = Public
 *
 * 1 = Private
 * @format int32
 */
export enum CalendarEventVisibility {
  Public = 0,
  Private = 1,
}

/**
 *
 *
 * 0 = Hide
 *
 * 1 = Display
 *
 * 2 = DisplayThisOnly
 * @format int32
 */
export enum CalendarSubscriptionDisplayMode {
  Hide = 0,
  Display = 1,
  DisplayThisOnly = 2,
}

export interface CalendarSubscriptionResponse {
  /** Whether the calendar content shows up in the calendar UI */
  Enabled?: boolean
  /** The main color of the calendar in the hexadecimal format */
  Color?: string | null
  /**
   * Unique id of the user within the system
   * @format int32
   */
  CalendarOwnerId?: number
  CalendarOwner?: BaseUserInfoResponse
}

export interface ChatInfoResponse {
  /**
   * Unique id of the user within the system
   * @format int32
   */
  UserId?: number | null
  /**
   * Exact day and time (in UTC) of the last message
   * @format date-time
   */
  LastMessageDate?: string
  /**
   * Last readed message id
   * @format int32
   */
  LastReadedMessageId?: number | null
  /**
   * Last readed message date
   * @format date-time
   */
  LastReadedMessageDate?: string | null
  /**
   * Unread messages count
   * @format int32
   */
  UnreadedMessagesCount?: number
}

export interface ChatMessageAssetResponse {
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Audio
   */
  Type?: ChatMessageAssetType
  /** @maxLength 4000 */
  Url?: string | null
  /** @format int32 */
  Duration?: number
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 0 = Unknown
 *
 * 1 = Audio
 * @format int32
 */
export enum ChatMessageAssetType {
  Unknown = 0,
  Audio = 1,
}

export interface ChatMessageResponse {
  /** @format int32 */
  UserId?: number
  User?: BaseUserInfoWithoutAvatarResponse
  /** @format int32 */
  ContentId?: number
  Message?: string | null
  Translations?: Record<string, string | null>
  Asset?: ChatMessageAssetResponse
  /** @format date-time */
  CreatedOn?: string
  /** @format int32 */
  ReceiverId?: number | null
  Receiver?: BaseUserInfoWithoutAvatarResponse
  /** @format int32 */
  TopicId?: number
  /**
   *
   *
   * 1 = Session
   *
   * 2 = Page
   *
   * 3 = FormAnswer
   */
  TopicType?: TopicType
  /** @format int32 */
  Id?: number
}

export interface ChatMessageTranslationRequest {
  /**
   * Unique id of chat message within the system
   * @format int32
   */
  MessageId: number
  /**
   * Short language name
   * @minLength 1
   */
  TargetLanguage: string
}

/**
 *
 *
 * 0 = MLP
 *
 * 1 = LogisticRegression
 * @format int32
 */
export enum ClassifierType {
  MLP = 0,
  LogisticRegression = 1,
}

/**
 *
 *
 * 0 = Equals
 *
 * 1 = NotEquals
 *
 * 2 = Greater
 *
 * 3 = GreaterOrEquals
 *
 * 4 = Less
 *
 * 5 = LessOrEquals
 * @format int32
 */
export enum ComparisonOperation {
  Equals = 0,
  NotEquals = 1,
  Greater = 2,
  GreaterOrEquals = 3,
  Less = 4,
  LessOrEquals = 5,
}

export interface CompressedMediaUrlResponse {
  /** @format int32 */
  AssetId?: number
  /**
   *
   *
   * 0 = Unscaled
   *
   * 1 = Scaled
   *
   * 2 = Lowest
   */
  CompressionType?: CompressionType
  Url?: string | null
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 0 = Unscaled
 *
 * 1 = Scaled
 *
 * 2 = Lowest
 * @format int32
 */
export enum CompressionType {
  Unscaled = 0,
  Scaled = 1,
  Lowest = 2,
}

export interface ConditionRecipientResponse {
  /**
   *
   *
   * 1 = AllParticipant
   *
   * 2 = SessionOwner
   *
   * 3 = SpecificUsers
   */
  Type?: SendEmailConditionType
  Recipients?: RecipientResponse[] | null
}

/**
 *
 *
 * 0 = AnyEntry
 *
 * 1 = Equals
 *
 * 2 = GreaterThan
 *
 * 3 = LessThan
 *
 * 4 = UnansweredQuestions
 * @format int32
 */
export enum ConditionTriggerType {
  AnyEntry = 0,
  Equals = 1,
  GreaterThan = 2,
  LessThan = 3,
  UnansweredQuestions = 4,
}

/**
 *
 *
 * 0 = Unknown
 *
 * 1 = AttachForm
 *
 * 2 = SendEmail
 *
 * 3 = AddVideoByUrl
 *
 * 4 = ShowInfoMessage
 *
 * 5 = RunWorkflow
 * @format int32
 */
export enum ConditionType {
  Unknown = 0,
  AttachForm = 1,
  SendEmail = 2,
  AddVideoByUrl = 3,
  ShowInfoMessage = 4,
  RunWorkflow = 5,
}

export interface ConditionsRecipientRequest {
  /**
   *
   *
   * 1 = AllParticipant
   *
   * 2 = SessionOwner
   *
   * 3 = SpecificUsers
   */
  Type?: SendEmailConditionType
  Recipients?: RecipientRequest[] | null
}

export interface ConditionsResponse {
  /** @format int32 */
  Id?: number
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = AttachForm
   *
   * 2 = SendEmail
   *
   * 3 = AddVideoByUrl
   *
   * 4 = ShowInfoMessage
   *
   * 5 = RunWorkflow
   */
  Type?: ConditionType
  Solution?: string | null
  TriggerValue?: string | null
  /**
   *
   *
   * 0 = AnyEntry
   *
   * 1 = Equals
   *
   * 2 = GreaterThan
   *
   * 3 = LessThan
   *
   * 4 = UnansweredQuestions
   */
  TriggerType?: ConditionTriggerType
  ConditionsRecipient?: ConditionRecipientResponse[] | null
}

export interface ContentActivateRequest {
  /**
   * Unique id of the content within the system
   * @format int32
   */
  ContentId: number
}

export interface ContentByDescriptionRequest {
  /**
   * The name of the session.
   * @minLength 1
   * @maxLength 4000
   */
  Description: string
  /**
   * The Id of the tenant where the session should be found.
   * <br>If missing, then data for the user's tenant is returned.
   * @format int32
   */
  TenantId?: number | null
  /**
   * Parameter for defining whether to include deleted sessions or not.
   * @default false
   */
  IncludeDeleted?: boolean
}

export interface ContentByDescriptionResponse {
  /**
   * Unique id of the session within the system.
   * @format int32
   */
  Id: number
  /**
   * The name of the session.
   * @minLength 1
   */
  Description: string
  /**
   * Exact day and time (in UTC) when a particular session was created.
   * @format date-time
   */
  CreatedOn: string
  /** Defines whether the session is deleted or not. */
  IsDeleted: boolean
}

export interface ContentCreateRequest {
  /**
   * The name of created session.
   * @maxLength 4000
   */
  Description?: string | null
  /**
   * The id of the session folder.
   * @format int32
   */
  FolderId?: number | null
  Documents?: DocumentCreateRequest[] | null
  /** @format int32 */
  ProgramId?: number | null
  /** Array of objects, required for displaying session name in json format in reports (if session name created using naming convention). */
  ContentNameParts?: ContentNamePartRequest[] | null
  /**
   * Storage that will be used to store all the content of the session. If not provided the default storage of the tenant will be used.
   * @format int32
   */
  StorageGroupId?: number | null
}

export interface ContentFolderRequest {
  /** @format int32 */
  ContentId: number
  /** @format int32 */
  ToFolderId?: number | null
  /** @format int32 */
  FromFolderId?: number | null
}

export interface ContentForIterationResponse {
  Description?: string | null
  /** @format int32 */
  Id?: number
}

export interface ContentFormIdResponse {
  /** @format int32 */
  ContentFormId?: number
}

export interface ContentFormRecordRequest {
  /**
   * A recorded form answers owner id.
   * @format int32
   */
  UserId: number
  /**
   * A recorded contentForm id.
   * @format int32
   */
  ContentFormId: number
  /**
   *
   *
   * 0 = Manual
   *
   * 1 = UserSignature
   */
  Type?: ContentFormRecordType
}

export interface ContentFormRecordResponse {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  UserId?: number
  /** @format int32 */
  ContentFormId?: number
  /** @format date-time */
  CreatedOn?: string
  /**
   *
   *
   * 0 = Manual
   *
   * 1 = UserSignature
   */
  Type?: ContentFormRecordType
  Signature?: string | null
}

/**
 *
 *
 * 0 = Manual
 *
 * 1 = UserSignature
 * @format int32
 */
export enum ContentFormRecordType {
  Manual = 0,
  UserSignature = 1,
}

export interface ContentFormWithAnswerStatusesRequest {
  /** @format int32 */
  ContentFormId: number
  AnswerStatuses?: FormAnswerStatus[] | null
}

export interface ContentFormsByUserResponse {
  /** @format int32 */
  TotalCount?: number
  ContentForms?: UserContentFormResponse[] | null
}

export interface ContentFormsIdsResponse {
  ContentFormsIds?: number[] | null
}

export type ContentFormsListWithoutStatusesResponseIAsyncEnumerable = object

export interface ContentIdResponse {
  /**
   * Unique id of the content within the system
   * @format int32
   */
  ContentId: number
}

export interface ContentLinkRequest {
  /** @format int32 */
  ContentId: number
  /** @format int32 */
  LinkedContentId: number
}

export interface ContentLinkResponse {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  ContentId?: number
  Description?: string | null
  TenantName?: string | null
  OwnerName?: string | null
  /**
   *
   *
   * 0 = Pending
   *
   * 1 = Active
   *
   * 2 = MergingInProgress
   *
   * 3 = Rejected
   */
  Status?: LinkedContentStatus
}

export interface ContentNamePartRequest {
  /** @minLength 1 */
  Question: string
  /** @minLength 1 */
  Answer: string
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  Order?: number
}

export interface ContentReviewStatusRequest {
  ContentIds?: number[] | null
  /**
   *
   *
   * 0 = NotStarted
   *
   * 1 = ReadyForReview
   *
   * 2 = InReview
   *
   * 3 = SessionFeedbackSent
   *
   * 4 = Complete
   */
  Status?: ReviewStatus
}

/**
 *
 *
 * 0 = SharedByOwner
 *
 * 1 = SharedByAdmin
 *
 * 2 = ForReview
 *
 * 3 = ContentsMerging
 *
 * 4 = LinkedContentsMerging
 *
 * 5 = MergeForReview
 * @format int32
 */
export enum ContentSharingModelType {
  SharedByOwner = 0,
  SharedByAdmin = 1,
  ForReview = 2,
  ContentsMerging = 3,
  LinkedContentsMerging = 4,
  MergeForReview = 5,
}

export interface ContentSharingRequest {
  /**
   * The Id of shared content.
   * @format int32
   */
  ContentId: number
  /**
   * The Id of a user the content is shared with.
   * Optional in case of contents merging. In this case UserId will be calculated by the content with TargetContentId (it must be the owner of this target content).
   * In other cases is required.
   * If content is shared to copy (Type = SharedByOwner or SharedByAdmin) the user will become an owner of copy of the shared content.
   * If content is shared to review the user will become a participant of the shared content with the role Reviewer.
   * @format int32
   */
  UserId?: number | null
  /**
   * The Id a tenant the content is shared with.
   * @format int32
   */
  TenantId?: number | null
  /**
   * The list of Ids of the shared gallery pages.
   * If 'DataType' is not provided (by default 'AllData' option is set) or 'AllData' option is selected then all content pages are shared.
   * If 'DataType' = 'SelectedData' then:
   * 'PagesIds' list is filtered to remove invalid items (all Ids must belong to the shared content)
   * If filtered 'PagesIds' list is empty there is an option to find out the valid pages by 'ContentForms' list:
   * If filtered 'ContentForms' list is empty then in case 'Type' is 'ForReview' the request model is invalid.
   * If 'Type' is not 'ForReview' then the first gallery page of the shared content is selected as a fallback.
   */
  PagesIds?: number[] | null
  /**
   * The list of Ids of the shared content forms with the list of selected form answer statuses for each form.
   * The list of the latest form answers will be calculated for each form taking into account provided form answers statuses.
   * If 'DataType' is not provided (by default 'AllData' option is set) or 'AllData' option is selected then all content forms with all available statuses are shared.
   * If 'DataType' = 'SelectedData' then:
   * 'ContentForms' list is filtered to remove invalid items (all Ids must belong to the shared content)
   * If filtered 'ContentForms' list is empty then in case 'Type' is 'ForReview' the request model is invalid.
   * In case if only the Ids of the content forms are provided (without answers statuses) then all available statuses are selected by default for each form.
   */
  ContentForms?: ContentFormWithAnswerStatusesRequest[] | null
  /**
   *
   *
   * 0 = SharedByOwner
   *
   * 1 = SharedByAdmin
   *
   * 2 = ForReview
   *
   * 3 = ContentsMerging
   *
   * 4 = LinkedContentsMerging
   *
   * 5 = MergeForReview
   */
  Type: ContentSharingModelType
  /**
   *
   *
   * 0 = AllData
   *
   * 1 = SelectedData
   */
  DataType?: SharedContentDataType
  /**
   * The Id of the target content.
   * Required in case of 'Type' = 'ContentsMerging'.
   * It's an existing content the shared data from the original content is copied to.
   * @format int32
   */
  TargetContentId?: number | null
}

/**
 *
 *
 * 0 = Unknown
 *
 * 1 = Video
 *
 * 2 = Text
 *
 * 3 = Pdf
 *
 * 4 = VideoByUrl
 *
 * 5 = CloudFile
 *
 * 6 = RecordedVideo
 *
 * 7 = Audio
 *
 * 8 = DrawingBoard
 *
 * 9 = Snapshot
 *
 * 10 = Image
 *
 * 11 = ConcatenatedVideo
 *
 * 12 = WebPage
 *
 * 13 = SpatialModel
 *
 * 14 = DocDocumentPage
 *
 * 15 = PptDocumentPage
 *
 * 16 = ExcelDocumentPage
 *
 * 17 = File
 *
 * 18 = StructureModel
 *
 * 19 = StructureModelPdf
 *
 * 20 = TwoDLineDrawing
 *
 * 21 = TwoDLineDrawingPdf
 *
 * 22 = AutodeskFile
 *
 * 23 = PointCloud
 *
 * 24 = BentleyAnalyticsReportPdf
 *
 * 101 = UnencodedVideo
 *
 * 102 = UnencodedRecordedVideo
 *
 * 103 = UnencodedConcatenatedVideo
 *
 * 104 = RecordedCloudFileVideo
 *
 * 105 = StreamVideo
 * @format int32
 */
export enum ContentType {
  Unknown = 0,
  Video = 1,
  Text = 2,
  Pdf = 3,
  VideoByUrl = 4,
  CloudFile = 5,
  RecordedVideo = 6,
  Audio = 7,
  DrawingBoard = 8,
  Snapshot = 9,
  Image = 10,
  ConcatenatedVideo = 11,
  WebPage = 12,
  SpatialModel = 13,
  DocDocumentPage = 14,
  PptDocumentPage = 15,
  ExcelDocumentPage = 16,
  File = 17,
  StructureModel = 18,
  StructureModelPdf = 19,
  TwoDLineDrawing = 20,
  TwoDLineDrawingPdf = 21,
  AutodeskFile = 22,
  PointCloud = 23,
  BentleyAnalyticsReportPdf = 24,
  UnencodedVideo = 101,
  UnencodedRecordedVideo = 102,
  UnencodedConcatenatedVideo = 103,
  RecordedCloudFileVideo = 104,
  StreamVideo = 105,
}

/**
 *
 *
 * 0 = User
 *
 * 1 = Owner
 *
 * 2 = AIUser
 *
 * 3 = Reviewer
 *
 * 4 = Viewer
 *
 * 5 = Labeler
 *
 * 6 = Approver
 *
 * 7 = Guest
 * @format int32
 */
export enum ContentUserRole {
  User = 0,
  Owner = 1,
  AIUser = 2,
  Reviewer = 3,
  Viewer = 4,
  Labeler = 5,
  Approver = 6,
  Guest = 7,
}

export interface ControlOptionsResponse {
  /** @format int32 */
  Id?: number
  Value?: string | null
  /** @format int32 */
  Order?: number
  Conditions?: ConditionsResponse[] | null
  /** @format int32 */
  VQAPolicyId?: number | null
  /** @format int32 */
  AIExpressionId?: number | null
}

/**
 *
 *
 * 1 = Text
 *
 * 2 = Textarea
 *
 * 3 = Checkboxlist
 *
 * 4 = Radiobuttonlist
 *
 * 5 = Selectlist
 *
 * 6 = Tabs
 *
 * 7 = Counter
 *
 * 8 = Date
 *
 * 9 = DateTime
 * @format int32
 */
export enum ControlType {
  Text = 1,
  Textarea = 2,
  Checkboxlist = 3,
  Radiobuttonlist = 4,
  Selectlist = 5,
  Tabs = 6,
  Counter = 7,
  Date = 8,
  DateTime = 9,
}

export interface Coordinates {
  Start?: Point
  End?: Point
}

export interface CreateAutodeskPageRequest {
  /**
   * Url to file
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @minLength 1
   */
  Url: string
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreateChatMessageRequest {
  /**
   * Text of message
   * @minLength 1
   * @maxLength 4000
   */
  Message: string
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * User id of the recipient of the private message
   * @format int32
   */
  ReceiverId?: number | null
  /**
   * Topic Id (Content Id or Form answer id or Page id) of the selected Topic Type
   * @format int32
   */
  TopicId: number
  /**
   *
   *
   * 1 = Session
   *
   * 2 = Page
   *
   * 3 = FormAnswer
   */
  TopicType?: TopicType
}

export interface CreateChatMessageWithAssetRequest {
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Audio
   */
  Type?: ChatMessageAssetType
  /**
   * Url to file
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   * Duration on a media file
   * @format int32
   * @min 0
   * @max 2147483647
   * @default 0
   */
  Duration?: number
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * User id of the recipient of the private message
   * @format int32
   */
  ReceiverId?: number | null
  /**
   * Topic Id (Content Id or Form answer id or Page id) of the selected Topic Type
   * @format int32
   */
  TopicId: number
  /**
   *
   *
   * 1 = Session
   *
   * 2 = Page
   *
   * 3 = FormAnswer
   */
  TopicType?: TopicType
}

export interface CreateDrawingBoardPageRequest {
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreateExcelPageRequest {
  /**
   * Url to document
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @minLength 1
   */
  Url: string
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreateFilePageRequest {
  /**
   * Url to file
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @minLength 1
   */
  Url: string
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreateFolderRequest {
  /**
   * Folder name
   * @minLength 1
   * @maxLength 400
   */
  Name: string
}

export interface CreateFormAnswerRequest {
  /** @format int32 */
  UserId?: number | null
  /** @format int32 */
  ContentId: number
  /** @format int32 */
  ContentFormId: number
  /** @format int32 */
  FormControlId: number
  /** @format int32 */
  PageId: number
  /** A created form answer value (must not be empty if form control has no any options). */
  Value?: string | null
  /**
   * A specific moment in video when form answer is given (for video pages and recordings).
   * @format double
   */
  Timestamp?: number | null
  /**
   * A list of selected options of form control
   * (may be more than one in case of checkbox control, empty if control has no any options).
   */
  OptionsIds?: number[] | null
  /** A list of selected form tabs of content form (empty if form has no any tab panels). */
  FormTabsIds?: number[] | null
  /** @format date-time */
  CreatedOn?: string | null
}

export interface CreateFormFolderRequest {
  /**
   * Folder name
   * @minLength 1
   * @maxLength 400
   */
  Name: string
}

export interface CreateFormRequest {
  /**
   * A form name.
   * @minLength 1
   * @maxLength 256
   */
  Name: string
  /**
   * A form thumbnail.
   * @maxLength 4000
   */
  IconUrl?: string | null
  /** A list of tabs panels. Each tab panel contains at least one tab. */
  Tabspanels?: FormTabPanelRequest[] | null
  /** A list of form questions. Required if Actions and Counters lists are empty. */
  Controls?: FormControlRequest[] | null
  /** A list of AI counters. Required if Controls and Actions lists are empty. */
  Counters?: FormCounterRequest[] | null
  /** A list of form actions. Required if Controls and Counters lists are empty. */
  Actions?: FormActionRequest[] | null
  /**
   *
   *
   * 0 = Default
   *
   * 1 = List
   */
  FormViewMode?: FormViewMode
  GrantedPermissions?: FormPermissionsByContentRoleDto[] | null
  /**
   * An external information related to current form (Id or any other information).
   * @maxLength 4000
   */
  ExternalData?: string | null
  /**
   * NLP model Id.
   * @format int32
   */
  NLPModelId?: number | null
  /**
   * Id of the folder where the form was created.
   * @format int32
   */
  FolderId?: number | null
  /** The possibility of signing a form by the user. */
  IsSignatureEnabled?: boolean
}

export interface CreateImagePageRequest {
  /**
   * Url to file
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @minLength 1
   */
  Url: string
  /** @format double */
  Latitude?: number | null
  /** @format double */
  Longitude?: number | null
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreatePageFolderRequest {
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * The name of created folder
   * @minLength 1
   * @maxLength 400
   */
  Name: string
  /**
   * Unique id of parent session folder within the system
   * @format int32
   */
  ParentId?: number | null
}

export interface CreatePdfPageRequest {
  /**
   * Url to pdf
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @minLength 1
   */
  Url: string
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreatePointCloudPageModel {
  /**
   * Url to files
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @minItems 1
   */
  Urls: string[]
  /**
   * Url to the orientations file
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)<remarks>The property must be hidden from public API due to being for internal use only</remarks>
   */
  OrientationsUrl?: string | null
  /** @format double */
  Latitude?: number | null
  /** @format double */
  Longitude?: number | null
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreatePowerPointPageRequest {
  /**
   * Url to document
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @minLength 1
   */
  Url: string
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreateProfileRequest {
  /**
   * The first name of user.
   * @minLength 1
   * @maxLength 35
   */
  FirstName: string
  /**
   * The last name of user.
   * @minLength 1
   * @maxLength 35
   */
  LastName: string
  /**
   * The email address for the new account.
   * @format email
   * @maxLength 120
   */
  Email?: string | null
  /** @maxLength 512 */
  Institution?: string | null
  /** @format date-time */
  DateOfBirth?: string | null
  /**
   * The phone number of new account.
   * @minLength 8
   * @maxLength 15
   * @pattern ^[0-9]+$
   */
  PhoneNumber?: string | null
  Address?: UserAddressRequest
  /** The token with which you can register a user. */
  InviteToken?: string | null
  /** The flag with which you can join to session after register. */
  RedirectToContent?: string | null
}

export interface CreatePublishTemplateRequest {
  /** @format int32 */
  AIObjectId?: number
  Columns?: PublishTemplateColumnRequest[] | null
  Name?: string | null
}

export interface CreateSplatModelRequest {
  PageIds?: number[] | null
  PageName?: string | null
  /** @format int32 */
  FolderId?: number | null
}

export interface CreateStructureRequest {
  /**
   * Unique id of the content within the system.
   * @format int32
   */
  ContentId: number
  /**
   * List of pages ids.
   * @minItems 1
   */
  PageIds: number[]
}

export interface CreateUserRequest {
  /**
   * @minLength 1
   * @maxLength 35
   */
  FirstName: string
  /**
   * @minLength 1
   * @maxLength 35
   */
  LastName: string
  /**
   * @maxLength 128
   * @pattern ^[a-zA-Z0-9_-]+$
   */
  EmployeeId?: string | null
  /**
   * @minLength 1
   * @maxLength 120
   */
  Email: string
  /** @minLength 1 */
  Role: string
  Institution?: string | null
  /** @format date-time */
  DateOfBirth?: string | null
  /** @maxLength 15 */
  PhoneNumber?: string | null
  Address?: UserAddressRequest
  /** @format int32 */
  TenantId?: number | null
}

export interface CreateVideoPageRequest {
  /**
   * Url to file
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @minLength 1
   */
  Url: string
  /** @format double */
  Latitude?: number | null
  /** @format double */
  Longitude?: number | null
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreateWebPageRequest {
  /**
   * Url to web page
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreateWordPageRequest {
  /**
   * Url to document
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @minLength 1
   */
  Url: string
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface CreatedFormQuestionResponse {
  /**
   * A created form question Id.
   * @format int32
   */
  Id?: number
  /** A created form question name. */
  Label?: string | null
}

export interface CreatedFormResponse {
  /**
   * A created form Id.
   * @format int32
   */
  FormId?: number
  /** A created form name. */
  Name?: string | null
  /** A list of form questions. */
  Questions?: CreatedFormQuestionResponse[] | null
}

/**
 *
 *
 * 0 = NotDeployed
 *
 * 1 = Succeeded
 *
 * 2 = Stopped
 *
 * 3 = Failed
 *
 * 4 = Creating
 *
 * 5 = Updating
 *
 * 6 = Deleting
 *
 * 100 = SentToDeploy
 *
 * 101 = FailedToSendToDeploy
 * @format int32
 */
export enum DeploymentStatus {
  NotDeployed = 0,
  Succeeded = 1,
  Stopped = 2,
  Failed = 3,
  Creating = 4,
  Updating = 5,
  Deleting = 6,
  SentToDeploy = 100,
  FailedToSendToDeploy = 101,
}

export interface Detection {
  /** @format int32 */
  ClassId?: number
  Name?: string | null
  Color?: string | null
  BoundingBox?: BoundingBoxModel
  /** @format double */
  Probability?: number
}

/**
 *
 *
 * 0 = Unknown
 *
 * 1 = WebBrowser
 *
 * 2 = AndroidDevice
 *
 * 3 = AppleDevice
 *
 * 4 = AndroidBrowser
 *
 * 5 = IosBrowser
 * @format int32
 */
export enum DeviceTypes {
  Unknown = 0,
  WebBrowser = 1,
  AndroidDevice = 2,
  AppleDevice = 3,
  AndroidBrowser = 4,
  IosBrowser = 5,
}

export interface DocumentCreateRequest {
  Pages?: PageCreateRequest[] | null
}

export interface Error {
  /** @format int32 */
  Code: number
  /** @minLength 1 */
  Message: string
  Details?: string | null
}

export interface EulerRotation {
  /** @format double */
  X?: number
  /** @format double */
  Y?: number
  /** @format double */
  Z?: number
}

export interface ExportContentFormsResponse {
  /** Name of the session assigned by the system automatically or changed by the user manually */
  SessionName?: string | null
  /** Exact day and time (in UTC) when a form was exported */
  TimeOfExport?: string | null
  Forms?: ExportFormResponse[] | null
}

export interface ExportFormAnswerResponse {
  TabsIds?: number[] | null
  TabsNames?: string[] | null
  TabsNumbers?: number[] | null
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId?: number
  Title?: string | null
  /**
   * Order of the current question in a form
   * @format int32
   */
  QuestionNumber?: number
  /**
   * Unique id of the form within the system
   * @format int32
   */
  FormId?: number
  /** Name of the form assigned by the system automatically or changed by the user manually */
  FormName?: string | null
  /**
   * Unique id of the question within the system
   * @format int32
   */
  QuestionId?: number | null
  /** Text of the question in a form */
  Question?: string | null
  /** Email of the user who changed the status of a particular answer to a particular form question */
  StatusChanger?: string | null
  /**
   * Exact day and time (in UTC) when the status of a particular answer to a particular form question was changed
   * @format date-time
   */
  StatusChangeTime?: string | null
  /** Email of the user who gave a particular answer to a particular form question */
  Answerer?: string | null
  /**
   * Exact day and time (in UTC) when a particular answer to a particular form question was given
   * @format date-time
   */
  AnswerTime?: string | null
  /**
   *
   *
   * 0 = Pending
   *
   * 1 = Pass
   *
   * 2 = Fail
   *
   * 3 = Empty
   *
   * 4 = ApprovedByAI
   *
   * 5 = RejectedByAI
   *
   * 6 = ApprovedByReviewer
   *
   * 7 = RejectedByReviewer
   */
  Status?: FormAnswerStatus
  /**
   * Unique ids of the answers within the system
   * @format int32
   */
  AnswerId?: number | null
  /** Text of the answer to particular form question */
  Answer?: string | null
  /** Text of additional comments left for a form answer */
  Comments?: FormAnswerCommentModel[] | null
  /** URL to the page on which a particular answer to a particular form question was given */
  PhotoLink?: string | null
}

export interface ExportFormResponse {
  /** Name of the form assigned by the system automatically or changed by the user manually */
  FormName?: string | null
  FormAnswers?: ExportFormAnswerResponse[] | null
}

export interface ExpressionExecutionResult {
  TextResult?: string | null
  Result?: any
  Detections?: Detection[] | null
  Trace?: Record<string, Trace>
}

/**
 *
 *
 * 0 = Unset
 *
 * 1 = Equals
 *
 * 2 = Contains
 *
 * 3 = NotEquals
 *
 * 4 = Empty
 *
 * 5 = NotEmpty
 * @format int32
 */
export enum FilterOperation {
  Unset = 0,
  Equals = 1,
  Contains = 2,
  NotEquals = 3,
  Empty = 4,
  NotEmpty = 5,
}

export interface Filtering {
  Name?: string | null
  Email?: string | null
  Role?: string | null
}

export interface FolderBaseResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  /** @format int32 */
  UserId?: number
  OwnerName?: string | null
  OwnerEmail?: string | null
  OwnerAvatarUrl?: string | null
  FolderUsersPermissions?: FolderPermission[] | null
  IsFolderShared?: boolean
  /**
   *
   *
   * 0 = User
   *
   * 1 = Owner
   *
   * 2 = Viewer
   *
   * 3 = Guest
   */
  Role?: FolderUserRole
  IsDeleted?: boolean
}

/**
 *
 *
 * 1 = CreateContent
 *
 * 2 = AddUsers
 *
 * 3 = EditUsers
 *
 * 4 = MoveContent
 *
 * 5 = LeaveFolder
 *
 * 6 = DeleteFolder
 * @format int32
 */
export enum FolderPermission {
  CreateContent = 1,
  AddUsers = 2,
  EditUsers = 3,
  MoveContent = 4,
  LeaveFolder = 5,
  DeleteFolder = 6,
}

export interface FolderPermissionPermissionsResponse {
  /**
   *
   *
   * 1 = CreateContent
   *
   * 2 = AddUsers
   *
   * 3 = EditUsers
   *
   * 4 = MoveContent
   *
   * 5 = LeaveFolder
   *
   * 6 = DeleteFolder
   */
  Permissions?: FolderPermission
}

/**
 *
 *
 * 0 = User
 *
 * 1 = Owner
 *
 * 2 = Viewer
 *
 * 3 = Guest
 * @format int32
 */
export enum FolderUserRole {
  User = 0,
  Owner = 1,
  Viewer = 2,
  Guest = 3,
}

export interface FormActionRequest {
  /**
   * A workflow id.
   * @minLength 1
   * @maxLength 450
   */
  WorkflowId: string
  /**
   * An action name.
   * @minLength 1
   * @maxLength 256
   */
  Label: string
  /**
   * An order of the current action in the form.
   * @format int32
   */
  Order: number
  /**
   * An informational text displayed when hovering over the action.
   * @maxLength 512
   */
  Tooltip?: string | null
}

export interface FormAnswerCommentModel {
  Email?: string | null
  Reply?: string | null
}

export interface FormAnswerSelectedOptionResponse {
  /** @format int32 */
  Id?: number
  Value?: string | null
  /** @format int32 */
  VQAPolicyId?: number | null
  /** @format int32 */
  AIExpressionId?: number | null
}

/**
 *
 *
 * 0 = Pending
 *
 * 1 = Pass
 *
 * 2 = Fail
 *
 * 3 = Empty
 *
 * 4 = ApprovedByAI
 *
 * 5 = RejectedByAI
 *
 * 6 = ApprovedByReviewer
 *
 * 7 = RejectedByReviewer
 * @format int32
 */
export enum FormAnswerStatus {
  Pending = 0,
  Pass = 1,
  Fail = 2,
  Empty = 3,
  ApprovedByAI = 4,
  RejectedByAI = 5,
  ApprovedByReviewer = 6,
  RejectedByReviewer = 7,
}

export interface FormAnswersJsonExportRequest {
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /** Ids of attached forms */
  FormsIds?: number[] | null
  /**
   * Unique id of the user within the system
   * @format int32
   */
  UserId?: number | null
  /**
   * The id of the page with form answers
   * @format int32
   */
  PageId?: number | null
}

export interface FormConditionRequest {
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = AttachForm
   *
   * 2 = SendEmail
   *
   * 3 = AddVideoByUrl
   *
   * 4 = ShowInfoMessage
   *
   * 5 = RunWorkflow
   */
  Type: ConditionType
  /**
   * A main information used by triggered condition action.
   * For AttachForm condition - FormId, SendEmail - additional text in email, AddVideoByUrl - Video Url, ShowInfoMessage - info message.
   * Optional for SendEmail condition.
   * @maxLength 4000
   */
  Solution?: string | null
  /**
   * An answer value that trigger condition action (For textbox and textarea).
   * @maxLength 128
   */
  TriggerValue?: string | null
  /**
   *
   *
   * 0 = AnyEntry
   *
   * 1 = Equals
   *
   * 2 = GreaterThan
   *
   * 3 = LessThan
   *
   * 4 = UnansweredQuestions
   */
  TriggerType?: ConditionTriggerType
  ConditionsRecipient?: ConditionsRecipientRequest[] | null
}

export interface FormControlAnswerResponse {
  /** @format int32 */
  Id?: number
  Value?: string | null
  /** @format int32 */
  AnswerOrder?: number
  /** @format int32 */
  UserId?: number
  /** @format int32 */
  ContentFormId?: number
  SelectedOptions?: FormAnswerSelectedOptionResponse[] | null
  /** @format int32 */
  FormControlId?: number
  /** @format int32 */
  PageId?: number
  Label?: string | null
  FormName?: string | null
  /** @format int32 */
  FormId?: number
  /** @format date-time */
  CreatedOn?: string | null
  FormTabAnswers?: FormTabAnswerResponse[] | null
  /**
   *
   *
   * 0 = Pending
   *
   * 1 = Pass
   *
   * 2 = Fail
   *
   * 3 = Empty
   *
   * 4 = ApprovedByAI
   *
   * 5 = RejectedByAI
   *
   * 6 = ApprovedByReviewer
   *
   * 7 = RejectedByReviewer
   */
  FormAnswerStatus?: FormAnswerStatus
  /** @format date-time */
  FormAnswerStatusCreatedOn?: string | null
  /** @format int32 */
  ApproverId?: number | null
  ApproverName?: string | null
  /** @format double */
  Timestamp?: number | null
}

export interface FormControlForProjectResponse {
  /** @format int32 */
  Id?: number
  Question?: string | null
  FormName?: string | null
  IconUrl?: string | null
  /** @format int32 */
  TotalCount?: number
}

/**
 *
 *
 * 0 = Unknown
 *
 * 1 = Video
 *
 * 10 = Image
 *
 * 101 = UnencodedVideo
 * @format int32
 */
export enum FormControlInfoContentType {
  Unknown = 0,
  Video = 1,
  Image = 10,
  UnencodedVideo = 101,
}

export interface FormControlInfoRequest {
  Description?: string | null
  /**
   * A question text.
   * @maxLength 4000
   */
  ControlLabel?: string | null
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Video
   *
   * 10 = Image
   *
   * 101 = UnencodedVideo
   */
  Type?: FormControlInfoContentType
  /**
   * An additional information content url.
   * @maxLength 4000
   */
  Url?: string | null
  /**
   * An additional information content file name.
   * @maxLength 256
   */
  FileName?: string | null
  /**
   * An additional information content thumnail url (For video files only).
   * @maxLength 4000
   */
  ThumbnailUrl?: string | null
  /**
   * An additional information content encoded url (For video files only).
   * @maxLength 4000
   */
  EncodedUrl?: string | null
}

export interface FormControlOptionRequest {
  /**
   * A name of the option.
   * @minLength 1
   * @maxLength 128
   */
  Value: string
  /**
   * An order of the option in current control.
   * @format int32
   */
  Order: number
  /** A list of actions that can be triggered by selecting current option (for Dropdown, Checkbox or Radiobutton control). */
  Conditions?: FormConditionRequest[] | null
}

export interface FormControlRequest {
  /**
   *
   *
   * 1 = Text
   *
   * 2 = Textarea
   *
   * 3 = Checkboxlist
   *
   * 4 = Radiobuttonlist
   *
   * 5 = Selectlist
   *
   * 6 = Tabs
   *
   * 7 = Counter
   *
   * 8 = Date
   *
   * 9 = DateTime
   */
  ControlType: ControlType
  /**
   * An informational text displayed when hovering over the control.
   * @maxLength 512
   */
  Tooltip?: string | null
  /**
   * A hint text for possible content in control field.
   * @maxLength 256
   */
  Placeholder?: string | null
  /**
   * A default answer for current question (For textbox and textarea only).
   * @maxLength 512
   */
  InitialValue?: string | null
  /** A list of control options (For dropdown, checkbox and radiobutton). */
  Options?: FormControlOptionRequest[] | null
  Info?: FormControlInfoRequest
  /**
   *
   *
   * 0 = Basic
   *
   * 1 = BodyTemperature
   *
   * 2 = PhoneNumber
   *
   * 3 = Email
   *
   * 4 = BarcodeAndQRCode
   */
  Type?: FormControlType
  /**
   * A question text.
   * @minLength 1
   * @maxLength 4000
   */
  Label: string
  /**
   * An order of the current question in the form.
   * @format int32
   */
  Order: number
  /** @format int32 */
  IncrementValue?: number | null
  /** @format int32 */
  IncrementPeriod?: number | null
  /** A list of actions that can be triggered by answering current question (for Textbox or Textarea control). */
  Conditions?: FormConditionRequest[] | null
  /**
   * An external information related to current control (Id or any other information).
   * @maxLength 4000
   */
  ExternalData?: string | null
  /**
   * Determines whether the form control should be processed by VQA model. By default - false.
   * @default false
   */
  ProcessByVQAModel?: boolean
}

/**
 *
 *
 * 0 = Basic
 *
 * 1 = BodyTemperature
 *
 * 2 = PhoneNumber
 *
 * 3 = Email
 *
 * 4 = BarcodeAndQRCode
 * @format int32
 */
export enum FormControlType {
  Basic = 0,
  BodyTemperature = 1,
  PhoneNumber = 2,
  Email = 3,
  BarcodeAndQRCode = 4,
}

export interface FormCounterRequest {
  /**
   * A counter limit. Can be used to trigger counter conditions when exceeded. If null - unlimited.
   * @format int32
   */
  CounterLimit?: number | null
  /**
   * CV class Id.
   * @format int32
   */
  AIObjectId: number
  /** Indicates whether the counter should count CV class unique objects or not. */
  CountAIObjects?: boolean
  /**
   * A counter name.
   * @minLength 1
   * @maxLength 256
   */
  Label: string
  /**
   * An order of the current counter in the form.
   * @format int32
   */
  Order: number
  /** @format int32 */
  IncrementValue?: number | null
  /** @format int32 */
  IncrementPeriod?: number | null
  /** A list of actions that can be triggered by exceeding counter limit. */
  Conditions?: FormConditionRequest[] | null
}

export interface FormFolderRequest {
  /**
   * Unique id of a form within the system.
   * @format int32
   */
  FormId: number
  /**
   * Unique id of a form folder within the system.
   * If provided, defines the folder to move a form to.
   * Otherwise, the form will be moved outside the folder.
   * @format int32
   */
  ToFolderId?: number | null
  /**
   * Unique id of a form folder within the system.
   * If provided, defines the folder to move a form from.
   * Otherwise, the form is not expected to initially belong to any folder.
   * @format int32
   */
  FromFolderId?: number | null
}

export interface FormFolderResponse {
  /** @format int32 */
  FolderId?: number
  Name?: string | null
  /** @format int32 */
  UserId?: number
  CanBeDeleted?: boolean
  /** @format int32 */
  TenantId?: number | null
  TenantName?: string | null
  PublishedFolderTenants?: TenantResponse[] | null
}

export interface FormForProjectResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  IconUrl?: string | null
  /** @format date-time */
  CreatedOn?: string | null
  /** @format int32 */
  TotalCount?: number
}

/**
 *
 *
 * 0 = Nothing
 *
 * 1 = ViewAndAnswerForm
 *
 * 2 = ViewOtherUsersAnswers
 * @format int32
 */
export enum FormPermissions {
  Nothing = 0,
  ViewAndAnswerForm = 1,
  ViewOtherUsersAnswers = 2,
}

export interface FormPermissionsByContentRoleDto {
  /**
   *
   *
   * 0 = User
   *
   * 1 = Owner
   *
   * 2 = AIUser
   *
   * 3 = Reviewer
   *
   * 4 = Viewer
   *
   * 5 = Labeler
   *
   * 6 = Approver
   *
   * 7 = Guest
   */
  ContentRoleId?: ContentUserRole
  FormPermissionsIds?: FormPermissions[] | null
}

export interface FormResponse {
  /**
   * A form id.
   * @format int32
   */
  FormId?: number
  /**
   * A form creator user id.
   * @format int32
   */
  UserId?: number
  User?: FormViewUserResponse
  /** A form name. */
  Name?: string | null
  /**
   * A form creation date.
   * @format date-time
   */
  CreatedOn?: string | null
  /** A form thumbnail. */
  IconUrl?: string | null
  /**
   * A number of form questions.
   * @format int32
   */
  ControlsNumber?: number
  /**
   * A number of sessions where current form is attached.
   * @format int32
   */
  SessionsNumber?: number
  /**
   * A total number of answers given in current form in all sessions.
   * @format int32
   */
  AnswersNumber?: number
  /** @format int32 */
  TenantId?: number | null
  TenantName?: string | null
  /**
   * Indicates that form is not created by current user, but published by tenant admin.
   * Published forms are available for every user of this tenant.
   */
  PublishedFormTenants?: TenantResponse[] | null
}

export interface FormTabAnswerResponse {
  /** @format int32 */
  FormTabId?: number
  FormTabName?: string | null
}

export interface FormTabPanelRequest {
  /**
   * An order of the tab panel in the form.
   * @format int32
   */
  Order: number
  /** A tab panel name. */
  Name?: string | null
  /** A list of tabs in current panel. */
  Tabs: FormTabRequest[]
}

export interface FormTabRequest {
  /**
   * A name of the tab.
   * @minLength 1
   * @maxLength 128
   */
  Value: string
  /**
   * An order of the tab in current tab panel.
   * @format int32
   */
  Order: number
}

/**
 *
 *
 * 0 = Default
 *
 * 1 = List
 * @format int32
 */
export enum FormViewMode {
  Default = 0,
  List = 1,
}

export interface FormViewUserResponse {
  /** A form creator user email. */
  Email?: string | null
  /** A form creator user first name. */
  FirstName?: string | null
  /** A form creator user last name. */
  LastName?: string | null
  /** @format int32 */
  Id?: number
}

export interface GetAIObjectAnalysisRequest {
  Columns?: PublishTemplateColumnRequest[] | null
  Search?: string | null
  SearchFields?: string[] | null
}

/**
 *
 *
 * 0 = None
 *
 * 1 = Node
 *
 * 2 = Edge
 *
 * 3 = EdgeDirected
 * @format int32
 */
export enum GraphElementType {
  None = 0,
  Node = 1,
  Edge = 2,
  EdgeDirected = 3,
}

/**
 *
 *
 * 0 = Unset
 *
 * 1 = Max
 *
 * 2 = Count
 * @format int32
 */
export enum GroupingOperation {
  Unset = 0,
  Max = 1,
  Count = 2,
}

export type IActionResult = object

export interface InviteQuickLinkPasswordUpdateRequest {
  /**
   * Quick access link password
   * @minLength 6
   */
  Password: string
}

export interface InviteResponse {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  UserId?: number | null
  User?: UserInfoResponse
  /** @format int32 */
  RecipientId?: number | null
  /** @format uuid */
  Token?: string | null
  Recipient?: UserInfoResponse
  RecipientEmail?: string | null
  Accepted?: boolean
  Ignored?: boolean
  /** @format int32 */
  ContentId?: number
  /**
   *
   *
   * 0 = User
   *
   * 1 = Owner
   *
   * 2 = AIUser
   *
   * 3 = Reviewer
   *
   * 4 = Viewer
   *
   * 5 = Labeler
   *
   * 6 = Approver
   *
   * 7 = Guest
   */
  Role?: ContentUserRole
  ContentDescription?: string | null
  /** @format date-time */
  StartDate?: string | null
  /** @format date-time */
  EndDate?: string | null
  /** @format date-time */
  CreatedOn?: string
  LinkAccess?: boolean
  IdentityProtected?: boolean
}

export interface InviteRoleUpdateRequest {
  /**
   *
   *
   * 0 = User
   *
   * 1 = Owner
   *
   * 2 = AIUser
   *
   * 3 = Reviewer
   *
   * 4 = Viewer
   *
   * 5 = Labeler
   *
   * 6 = Approver
   *
   * 7 = Guest
   */
  Role: ContentUserRole
}

export interface InviteTimeUpdateRequest {
  /** @format date-time */
  StartDate?: string | null
  /** @format date-time */
  EndDate?: string | null
}

export interface InvitesCreateRequest {
  /** @format int32 */
  ContentId: number
  /** @minItems 1 */
  Emails: string[]
  /** @format date-time */
  StartDate?: string | null
  /** @format date-time */
  EndDate?: string | null
  /**
   *
   *
   * 0 = User
   *
   * 1 = Owner
   *
   * 2 = AIUser
   *
   * 3 = Reviewer
   *
   * 4 = Viewer
   *
   * 5 = Labeler
   *
   * 6 = Approver
   *
   * 7 = Guest
   */
  Role: ContentUserRole
  Password?: string | null
}

export interface IterationDeployStartByAIModelRequest {
  /** @format int32 */
  AIModelId: number
}

export interface IterationDeployStartByVQAModelRequest {
  /** @format int32 */
  VQAModelId: number
}

export interface IterationDeployStartRequest {
  /** @format int32 */
  IterationId: number
}

export interface IterationDeployStatusRequest {
  /**
   * Unique id of the project within the system.
   * @format int32
   */
  ProjectId?: number | null
  /**
   * Unique id of the iteration within the system.
   * @format int32
   */
  IterationId: number
  /** The url to access the deployed model. Required if the Status is 1 (Running). */
  Url?: string | null
  /** The key which provides access to the deployed model. Required if the Status is 1 (Running). */
  Key?: string | null
  /**
   *
   *
   * 0 = NotDeployed
   *
   * 1 = Succeeded
   *
   * 2 = Stopped
   *
   * 3 = Failed
   *
   * 4 = Creating
   *
   * 5 = Updating
   *
   * 6 = Deleting
   *
   * 100 = SentToDeploy
   *
   * 101 = FailedToSendToDeploy
   */
  Status: DeploymentStatus
  /**
   * Exact day and time (in UTC) when an iteration model will be stopped.
   * @format date-time
   */
  ExpirationTime?: string | null
  /** The text of the error. */
  ErrorMessage?: string | null
}

export interface IterationResponse {
  /** The name of the iteration. */
  Name?: string | null
  /**
   * The order of the iteration.
   * @format int32
   */
  Order?: number
  /**
   *
   *
   * 0 = NotStarted
   *
   * 1 = Starting
   *
   * 2 = Provisioning
   *
   * 3 = Preparing
   *
   * 4 = Queued
   *
   * 5 = Running
   *
   * 6 = Finalizing
   *
   * 7 = CancelRequested
   *
   * 8 = Completed
   *
   * 9 = Failed
   *
   * 10 = Canceled
   *
   * 11 = NotResponding
   *
   * 100 = SentToStart
   *
   * 101 = FailedToSendToStart
   */
  Status?: IterationStatus
  /** The text of the error. */
  ErrorMessage?: string | null
  /** The text of the warning. */
  WarningMessage?: string | null
  /**
   *
   *
   * 0 = NotDeployed
   *
   * 1 = Succeeded
   *
   * 2 = Stopped
   *
   * 3 = Failed
   *
   * 4 = Creating
   *
   * 5 = Updating
   *
   * 6 = Deleting
   *
   * 100 = SentToDeploy
   *
   * 101 = FailedToSendToDeploy
   */
  DeploymentStatus?: DeploymentStatus
  /**
   * The date when the iteration's deployment expires.
   * @format date-time
   */
  DeploymentExpirationTime?: string | null
  /**
   * Unique id of the user who has started the iteration.
   * @format int32
   */
  UserId?: number
  /**
   * Unique id of the project within the system.
   * @format int32
   */
  ProjectId?: number
  /**
   * The date when the iteration's training status has been updated.
   * @format date-time
   */
  StatusUpdatedOn?: string | null
  /** Unique ids of the users who created annotations. */
  AnnotationOwnerIds?: number[] | null
  /**
   * Start date of the annotations' creation.
   * @format date-time
   */
  AnnotationStartDate?: string | null
  /**
   * End date of the annotations' creation.
   * @format date-time
   */
  AnnotationEndDate?: string | null
  /** Unique ids of the users who reviewed the answers. */
  ReviewerIds: number[]
  /** Unique ids of the users who answered questions. */
  AnswererIds: number[]
  /** FormAnswer Statuses. */
  FormAnswerStatuses: FormAnswerStatus[]
  /** Unique ids of the form controls. */
  FormControlIds: number[]
  /** The value of the form answer. */
  FormAnswerValue?: string | null
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 0 = NotStarted
 *
 * 1 = Starting
 *
 * 2 = Provisioning
 *
 * 3 = Preparing
 *
 * 4 = Queued
 *
 * 5 = Running
 *
 * 6 = Finalizing
 *
 * 7 = CancelRequested
 *
 * 8 = Completed
 *
 * 9 = Failed
 *
 * 10 = Canceled
 *
 * 11 = NotResponding
 *
 * 100 = SentToStart
 *
 * 101 = FailedToSendToStart
 * @format int32
 */
export enum IterationStatus {
  NotStarted = 0,
  Starting = 1,
  Provisioning = 2,
  Preparing = 3,
  Queued = 4,
  Running = 5,
  Finalizing = 6,
  CancelRequested = 7,
  Completed = 8,
  Failed = 9,
  Canceled = 10,
  NotResponding = 11,
  SentToStart = 100,
  FailedToSendToStart = 101,
}

/**
 *
 *
 * 1 = FineTuneCLIP
 *
 * 2 = ObjectDetection
 *
 * 3 = InstanceSegmentation
 *
 * 4 = ClassificationWithPretrainedEncoder
 *
 * 8 = ImageNLPClassificationWithPretrainedEncoder
 *
 * 9 = ObjectDetectionYolox
 * @format int32
 */
export enum IterationType {
  FineTuneCLIP = 1,
  ObjectDetection = 2,
  InstanceSegmentation = 3,
  ClassificationWithPretrainedEncoder = 4,
  ImageNLPClassificationWithPretrainedEncoder = 8,
  ObjectDetectionYolox = 9,
}

export interface IterationUpdateRequest {
  /**
   * Unique id of the iteration within the system.
   * @format int32
   */
  IterationId: number
  /**
   * A new name of the iteration.
   * @minLength 1
   * @maxLength 500
   */
  Name: string
}

export interface JwtTokenResponse {
  /**
   * Access token for the application
   * @minLength 1
   * @maxLength 1024
   */
  access_token: string
  /**
   * Token lifetime in seconds
   * @format int64
   */
  expires_in?: number
  /**
   * Type of created token
   * @minLength 1
   * @maxLength 24
   */
  token_type: string
  /**
   * Session id, if token created for concrete session
   * @format int32
   */
  content_id?: number | null
}

/**
 *
 *
 * 0 = Pending
 *
 * 1 = Active
 *
 * 2 = MergingInProgress
 *
 * 3 = Rejected
 * @format int32
 */
export enum LinkedContentStatus {
  Pending = 0,
  Active = 1,
  MergingInProgress = 2,
  Rejected = 3,
}

export interface LocationResponse {
  /** @format double */
  Latitude?: number
  /** @format double */
  Longitude?: number
}

/**
 *
 *
 * 0 = And
 *
 * 1 = Or
 * @format int32
 */
export enum LogicalOperation {
  And = 0,
  Or = 1,
}

export interface MarkChatMessageAsReadRequest {
  /**
   * Unique id of the session within the system
   * @format int32
   */
  MessageId: number
}

export interface MetadataResponse {
  /** @format double */
  FieldOfView?: number | null
  /** @format double */
  Altitude?: number | null
  /** @format double */
  Azimuth?: number | null
  /** @format double */
  GimbalPitch?: number | null
  ZoomRatio?: string | null
  /** @format int32 */
  Id?: number
}

export interface NLPModelCreateRequest {
  /**
   * The URL to the model.
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   * The name of the model.
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * The description of the model.
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
}

export interface NLPModelResponse {
  /**
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
  /** @format int32 */
  TenantId: number
  /** @format date-time */
  CreatedOn?: string | null
  /** @format int32 */
  Id?: number
}

export interface NLPModelUpdateRequest {
  /**
   * Unique id of the model within the system
   * @format int32
   */
  Id?: number
  /**
   * The URL to the model.
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   * The name of the model.
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * The description of the model.
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
}

export interface NotifyFileReadyRequest {
  /**
   * Unique id of the session within the system.
   * @format int32
   */
  ContentId: number
  /**
   * Url to a file
   * <br>Only links to collaborate blob storage are supported (can be received via api/uploads)
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   *
   *
   * 0 = Pdf
   *
   * 1 = Excel
   */
  FileType: ReportFileType
}

/**
 *
 *
 * 0 = YoloXSmall
 *
 * 1 = YoloXMedium
 *
 * 2 = YoloXLarge
 *
 * 3 = FasterRCNN50
 *
 * 4 = FasterRCNN101
 *
 * 5 = FasterRCNN101X
 *
 * 6 = MaskRCNN50
 *
 * 7 = MaskRCNN101
 *
 * 8 = MaskRCNN101X
 *
 * 9 = CascadeMaskRCNN50
 * @format int32
 */
export enum ObjectDetectorType {
  YoloXSmall = 0,
  YoloXMedium = 1,
  YoloXLarge = 2,
  FasterRCNN50 = 3,
  FasterRCNN101 = 4,
  FasterRCNN101X = 5,
  MaskRCNN50 = 6,
  MaskRCNN101 = 7,
  MaskRCNN101X = 8,
  CascadeMaskRCNN50 = 9,
}

export interface PageBaseRequest {
  /** @maxLength 256 */
  Name?: string | null
  /** @maxLength 4000 */
  Description?: string | null
  /**
   *
   *
   * 0 = Standard
   *
   * 1 = Text
   *
   * 2 = EncodingPending
   */
  Type?: PageType
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Video
   *
   * 2 = Text
   *
   * 3 = Pdf
   *
   * 4 = VideoByUrl
   *
   * 5 = CloudFile
   *
   * 6 = RecordedVideo
   *
   * 7 = Audio
   *
   * 8 = DrawingBoard
   *
   * 9 = Snapshot
   *
   * 10 = Image
   *
   * 11 = ConcatenatedVideo
   *
   * 12 = WebPage
   *
   * 13 = SpatialModel
   *
   * 14 = DocDocumentPage
   *
   * 15 = PptDocumentPage
   *
   * 16 = ExcelDocumentPage
   *
   * 17 = File
   *
   * 18 = StructureModel
   *
   * 19 = StructureModelPdf
   *
   * 20 = TwoDLineDrawing
   *
   * 21 = TwoDLineDrawingPdf
   *
   * 22 = AutodeskFile
   *
   * 23 = PointCloud
   *
   * 24 = BentleyAnalyticsReportPdf
   *
   * 101 = UnencodedVideo
   *
   * 102 = UnencodedRecordedVideo
   *
   * 103 = UnencodedConcatenatedVideo
   *
   * 104 = RecordedCloudFileVideo
   *
   * 105 = StreamVideo
   */
  ContentType?: ContentType
  /** @format int32 */
  Order?: number | null
  Assets?: AssetRequest[] | null
  Annotations?: AnnotationResponse[] | null
  Owner?: BaseUserInfoWithoutAvatarResponse
  /** @format int32 */
  DocumentId?: number
  /** @format int32 */
  ContentId?: number
  Content?: string | null
  /** @format date-time */
  CreatedOn?: string
  /** @format int32 */
  TimeZoneOffset?: number
  /** @format int64 */
  DateTimeOffset?: number
  PdfURL?: string | null
  /** @format int32 */
  PdfPageIndex?: number | null
  /** @format int32 */
  Width?: number | null
  /** @format int32 */
  Height?: number | null
  IsRecorded?: boolean
  ThumbnailUrl?: string | null
  /** @format int32 */
  FolderId?: number | null
  /** @format int32 */
  CopyOfId?: number | null
  Tags?: PageTagRequest[] | null
  PageVideoRestoreRequested?: boolean
  /**
   *
   *
   * 0 = Creating
   *
   * 1 = Created
   */
  Status?: PageStatus
  /** @format int32 */
  Id?: number
}

export interface PageCreateRequest {
  /** @maxLength 256 */
  Name?: string | null
  /** @maxLength 4000 */
  Description?: string | null
  /**
   *
   *
   * 0 = Standard
   *
   * 1 = Text
   *
   * 2 = EncodingPending
   */
  Type?: PageType
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Video
   *
   * 2 = Text
   *
   * 3 = Pdf
   *
   * 4 = VideoByUrl
   *
   * 5 = CloudFile
   *
   * 6 = RecordedVideo
   *
   * 7 = Audio
   *
   * 8 = DrawingBoard
   *
   * 9 = Snapshot
   *
   * 10 = Image
   *
   * 11 = ConcatenatedVideo
   *
   * 12 = WebPage
   *
   * 13 = SpatialModel
   *
   * 14 = DocDocumentPage
   *
   * 15 = PptDocumentPage
   *
   * 16 = ExcelDocumentPage
   *
   * 17 = File
   *
   * 18 = StructureModel
   *
   * 19 = StructureModelPdf
   *
   * 20 = TwoDLineDrawing
   *
   * 21 = TwoDLineDrawingPdf
   *
   * 22 = AutodeskFile
   *
   * 23 = PointCloud
   *
   * 24 = BentleyAnalyticsReportPdf
   *
   * 101 = UnencodedVideo
   *
   * 102 = UnencodedRecordedVideo
   *
   * 103 = UnencodedConcatenatedVideo
   *
   * 104 = RecordedCloudFileVideo
   *
   * 105 = StreamVideo
   */
  ContentType?: ContentType
  /** @format int32 */
  Order?: number | null
  /** @format int32 */
  Width?: number | null
  /** @format int32 */
  Height?: number | null
}

/**
 *
 *
 * 1 = FullAccess
 * @format int32
 */
export enum PageFolderPermission {
  FullAccess = 1,
}

export interface PageFolderPermissionModel {
  /** @format int32 */
  UserId?: number
  /** @format int32 */
  FolderId?: number
  Permissions?: PageFolderPermission[] | null
}

export interface PageFolderResponse {
  /** @format int32 */
  UserId?: number
  Name?: string | null
  /** @format int32 */
  ParentId?: number | null
  /** @format int32 */
  Order?: number
  /** @format int32 */
  DocumentId?: number
  IsPrivate?: boolean
  /** @format int32 */
  Id?: number
}

export interface PageForIterationResponse {
  ThumbnailURL?: string | null
  Name?: string | null
  /** @format int32 */
  ContentId?: number
  IsActive?: boolean
  /** @format int32 */
  TotalCount?: number
  /** @format int32 */
  Id?: number
}

export interface PageIdRequest {
  /**
   * Unique id of the entity within the system
   * @format int32
   */
  Id?: number
}

export interface PageInfoResponse {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  UserId?: number
  /** @format int32 */
  TenantId?: number
  /** @format int32 */
  DocumentId?: number | null
  /** @format int32 */
  CopyOfId?: number | null
  /** @format int32 */
  FolderId?: number | null
  FolderName?: string | null
  PdfURL?: string | null
  /** @format int32 */
  PdfPageIndex?: number | null
  Name?: string | null
  Description?: string | null
  /**
   *
   *
   * 0 = Standard
   *
   * 1 = Text
   *
   * 2 = EncodingPending
   */
  Type?: PageType
  /** @format int32 */
  Order?: number
  IsDeleted?: boolean
  /** @format date-time */
  CreatedOn?: string | null
  /** @format int32 */
  Width?: number | null
  /** @format int32 */
  Height?: number | null
  /** @format date-time */
  ThumbnailUpdated?: string | null
  /** @format int32 */
  TimeZoneOffset?: number
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Video
   *
   * 2 = Text
   *
   * 3 = Pdf
   *
   * 4 = VideoByUrl
   *
   * 5 = CloudFile
   *
   * 6 = RecordedVideo
   *
   * 7 = Audio
   *
   * 8 = DrawingBoard
   *
   * 9 = Snapshot
   *
   * 10 = Image
   *
   * 11 = ConcatenatedVideo
   *
   * 12 = WebPage
   *
   * 13 = SpatialModel
   *
   * 14 = DocDocumentPage
   *
   * 15 = PptDocumentPage
   *
   * 16 = ExcelDocumentPage
   *
   * 17 = File
   *
   * 18 = StructureModel
   *
   * 19 = StructureModelPdf
   *
   * 20 = TwoDLineDrawing
   *
   * 21 = TwoDLineDrawingPdf
   *
   * 22 = AutodeskFile
   *
   * 23 = PointCloud
   *
   * 24 = BentleyAnalyticsReportPdf
   *
   * 101 = UnencodedVideo
   *
   * 102 = UnencodedRecordedVideo
   *
   * 103 = UnencodedConcatenatedVideo
   *
   * 104 = RecordedCloudFileVideo
   *
   * 105 = StreamVideo
   */
  ContentType?: ContentType
  ThumbnailURL?: string | null
  /** @format date-time */
  UpdatedOn?: string | null
  /**
   *
   *
   * 0 = NotProcessed
   *
   * 1 = Processed
   */
  PageTagsStatus?: PageTagsStatus
  AssetModels?: AssetResponse[] | null
  AnnotationsModels?: AnnotationInfoResponse[] | null
}

/**
 *
 *
 * 0 = Creating
 *
 * 1 = Created
 * @format int32
 */
export enum PageStatus {
  Creating = 0,
  Created = 1,
}

export interface PageTagRequest {
  Name?: string | null
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 0 = NotProcessed
 *
 * 1 = Processed
 * @format int32
 */
export enum PageTagsStatus {
  NotProcessed = 0,
  Processed = 1,
}

/**
 *
 *
 * 0 = Standard
 *
 * 1 = Text
 *
 * 2 = EncodingPending
 * @format int32
 */
export enum PageType {
  Standard = 0,
  Text = 1,
  EncodingPending = 2,
}

export interface PhotoResponse {
  Url?: string | null
  /** @format int32 */
  Width?: number
  /** @format int32 */
  Height?: number
}

export interface PlaceResponse {
  Id?: string | null
  Address?: string | null
  Name?: string | null
  Geolocation?: LocationResponse
  Photos?: PhotoResponse[] | null
}

export interface PlacesPagedResponse {
  Items?: PlaceResponse[] | null
  NextPageToken?: string | null
}

export interface Point {
  /** @format double */
  X?: number
  /** @format double */
  Y?: number
  /** @format double */
  Z?: number | null
}

/**
 *
 *
 * 0 = NotCreated
 *
 * 1 = ModelCreating
 *
 * 2 = ModelCreated
 * @format int32
 */
export enum PointCloudModelStatus {
  NotCreated = 0,
  ModelCreating = 1,
  ModelCreated = 2,
}

export interface PointCloudPageSettingsResponse {
  /** @format int32 */
  PageId?: number
  /** @format double */
  MeasurementsScaleFactor?: number
}

export interface PointCloudPageSettingsUpdateRequest {
  /**
   * @format double
   * @min 0
   */
  MeasurementsScaleFactor: number
}

export interface PredictionSetUpdateRequest {
  /** @format int32 */
  Id: number
  RunOnPageUpload?: boolean
  RunOnFormAnswer?: boolean
  AIObjects: AIObjectPredictionSetUpdateRequest[]
}

export interface PredictionSetViewResponse {
  /**
   * Unique id of the AIModel within the system.
   * @format int32
   */
  AIModelId?: number
  /**
   * Exact day and time (in UTC) when a prediction set was updated.
   * @format date-time
   */
  UpdatedOn?: string | null
  /** Process a page uploaded to a gallery. */
  RunOnPageUpload?: boolean
  /** Process a page which has an answer on a form. */
  RunOnFormAnswer?: boolean
  /** Defines whether the AIModel and PredictionSet belong to the same tenant. */
  IsOwnModel?: boolean
  /** A list of AIObjects. */
  AIObjects?: AIObjectPredictionResponse[] | null
  /** Required if AIModel's Type is CustomVision or HuggingFace. */
  Key?: string | null
  /**
   *
   *
   * 0 = CustomVision
   *
   * 1 = External
   *
   * 2 = InternalTrained
   *
   * 3 = HuggingFace
   *
   * 4 = Roboflow
   */
  Type?: AIModelType
  /**
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
  /** @format int32 */
  TenantId: number
  /** @format date-time */
  CreatedOn?: string | null
  /** @format int32 */
  Id?: number
}

export interface PrepareSplatModelResponse {
  PageIds?: number[] | null
  PageName?: string | null
  /** @format int32 */
  FolderId?: number | null
}

/**
 *
 *
 * 0 = CLIP
 *
 * 1 = DinoV2Base
 *
 * 2 = SimCLR
 *
 * 3 = VitSmall
 *
 * 4 = ResnetRS50
 *
 * 5 = SwinTransformersBase
 *
 * 6 = ConvnextTiny
 * @format int32
 */
export enum PretrainedModelName {
  CLIP = 0,
  DinoV2Base = 1,
  SimCLR = 2,
  VitSmall = 3,
  ResnetRS50 = 4,
  SwinTransformersBase = 5,
  ConvnextTiny = 6,
}

export interface ProjectListResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  Description?: string | null
  /** @format int32 */
  UserId?: number
  /**
   *
   *
   * 0 = VQA
   *
   * 100 = ImageClassification
   *
   * 101 = ObjectDetection
   */
  Type?: ProjectType
  /** @format date-time */
  CreatedOn?: string | null
  /** @format int32 */
  TotalCount?: number
}

export interface ProjectPrepareRequest {
  /**
   * The name of a project. Optional
   * @maxLength 500
   */
  Name?: string | null
  /**
   * The description of a project.
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
  /**
   *
   *
   * 0 = VQA
   *
   * 100 = ImageClassification
   *
   * 101 = ObjectDetection
   */
  Type?: ProjectType
}

export interface ProjectResponse {
  /**
   * The name of the project.
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * The description of the project.
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
  /**
   * Unique id of the user within the system.
   * @format int32
   */
  UserId: number
  /**
   *
   *
   * 0 = VQA
   *
   * 100 = ImageClassification
   *
   * 101 = ObjectDetection
   */
  Type?: ProjectType
  /**
   * Exact day and time (in UTC) when a project was created.
   * @format date-time
   */
  CreatedOn?: string
  /** @format int32 */
  Id?: number
}

export interface ProjectTrainMetricResponse {
  /**
   * The name of a metric.
   * @minLength 1
   */
  Name: string
  /**
   * The values of a metric. The values must be sorted from the earliest to the latest.
   * @minItems 1
   */
  Values: number[]
}

export interface ProjectTrainStartRequest {
  /**
   * Unique id of the project within the system.
   * @format int32
   */
  ProjectId: number
  /**
   *
   *
   * 1 = FineTuneCLIP
   *
   * 2 = ObjectDetection
   *
   * 3 = InstanceSegmentation
   *
   * 4 = ClassificationWithPretrainedEncoder
   *
   * 8 = ImageNLPClassificationWithPretrainedEncoder
   *
   * 9 = ObjectDetectionYolox
   */
  IterationType: IterationType
  AdvancedSettings?: ProjectTrainingAdvancedSettingsRequest
  /** Unique ids of the Contents within the system. */
  ContentIds: number[]
  /** Unique ids of the AIObjects within the system. */
  AIObjectIds: number[]
  /** Unique ids of the Forms within the system. */
  FormIds: number[]
  /** Unique ids of the users who created annotations. */
  UserIds: number[]
  /** Unique ids of the pages that should be excluded from the response. */
  ExcludedPageIds: number[]
  /**
   * Start date of the annotations' creation.
   * @format date-time
   */
  StartDate?: string | null
  /**
   * End date of the annotations' creation.
   * @format date-time
   */
  EndDate?: string | null
  /** Unique ids of the users who approved the answers. */
  ApproverIds?: number[] | null
  /** Unique ids of the users who reviewed the answers. */
  ReviewerIds: number[]
  /** Unique ids of the users who answered questions. */
  AnswererIds: number[]
  /** FormAnswer Statuses. */
  FormAnswerStatuses: FormAnswerStatus[]
  /** Unique ids of the form controls. */
  FormControlIds: number[]
  /** The value of the form answer. */
  FormAnswerValue?: string | null
}

export interface ProjectTrainUpdatedRequest {
  /**
   * Unique id of the project within the system.
   * @format int32
   */
  ProjectId: number
  /**
   * Unique id of the iteration within the system.
   * @format int32
   */
  IterationId?: number
  /**
   *
   *
   * 0 = NotStarted
   *
   * 1 = Starting
   *
   * 2 = Provisioning
   *
   * 3 = Preparing
   *
   * 4 = Queued
   *
   * 5 = Running
   *
   * 6 = Finalizing
   *
   * 7 = CancelRequested
   *
   * 8 = Completed
   *
   * 9 = Failed
   *
   * 10 = Canceled
   *
   * 11 = NotResponding
   *
   * 100 = SentToStart
   *
   * 101 = FailedToSendToStart
   */
  Status: IterationStatus
  /** The text of the error. */
  ErrorMessage?: string | null
  /** The text of the warning. */
  WarningMessage?: string | null
  /**
   * Exact day and time (in UTC) when an iteration has been trained.
   * @format date-time
   */
  EventTime: string
  /** Training metrics. */
  Metrics?: ProjectTrainMetricResponse[] | null
}

export interface ProjectTrainingAdvancedSettingsRequest {
  /**
   * @format double
   * @min 0.1
   * @max 0.4
   */
  TestDataSize?: number
  /**
   * @format int32
   * @min 1
   * @max 7
   */
  NumberOfCrossValidationFolds?: number
  /**
   * @format int32
   * @min 2
   * @max 64
   */
  BatchSize?: number
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  NumberOfEpochs?: number
  /**
   * @format double
   * @max 0.1
   */
  LearningRate?: number
  /**
   * @format double
   * @min 0.01
   * @max 0.99
   */
  ConfidenceThreshold?: number
  /**
   *
   *
   * 0 = CLIP
   *
   * 1 = DinoV2Base
   *
   * 2 = SimCLR
   *
   * 3 = VitSmall
   *
   * 4 = ResnetRS50
   *
   * 5 = SwinTransformersBase
   *
   * 6 = ConvnextTiny
   */
  PretrainedModelName?: PretrainedModelName
  /**
   *
   *
   * 0 = MLP
   *
   * 1 = LogisticRegression
   */
  ClassifierType?: ClassifierType
  /**
   * @format double
   * @min 0.0001
   * @max 0.01
   */
  EarlyStoppingMinDelta?: number
  /**
   * @format int32
   * @min 1
   * @max 10
   */
  EarlyStoppingPatience?: number
  /**
   *
   *
   * 0 = YoloXSmall
   *
   * 1 = YoloXMedium
   *
   * 2 = YoloXLarge
   *
   * 3 = FasterRCNN50
   *
   * 4 = FasterRCNN101
   *
   * 5 = FasterRCNN101X
   *
   * 6 = MaskRCNN50
   *
   * 7 = MaskRCNN101
   *
   * 8 = MaskRCNN101X
   *
   * 9 = CascadeMaskRCNN50
   */
  ObjectDetector?: ObjectDetectorType
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  TextEncoderLayersPercent?: number
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  ImageEncoderLayersPercent?: number
}

export interface ProjectTrainingAnnotationResponse {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  PageId?: number
  /** @format int32 */
  UserId?: number
  /** @format int32 */
  ParentAnnotationId?: number | null
  /** @format int32 */
  CopyOfId?: number | null
  /**
   *
   *
   * 0 = StrokeDraw
   *
   * 1 = StrokeErase
   *
   * 2 = Laser
   *
   * 3 = Text
   *
   * 4 = Audio
   *
   * 5 = Text_Callout
   *
   * 6 = HotSpot
   *
   * 7 = LinkedPage
   *
   * 8 = AI
   *
   * 9 = AIHotspot
   *
   * 10 = Box
   *
   * 11 = Distance
   *
   * 12 = Model
   *
   * 13 = Height
   *
   * 14 = Area
   *
   * 15 = Cylinder
   *
   * 16 = Polyline
   *
   * 100 = StrokeDraw_Shape_Line
   *
   * 101 = StrokeDraw_Shape_Rectangle
   *
   * 102 = StrokeDraw_Shape_Ellipse
   *
   * 103 = StrokeDraw_Shape_Rectangle_Filled
   *
   * 104 = StrokeDraw_Shape_Ellipse_Filled
   *
   * 105 = StrokeDraw_Shape_Line_ArrowEnd
   *
   * 106 = StrokeDraw_Shape_Line_ArrowBoth
   *
   * 107 = Polygon
   */
  Type?: AnnotationType
  IsDeleted?: boolean
  /** @format date-time */
  CreatedOn?: string | null
  IsProcessed?: boolean
  /** @format date-time */
  UpdatedOn?: string | null
  /** @format date-time */
  DeletedOn?: string | null
  UniqueAIObject?: UniqueAIObjectForContentResponse
  AIObject?: AIObjectForContentResponse
  Anchor?: Point
  Color?: string | null
  Coordinates?: Coordinates[] | null
  /** @format int32 */
  FontSize?: number
  Text?: string | null
  /** @format float */
  Timestamp?: number
  TopLeft?: Point
  /** @format float */
  Duration?: number
  /** @format int32 */
  FormAnswerId?: number | null
  /** @format int32 */
  FormAnswererId?: number | null
  /** @format int32 */
  FormControlId?: number | null
  FormTabIds?: number[] | null
  /** @format int32 */
  FormId?: number | null
  /** @format int32 */
  LinkedPageId?: number | null
  /** @format int64 */
  Size?: number
  /** @format float */
  TargetTimestamp?: number
  /** @format int32 */
  Width?: number
}

export interface ProjectTrainingAssetResponse {
  Name?: string | null
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Image
   *
   * 2 = Video
   *
   * 3 = Preview
   *
   * 4 = Audio
   *
   * 5 = CloudFile
   *
   * 6 = RecordedVideo
   *
   * 7 = Thumbnail
   *
   * 10 = Snapshot
   *
   * 11 = ConcatenatedVideo
   *
   * 12 = WebPage
   *
   * 13 = SpatialModelImage
   *
   * 14 = DocDocument
   *
   * 15 = PptDocument
   *
   * 16 = ExcelDocument
   *
   * 17 = File
   *
   * 18 = StructureModel
   *
   * 20 = TwoDLineDrawing
   *
   * 22 = AutodeskFile
   *
   * 23 = PointCloud
   *
   * 24 = PotreePointCloud
   *
   * 101 = UnencodedVideo
   *
   * 102 = UnencodedRecordedVideo
   *
   * 103 = UnencodedConcatenatedVideo
   *
   * 104 = RecordedCloudFileVideo
   *
   * 105 = StreamVideo
   */
  Type?: AssetType
  Url?: string | null
  Metadata?: MetadataResponse
  /** @format int32 */
  PageId?: number
  /** @format double */
  Latitude?: number | null
  /** @format double */
  Longitude?: number | null
  /** @format int64 */
  Size?: number | null
  /** @format int32 */
  Duration?: number | null
  /** @format date-time */
  OriginalCreatedOn?: string | null
  /** @format int32 */
  Order?: number
  /** @format int32 */
  LinkedPageId?: number | null
  /** @format int32 */
  CopyOfId?: number | null
  /**
   *
   *
   * 0 = NotCreated
   *
   * 1 = ModelCreating
   *
   * 2 = ModelCreated
   */
  MeasurementStatus?: PointCloudModelStatus
}

export interface ProjectTrainingFormAnswerResponse {
  /** @format int32 */
  FormId?: number
  TabsIds?: number[] | null
  TabNames?: string | null
  FormControlLabel?: string | null
  /** @format int32 */
  Status?: number
  StatusChanger?: string | null
  /** @format date-time */
  StatusChangeTime?: string | null
  Answer?: string | null
  Answerer?: string | null
  /** @format date-time */
  AnswerTime?: string | null
}

export interface ProjectTrainingPageAndFormAnswersResponse {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  UserId?: number
  FolderName?: string | null
  Name?: string | null
  IsDeleted?: boolean
  /** @format date-time */
  CreatedOn?: string | null
  /** @format int32 */
  Width?: number | null
  /** @format int32 */
  Height?: number | null
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Video
   *
   * 2 = Text
   *
   * 3 = Pdf
   *
   * 4 = VideoByUrl
   *
   * 5 = CloudFile
   *
   * 6 = RecordedVideo
   *
   * 7 = Audio
   *
   * 8 = DrawingBoard
   *
   * 9 = Snapshot
   *
   * 10 = Image
   *
   * 11 = ConcatenatedVideo
   *
   * 12 = WebPage
   *
   * 13 = SpatialModel
   *
   * 14 = DocDocumentPage
   *
   * 15 = PptDocumentPage
   *
   * 16 = ExcelDocumentPage
   *
   * 17 = File
   *
   * 18 = StructureModel
   *
   * 19 = StructureModelPdf
   *
   * 20 = TwoDLineDrawing
   *
   * 21 = TwoDLineDrawingPdf
   *
   * 22 = AutodeskFile
   *
   * 23 = PointCloud
   *
   * 24 = BentleyAnalyticsReportPdf
   *
   * 101 = UnencodedVideo
   *
   * 102 = UnencodedRecordedVideo
   *
   * 103 = UnencodedConcatenatedVideo
   *
   * 104 = RecordedCloudFileVideo
   *
   * 105 = StreamVideo
   */
  ContentType?: ContentType
  FormAnswers?: ProjectTrainingFormAnswerResponse[] | null
  AssetsModel?: ProjectTrainingAssetResponse[] | null
  AnnotationsModel?: ProjectTrainingAnnotationResponse[] | null
  NLPTags?: ProjectTrainingTagResponse[] | null
}

export interface ProjectTrainingTagResponse {
  Name?: string | null
}

/**
 *
 *
 * 0 = VQA
 *
 * 100 = ImageClassification
 *
 * 101 = ObjectDetection
 * @format int32
 */
export enum ProjectType {
  VQA = 0,
  ImageClassification = 100,
  ObjectDetection = 101,
}

export interface ProjectUpdateRequest {
  /**
   * Unique id of the project within the system.
   * @format int32
   */
  Id: number
  /**
   * A new name of the project. Optional
   * @minLength 0
   * @maxLength 500
   */
  Name?: string | null
  /**
   * A new description of the project. Optional
   * @minLength 0
   * @maxLength 1000
   */
  Description?: string | null
}

/**
 *
 *
 * 1 = Name
 *
 * 2 = Description
 *
 * 3 = CreatedOn
 *
 * 4 = Type
 * @format int32
 */
export enum ProjectsSortingColumnEnum {
  Name = 1,
  Description = 2,
  CreatedOn = 3,
  Type = 4,
}

export interface PublishAIReportRequest {
  /** @format int32 */
  TemplateId?: number | null
  Name?: string | null
  Rewrite?: boolean
}

export interface PublishTemplateColumnRequest {
  /** @format int32 */
  Id?: number | null
  Property?: string | null
  Sorting?: AIReportSorting
  Filter?: AIReportFilter
  /**
   *
   *
   * 0 = Unset
   *
   * 1 = Max
   *
   * 2 = Count
   */
  Grouping?: GroupingOperation
}

export interface RecipientRequest {
  /** @format int32 */
  Id?: number
}

export interface RecipientResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  UserName?: string | null
  Email?: string | null
  AvatarUrl?: string | null
  PhoneNumber?: string | null
  IsFindByPhone?: boolean
}

/**
 *
 *
 * 0 = Count
 *
 * 1 = Max
 *
 * 2 = Min
 *
 * 3 = Sum
 * @format int32
 */
export enum ReductionOperation {
  Count = 0,
  Max = 1,
  Min = 2,
  Sum = 3,
}

export interface RegenerateAnalyticsReportRequest {
  /**
   * Unique id of a page within the system.
   * @format int32
   */
  PageId?: number
}

/**
 *
 *
 * 0 = Never
 *
 * 1 = Date
 *
 * 2 = Occurrences
 * @format int32
 */
export enum ReminderCustomRepeatEndOnType {
  Never = 0,
  Date = 1,
  Occurrences = 2,
}

/**
 *
 *
 * 0 = Minute
 *
 * 1 = Hour
 *
 * 2 = Day
 *
 * 3 = Week
 *
 * 4 = Month
 *
 * 5 = Year
 * @format int32
 */
export enum ReminderCustomRepeatMode {
  Minute = 0,
  Hour = 1,
  Day = 2,
  Week = 3,
  Month = 4,
  Year = 5,
}

export interface ReminderCustomRepeatSettingsRequest {
  /**
   *
   *
   * 0 = Minute
   *
   * 1 = Hour
   *
   * 2 = Day
   *
   * 3 = Week
   *
   * 4 = Month
   *
   * 5 = Year
   */
  Mode: ReminderCustomRepeatMode
  /** @format int32 */
  Interval: number
  /** @format int32 */
  SelectedDaysOfWeek?: number
  /**
   *
   *
   * 0 = Never
   *
   * 1 = Date
   *
   * 2 = Occurrences
   */
  EndType: ReminderCustomRepeatEndOnType
  /** @format date-time */
  EndDate?: string
  /** @format int32 */
  EndOccurrences?: number
}

/**
 *
 *
 * 0 = NoRepeat
 *
 * 1 = Daily
 *
 * 2 = Weekly
 *
 * 3 = Monthly
 *
 * 4 = Annually
 *
 * 5 = EveryWeekday
 *
 * 6 = Custom
 * @format int32
 */
export enum ReminderRepeatMode {
  NoRepeat = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Annually = 4,
  EveryWeekday = 5,
  Custom = 6,
}

export interface ReminderRequest {
  Users: number[]
  /**
   *
   *
   * 0 = AnswerFormQuestion
   *
   * 2 = UnreadTrainingMaterial
   */
  Type: ReminderType
  /** @format date-time */
  StartDate: string
  /**
   *
   *
   * 0 = NoRepeat
   *
   * 1 = Daily
   *
   * 2 = Weekly
   *
   * 3 = Monthly
   *
   * 4 = Annually
   *
   * 5 = EveryWeekday
   *
   * 6 = Custom
   */
  RepeatMode: ReminderRepeatMode
  CustomRepeatSettings?: ReminderCustomRepeatSettingsRequest
  /** @format int32 */
  ContentId?: number | null
  /** @format int32 */
  ContentFormId?: number | null
  /** @format int32 */
  FormControlId?: number | null
  FormTabsIds?: number[] | null
  Payload?: string | null
}

export interface ReminderResponse {
  /** @format int32 */
  Id?: number
  /** @format date-time */
  CreatedOn?: string
  /** @format date-time */
  PreviousTriggerDate?: string | null
  /** @format date-time */
  TriggerDate?: string
  /** @format int32 */
  Occurrences?: number
  IsDeleted?: boolean
  Users: number[]
  /**
   *
   *
   * 0 = AnswerFormQuestion
   *
   * 2 = UnreadTrainingMaterial
   */
  Type: ReminderType
  /** @format date-time */
  StartDate: string
  /**
   *
   *
   * 0 = NoRepeat
   *
   * 1 = Daily
   *
   * 2 = Weekly
   *
   * 3 = Monthly
   *
   * 4 = Annually
   *
   * 5 = EveryWeekday
   *
   * 6 = Custom
   */
  RepeatMode: ReminderRepeatMode
  CustomRepeatSettings?: ReminderCustomRepeatSettingsRequest
  /** @format int32 */
  ContentId?: number | null
  /** @format int32 */
  ContentFormId?: number | null
  /** @format int32 */
  FormControlId?: number | null
  FormTabsIds?: number[] | null
  Payload?: string | null
}

/**
 *
 *
 * 0 = AnswerFormQuestion
 *
 * 2 = UnreadTrainingMaterial
 * @format int32
 */
export enum ReminderType {
  AnswerFormQuestion = 0,
  UnreadTrainingMaterial = 2,
}

export interface RemoveUserContentRequest {
  /**
   * The id of particular session.
   * @format int32
   */
  ContentId: number
  /**
   * The id of the user to be removed from the session.
   * @format int32
   */
  UserId: number
}

export interface RenameContentFolderRequest {
  /**
   * Unique id of the folder within the system
   * @format int32
   */
  Id: number
  /**
   * New folder name
   * @minLength 1
   * @maxLength 400
   */
  Name: string
}

export interface RenameFormFolderRequest {
  /**
   * Folder Id
   * @format int32
   */
  FolderId: number
  /**
   * Folder's new name
   * @minLength 1
   * @maxLength 400
   */
  Name: string
}

export interface RenamePageFolderResponse {
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  Id: number
  /**
   * New name of session folder
   * @minLength 1
   * @maxLength 400
   */
  Name: string
}

export interface RenamePageRequest {
  /**
   * Unique if of the page within the system
   * @format int32
   */
  Id: number
  /**
   * New name of the page
   * @minLength 1
   * @maxLength 256
   */
  Name: string
}

/**
 *
 *
 * 0 = Pdf
 *
 * 1 = Excel
 * @format int32
 */
export enum ReportFileType {
  Pdf = 0,
  Excel = 1,
}

/**
 *
 *
 * 0 = NotStarted
 *
 * 1 = ReadyForReview
 *
 * 2 = InReview
 *
 * 3 = SessionFeedbackSent
 *
 * 4 = Complete
 * @format int32
 */
export enum ReviewStatus {
  NotStarted = 0,
  ReadyForReview = 1,
  InReview = 2,
  SessionFeedbackSent = 3,
  Complete = 4,
}

export interface Scale {
  /** @format double */
  X?: number
  /** @format double */
  Y?: number
  /** @format double */
  Z?: number
}

export interface SearchTargetContentRequest {
  /**
   * The search query by which the content description is matched.
   * @minLength 1
   * @maxLength 4000
   */
  SearchQuery: string
  /**
   * The id of a content owner.
   * @format int32
   */
  ContentOwnerId?: number | null
  /**
   * The id of a tenant.
   * @format int32
   */
  TenantId: number
}

/**
 *
 *
 * 1 = AllParticipant
 *
 * 2 = SessionOwner
 *
 * 3 = SpecificUsers
 * @format int32
 */
export enum SendEmailConditionType {
  AllParticipant = 1,
  SessionOwner = 2,
  SpecificUsers = 3,
}

/**
 *
 *
 * 0 = AddGalleryContent
 *
 * 1 = ExportGalleryContent
 *
 * 2 = AnswerQuestions
 *
 * 3 = RejectApprove
 *
 * 4 = AddForms
 *
 * 5 = StartCalls
 *
 * 6 = InviteUsers
 *
 * 7 = CompleteReview
 *
 * 8 = SendReviewFeedback
 *
 * 9 = DeleteAllFormAnswers
 *
 * 10 = ExportSession
 *
 * 11 = ExportFormAsCsv
 *
 * 12 = ExportFormAsPdf
 *
 * 13 = DeleteRenameAllGalleryContent
 *
 * 14 = RemoveUsers
 *
 * 15 = ChangeUserRole
 *
 * 16 = SetVideobridge
 *
 * 17 = RenameSession
 *
 * 18 = SendChatMessages
 *
 * 19 = AddAiAnnotations
 *
 * 20 = AddAnnotations
 *
 * 21 = MainScreenToolbarTracking
 *
 * 22 = RotateImages
 *
 * 23 = AlertHost
 *
 * 24 = DeleteRenameOwnGalleryContent
 *
 * 25 = PublishSessions
 *
 * 26 = JoinCall
 *
 * 27 = AddDeleteReminder
 *
 * 28 = ReadSpreadsheets
 *
 * 29 = HealthDataView
 *
 * 30 = SendForInContentReview
 *
 * 31 = CompleteOrFeedbackInContentReview
 *
 * 32 = ManagePageFolderPermissions
 *
 * 33 = ManageTrainingMaterials
 * @format int32
 */
export enum SessionPermission {
  AddGalleryContent = 0,
  ExportGalleryContent = 1,
  AnswerQuestions = 2,
  RejectApprove = 3,
  AddForms = 4,
  StartCalls = 5,
  InviteUsers = 6,
  CompleteReview = 7,
  SendReviewFeedback = 8,
  DeleteAllFormAnswers = 9,
  ExportSession = 10,
  ExportFormAsCsv = 11,
  ExportFormAsPdf = 12,
  DeleteRenameAllGalleryContent = 13,
  RemoveUsers = 14,
  ChangeUserRole = 15,
  SetVideobridge = 16,
  RenameSession = 17,
  SendChatMessages = 18,
  AddAiAnnotations = 19,
  AddAnnotations = 20,
  MainScreenToolbarTracking = 21,
  RotateImages = 22,
  AlertHost = 23,
  DeleteRenameOwnGalleryContent = 24,
  PublishSessions = 25,
  JoinCall = 26,
  AddDeleteReminder = 27,
  ReadSpreadsheets = 28,
  HealthDataView = 29,
  SendForInContentReview = 30,
  CompleteOrFeedbackInContentReview = 31,
  ManagePageFolderPermissions = 32,
  ManageTrainingMaterials = 33,
  ManageContentReviews = 34,
}

export interface SessionPermissionIEnumerablePermissionsResponse {
  Permissions?: SessionPermission[] | null
}

export interface SessionStorageSwitchRequest {
  /** @format int32 */
  StorageId: number
}

export interface SetFormAnswerStatusRequest {
  /** @format int32 */
  FormAnswerId: number
  /**
   *
   *
   * 0 = Pending
   *
   * 1 = Pass
   *
   * 2 = Fail
   *
   * 3 = Empty
   *
   * 4 = ApprovedByAI
   *
   * 5 = RejectedByAI
   *
   * 6 = ApprovedByReviewer
   *
   * 7 = RejectedByReviewer
   */
  FormAnswerStatus: FormAnswerStatus
  /** @format int32 */
  ContentId: number
}

export interface SetFormAnswerStatusResponse {
  /** @format int32 */
  FormAnswerId?: number
  /**
   *
   *
   * 0 = Pending
   *
   * 1 = Pass
   *
   * 2 = Fail
   *
   * 3 = Empty
   *
   * 4 = ApprovedByAI
   *
   * 5 = RejectedByAI
   *
   * 6 = ApprovedByReviewer
   *
   * 7 = RejectedByReviewer
   */
  FormAnswerStatus?: FormAnswerStatus
  /** @format int32 */
  ContentId?: number
  /** @format int32 */
  ContentFormId?: number
  /** @format int32 */
  FormControlId?: number
  FormTabIds?: number[] | null
  /** @format int32 */
  ApproverId?: number | null
  ApproverName?: string | null
  /** @format date-time */
  FormAnswerStatusCreatedOn?: string
}

/**
 *
 *
 * 0 = Union
 *
 * 1 = Intersection
 * @format int32
 */
export enum SetOperation {
  Union = 0,
  Intersection = 1,
}

export interface SetPermissionsRequest {
  /** @format int32 */
  UserId?: number
  Permissions?: PageFolderPermission[] | null
}

export interface ShapeAnnotationExternalCreateRequest {
  /**
   *
   *
   * 0 = StrokeDraw
   *
   * 1 = StrokeErase
   *
   * 2 = Laser
   *
   * 3 = Text
   *
   * 4 = Audio
   *
   * 5 = Text_Callout
   *
   * 6 = HotSpot
   *
   * 7 = LinkedPage
   *
   * 8 = AI
   *
   * 9 = AIHotspot
   *
   * 10 = Box
   *
   * 11 = Distance
   *
   * 12 = Model
   *
   * 13 = Height
   *
   * 14 = Area
   *
   * 15 = Cylinder
   *
   * 16 = Polyline
   *
   * 100 = StrokeDraw_Shape_Line
   *
   * 101 = StrokeDraw_Shape_Rectangle
   *
   * 102 = StrokeDraw_Shape_Ellipse
   *
   * 103 = StrokeDraw_Shape_Rectangle_Filled
   *
   * 104 = StrokeDraw_Shape_Ellipse_Filled
   *
   * 105 = StrokeDraw_Shape_Line_ArrowEnd
   *
   * 106 = StrokeDraw_Shape_Line_ArrowBoth
   *
   * 107 = Polygon
   */
  Type?: AnnotationType
  /**
   * Coordinates of the annotation. Required for annotations with types:
   * <br>StrokeDraw_Shape_Line,
   * <br>StrokeDraw_Shape_Rectangle,
   * <br>StrokeDraw_Shape_Ellipse,
   * <br>StrokeDraw_Shape_Rectangle_Filled,
   * <br>StrokeDraw_Shape_Ellipse_Filled,
   * <br>StrokeDraw_Shape_Line_ArrowEnd,
   * <br>StrokeDraw_Shape_Line_ArrowBoth,
   * <br>Polygon
   */
  Coordinates: Coordinates[]
  /** Annotation name */
  Name?: string | null
  /**
   * Unique id of the page within the system
   * @format int32
   */
  PageId: number
  /**
   * The time on video/audio when an annotation has been created. Optional
   * @format float
   * @default -1
   */
  Timestamp?: number
  /**
   * The color of the annotation. Optional
   * @default "#FB000E"
   */
  Color?: string | null
  /**
   * The width of the annotation lines/font. Optional
   * @format int32
   * @default 3
   */
  Width?: number
  /**
   * Unique id of the parent annotation within the system. Optional
   * @format int32
   */
  ParentAnnotationId?: number | null
}

/**
 *
 *
 * 0 = AllData
 *
 * 1 = SelectedData
 * @format int32
 */
export enum SharedContentDataType {
  AllData = 0,
  SelectedData = 1,
}

export interface Sorting {
  /**
   *
   *
   * 1 = Name
   *
   * 2 = Email
   *
   * 3 = Role
   *
   * 4 = Tenant
   *
   * 5 = IsDisabled
   *
   * 6 = IsCreatedByAdmin
   */
  Column?: AdminUsersSortingColumnEnum
  /**
   *
   *
   * 1 = asc
   *
   * 2 = desc
   */
  Order?: SortingOrder
}

/**
 *
 *
 * 0 = Unset
 *
 * 1 = Ascending
 *
 * 2 = Descending
 * @format int32
 */
export enum SortingDirection {
  Unset = 0,
  Ascending = 1,
  Descending = 2,
}

/**
 *
 *
 * 1 = asc
 *
 * 2 = desc
 * @format int32
 */
export enum SortingOrder {
  Asc = 1,
  Desc = 2,
}

export interface SpatialViewPageCreateRequest {
  /**
   * List of pages ids, from which 360 view will be created
   * @minItems 1
   */
  PageIds: number[]
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Name of page
   * @maxLength 256
   */
  Name?: string | null
  /**
   * Unique id of the session folder within the system
   * @format int32
   */
  FolderId?: number | null
  /** @format int32 */
  TimeZoneOffset?: number
}

export interface SwitchOwnerRequest {
  /**
   * The id of particular session.
   * @format int32
   */
  ContentId: number
  /**
   * The id of user, for whom session is switched.
   * @format int32
   */
  NewOwnerId: number
  IgnoreSessionStatus?: boolean
}

export interface TargetContentForMergingResponse {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  OwnerId?: number
  OwnerName?: string | null
  /** @format date-time */
  CreatedOn?: string
  Description?: string | null
  ThumbnailUrl?: string | null
  IsActive?: boolean
}

/**
 *
 *
 * 1 = CreateSessions
 *
 * 2 = ShareContents
 *
 * 3 = CreateForms
 *
 * 4 = TenantSettings
 *
 * 5 = BillingSettings
 *
 * 6 = AISettings
 *
 * 7 = AllSessionsAccess
 *
 * 8 = StoragesAccess
 *
 * 9 = AllFormsView
 *
 * 10 = ReviewRejectInAnySession
 *
 * 11 = AllTenantsAccess
 *
 * 12 = AllUsersView
 *
 * 13 = Devices
 *
 * 14 = Analytics
 *
 * 15 = EditPolicies
 *
 * 16 = BulkCopy
 *
 * 17 = Reports
 *
 * 18 = SessionApprovalSettings
 *
 * 19 = CreateFolders
 *
 * 20 = PublishForms
 *
 * 21 = GeosearchSettings
 *
 * 22 = SessionNamingConventionSettings
 *
 * 23 = OverlaySettings
 *
 * 24 = PredictionSettings
 *
 * 25 = DetectionSettings
 *
 * 26 = SessionSharingSettings
 *
 * 27 = ReviewingSettings
 *
 * 28 = FtpSettings
 *
 * 29 = FtpExportSettings
 *
 * 30 = AppearanceSettings
 *
 * 31 = TrackingSettings
 *
 * 32 = VideoBridgeSettings
 *
 * 33 = PremiumFeaturesSettings
 *
 * 34 = FormBuilderSettings
 *
 * 35 = SsoSettings
 *
 * 36 = MobileAppSettings
 *
 * 37 = ExportReceiversSettings
 *
 * 38 = UploadUsers
 *
 * 39 = AIStateManagment
 *
 * 40 = SwitchContentOwner
 *
 * 41 = AddContentUsers
 *
 * 42 = SwitchContentReviewer
 *
 * 43 = UpdateSessionStorage
 *
 * 44 = JoinContentReview
 *
 * 45 = ReminderSettings
 *
 * 46 = ThreeDimensionalModelSettings
 *
 * 47 = PublishFormsSettings
 *
 * 48 = CreateReports
 *
 * 49 = CalendarSettings
 *
 * 50 = WriteSpreadsheets
 *
 * 51 = AllSpreadsheetsAccess
 *
 * 52 = CreatePublishFormWhenRestricted
 *
 * 53 = RolesSettings
 *
 * 54 = HealthKitSettings
 *
 * 55 = AccountManagementSettings
 *
 * 56 = PublishFormFolders
 *
 * 57 = AllCalendarEventsAccess
 *
 * 58 = ShowAddVQAButton
 *
 * 59 = ThreeDimensionalModelsManagement
 *
 * 60 = SendApproveOrRejectNotificationSettings
 *
 * 61 = AnalyticsReports
 *
 * 62 = CertificatesSettings
 *
 * 63 = ChangeExpirationCertificateReminder
 *
 * 64 = ModelTraining
 *
 * 65 = ChangeInContentReviewStatus
 *
 * 66 = CustomIframeSettings
 *
 * 67 = ManageSystemAnnouncements
 *
 * 68 = ManageTenantAnnouncements
 *
 * 69 = InventoryAnalysis
 *
 * 70 = PublishInventoryAnalysis
 *
 * 71 = PublishInventoryAnalysisSettings
 *
 * 72 = InventoryAnalysisSettings
 *
 * 73 = ManageTenantGeneratedReports
 *
 * 74 = ManageTenantScheduledGeneratedReports
 *
 * 75 = ManageTenantGeneratedReportsReceivers
 *
 * 76 = CertificatesTenantScope
 *
 * 77 = CertificatesGlobalScope
 *
 * 78 = ContractsManagement
 *
 * 79 = ManageQualifications
 *
 * 80 = ManageWorkflows
 *
 * 81 = ManageTrainingMaterials
 *
 * 82 = ContentApproval
 *
 * 83 = BulkImport
 * @format int32
 */
export enum TenantPermission {
  CreateSessions = 1,
  ShareContents = 2,
  CreateForms = 3,
  TenantSettings = 4,
  BillingSettings = 5,
  AISettings = 6,
  AllSessionsAccess = 7,
  StoragesAccess = 8,
  AllFormsView = 9,
  ReviewRejectInAnySession = 10,
  AllTenantsAccess = 11,
  AllUsersView = 12,
  Devices = 13,
  Analytics = 14,
  EditPolicies = 15,
  BulkCopy = 16,
  Reports = 17,
  SessionApprovalSettings = 18,
  CreateFolders = 19,
  PublishForms = 20,
  GeosearchSettings = 21,
  SessionNamingConventionSettings = 22,
  OverlaySettings = 23,
  PredictionSettings = 24,
  DetectionSettings = 25,
  SessionSharingSettings = 26,
  ReviewingSettings = 27,
  FtpSettings = 28,
  FtpExportSettings = 29,
  AppearanceSettings = 30,
  TrackingSettings = 31,
  VideoBridgeSettings = 32,
  PremiumFeaturesSettings = 33,
  FormBuilderSettings = 34,
  SsoSettings = 35,
  MobileAppSettings = 36,
  ExportReceiversSettings = 37,
  UploadUsers = 38,
  AIStateManagment = 39,
  SwitchContentOwner = 40,
  AddContentUsers = 41,
  SwitchContentReviewer = 42,
  UpdateSessionStorage = 43,
  JoinContentReview = 44,
  ReminderSettings = 45,
  ThreeDimensionalModelSettings = 46,
  PublishFormsSettings = 47,
  CreateReports = 48,
  CalendarSettings = 49,
  WriteSpreadsheets = 50,
  AllSpreadsheetsAccess = 51,
  CreatePublishFormWhenRestricted = 52,
  RolesSettings = 53,
  HealthKitSettings = 54,
  AccountManagementSettings = 55,
  PublishFormFolders = 56,
  AllCalendarEventsAccess = 57,
  ShowAddVQAButton = 58,
  ThreeDimensionalModelsManagement = 59,
  SendApproveOrRejectNotificationSettings = 60,
  AnalyticsReports = 61,
  CertificatesSettings = 62,
  ChangeExpirationCertificateReminder = 63,
  ModelTraining = 64,
  ChangeInContentReviewStatus = 65,
  CustomIframeSettings = 66,
  ManageSystemAnnouncements = 67,
  ManageTenantAnnouncements = 68,
  InventoryAnalysis = 69,
  PublishInventoryAnalysis = 70,
  PublishInventoryAnalysisSettings = 71,
  InventoryAnalysisSettings = 72,
  ManageTenantGeneratedReports = 73,
  ManageTenantScheduledGeneratedReports = 74,
  ManageTenantGeneratedReportsReceivers = 75,
  CertificatesTenantScope = 76,
  CertificatesGlobalScope = 77,
  ContractsManagement = 78,
  ManageQualifications = 79,
  ManageWorkflows = 80,
  ManageTrainingMaterials = 81,
  ContentApproval = 82,
  BulkImport = 83,
}

export interface TenantPermissionIEnumerablePermissionsResponse {
  Permissions?: TenantPermission[] | null
}

export interface TenantResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
}

export interface ThreeDimensionalModelCreateRequest {
  /** @minLength 1 */
  Url: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  Name: string
  Thumbnail?: string | null
  /** @format int32 */
  Id?: number
}

export interface ThreeDimensionalModelResponse {
  Url?: string | null
  Name?: string | null
  Thumbnail?: string | null
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 1 = Session
 *
 * 2 = Page
 *
 * 3 = FormAnswer
 * @format int32
 */
export enum TopicType {
  Session = 1,
  Page = 2,
  FormAnswer = 3,
}

export interface TowerSideProfilePrepareRequest {
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Unique id of the page within the system
   * @format int32
   */
  PointCloudModelPageId: number
  /**
   * Unique id of the page within the system
   * @format int32
   */
  StructureModelPageId?: number | null
}

export interface Trace {
  /** @format date-span */
  ExecutionTime?: string
  Result?: any
}

/**
 *
 *
 * 0 = Point
 *
 * 1 = Rectangle
 *
 * 2 = Circle
 * @format int32
 */
export enum TrackingAnnotationType {
  Point = 0,
  Rectangle = 1,
  Circle = 2,
}

/**
 *
 *
 * 0 = CheckIn
 *
 * 1 = CheckOut
 *
 * 2 = Manual
 * @format int32
 */
export enum TrackingEventType {
  CheckIn = 0,
  CheckOut = 1,
  Manual = 2,
}

export interface TrackingPoint {
  /** @format double */
  Latitude?: number
  /** @format double */
  Longitude?: number
}

export interface TrackingResponse {
  /**
   * Exact day and time (in UTC) when a tracking was created
   * @format date-time
   */
  CreatedOn?: string
  /**
   *
   *
   * 0 = CheckIn
   *
   * 1 = CheckOut
   *
   * 2 = Manual
   */
  EventType?: TrackingEventType
  /**
   *
   *
   * 0 = Point
   *
   * 1 = Rectangle
   *
   * 2 = Circle
   */
  AnnotationType?: TrackingAnnotationType
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId?: number
  /** Name of the session assigned by the system automatically or changed by the user manually */
  SessionName?: string | null
  /**
   * Unique id of the user within the system
   * @format int32
   */
  UserId?: number
  User?: BaseUserInfoWithoutAvatarResponse
  /**
   * List of coordinates related to tracking (depends on value of AnnotationType field):
   * - Point - contains 1 point: exact coordinates of point on the map;
   * - Rectangle - contains 2 points: coordinates of top left and bottom right corners of rectangle on the map;
   * - Cirle - contains 2 points: coordinates of top left and bottom right points of rectangle into which the circle should fit on the map.
   */
  Coordinates?: TrackingPoint[] | null
  /** @format int32 */
  Id?: number
}

export interface TwoDLineDrawingPrepareRequest {
  /**
   * Unique id of the session within the system
   * @format int32
   */
  ContentId: number
  /**
   * Unique id of the page within the system
   * @format int32
   */
  PointCloudModelPageId: number
}

export interface TwoDLineDrawingRegenerateRequest {
  /**
   * Unique id of the page within the system
   * @format int32
   */
  PageId: number
}

export interface UniqueAIObjectBaseResponse {
  /**
   * Unique id of the unique AI object within the system
   * @format int32
   */
  Id?: number
  /** The name of the unique AI object */
  Name?: string | null
  /**
   * Unique id of the AI object within the system
   * @format int32
   */
  AIObjectId?: number
  /**
   * Unique id of the user who created the unique AI object
   * @format int32
   */
  UserId?: number
  /**
   * A unique AI object creation date.
   * @format date-time
   */
  CreatedOn?: string | null
}

export interface UniqueAIObjectChildConnectionResponse {
  Child?: UniqueAIObjectBaseResponse
  /**
   *
   *
   * 0 = Link
   *
   * 1 = Projection
   *
   * 2 = Intersection
   */
  Type?: UniqueAIObjectConnectionType
  /** @format date-time */
  CreatedOn?: string
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 0 = Link
 *
 * 1 = Projection
 *
 * 2 = Intersection
 * @format int32
 */
export enum UniqueAIObjectConnectionType {
  Link = 0,
  Projection = 1,
  Intersection = 2,
}

export interface UniqueAIObjectExternalUpdateRequest {
  /**
   * Unique id of the unique AI object within the system
   * @format int32
   */
  Id: number
  /**
   * Properties of the unique AI object
   * @minLength 1
   */
  Properties: string
  /** The name of the unique AI object */
  Name?: string | null
}

export interface UniqueAIObjectForContentResponse {
  Name?: string | null
  IsDeleted?: boolean
  /** @format date-time */
  CreatedOn?: string
  /** @format int32 */
  Id?: number
}

export interface UniqueAIObjectParentConnectionResponse {
  Parent?: UniqueAIObjectBaseResponse
  /**
   *
   *
   * 0 = Link
   *
   * 1 = Projection
   *
   * 2 = Intersection
   */
  Type?: UniqueAIObjectConnectionType
  /** @format date-time */
  CreatedOn?: string
  /** @format int32 */
  Id?: number
}

export interface UniqueAIObjectPropertyResponse {
  /** @format int32 */
  Id?: number
  AIObjectPropertyName?: string | null
  /** @format int32 */
  AIObjectPropertyId?: number
  /** @format int32 */
  UniqueAIObjectId?: number
  /** @format int32 */
  Ordinal?: number
  Value?: any
  /** @format date-time */
  CreatedOn?: string
}

export interface UniqueAIObjectResponse {
  /** Properties of the uniqueAIObject */
  Properties?: UniqueAIObjectPropertyResponse[] | null
  /** Parent connections of the uniqueAIObject */
  ParentConnections?: UniqueAIObjectParentConnectionResponse[] | null
  /** Child connections of the uniqueAIObject */
  ChildConnections?: UniqueAIObjectChildConnectionResponse[] | null
  /**
   * Unique id of the unique AI object within the system
   * @format int32
   */
  Id?: number
  /** The name of the unique AI object */
  Name?: string | null
  /**
   * Unique id of the AI object within the system
   * @format int32
   */
  AIObjectId?: number
  /**
   * Unique id of the user who created the unique AI object
   * @format int32
   */
  UserId?: number
  /**
   * A unique AI object creation date.
   * @format date-time
   */
  CreatedOn?: string | null
}

export interface UniqueAIObjectWithAIObjectResponse {
  /** The name of the AI object */
  AIObjectName?: string | null
  /**
   * Unique id of the unique AI object within the system
   * @format int32
   */
  Id?: number
  /** The name of the unique AI object */
  Name?: string | null
  /**
   * Unique id of the AI object within the system
   * @format int32
   */
  AIObjectId?: number
  /**
   * Unique id of the user who created the unique AI object
   * @format int32
   */
  UserId?: number
  /**
   * A unique AI object creation date.
   * @format date-time
   */
  CreatedOn?: string | null
}

export interface UpdateCalendarSubscriptionColorRequest {
  /**
   * The main color of the calendar in the hexadecimal format.
   * @minLength 1
   */
  Color: string
}

export interface UpdateCalendarSubscriptionDisplayRequest {
  /**
   *
   *
   * 0 = Hide
   *
   * 1 = Display
   *
   * 2 = DisplayThisOnly
   */
  Mode: CalendarSubscriptionDisplayMode
}

export interface UpdateContentDescriptionForTenantRequest {
  /**
   * A reviewed content Id.
   * @format int32
   */
  ContentId: number
  /**
   * A reviewer tenant Id. Either a tenant Id or a subdomain must be presented.
   * @format int32
   */
  TenantId?: number | null
  /**
   * A reviewer tenant subdomain. Either a tenant Id or a subdomain must be presented.
   * @maxLength 256
   */
  Subdomain?: string | null
  /**
   * A new name of a content.
   * @minLength 1
   * @maxLength 4000
   */
  Description: string
  /** Array of objects, required for displaying session name in json format in reports (if session name created using naming convention). */
  ContentNameParts?: ContentNamePartRequest[] | null
}

export interface UpdateContentNameRequest {
  /**
   * The name of the session.
   * @minLength 1
   * @maxLength 4000
   */
  Description: string
  /**
   * Unique id of the session within the system.
   * @format int32
   */
  ContentId: number
  /** Array of objects, required for displaying session name in json format in reports (if session name created using naming convention). */
  ContentNameParts?: ContentNamePartRequest[] | null
}

export interface UpdateFormPermissionsByFormRequest {
  GrantedPermissions?: FormPermissionsByContentRoleDto[] | null
}

export interface UpdateFormPermissionsByTenantRequest {
  GrantedPermissions?: FormPermissionsByContentRoleDto[] | null
}

export interface UpdatePagesFolderRequest {
  /** List of unique pages ids */
  PageIds: number[]
  /**
   * Id of the folder to which the pages are moved
   * @format int32
   */
  FolderId?: number | null
}

export interface UpdatePrivacyRequest {
  /** @format int32 */
  FolderId?: number
  IsPrivate?: boolean
}

export interface UpdatePublishTemplateRequest {
  Columns?: PublishTemplateColumnRequest[] | null
  Name?: string | null
}

export interface UserAddressRequest {
  /** @maxLength 512 */
  Line1?: string | null
  /** @maxLength 512 */
  Line2?: string | null
  /** @maxLength 256 */
  City?: string | null
  /** @maxLength 256 */
  State?: string | null
  /** @maxLength 16 */
  PostalCode?: string | null
  /** @maxLength 256 */
  Country?: string | null
  /** @format int32 */
  Id?: number
}

export interface UserAdminInfoResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  UserName?: string | null
  AvatarUrl?: string | null
  Email?: string | null
  FirstName?: string | null
  LastName?: string | null
  EmployeeId?: string | null
  PhoneNumber?: string | null
  IsDisabled?: boolean
  Role?: string | null
  /** @format int32 */
  TenantId?: number
  IsCreatedByAdmin?: boolean
  Password?: string | null
  /** @format int32 */
  TotalCount?: number
  ShowHealthData?: boolean
  /** @format uuid */
  IdentityGuid?: string
}

export interface UserAdminResponse {
  /** @format int32 */
  Id?: number
  Name?: string | null
  UserName?: string | null
  AvatarUrl?: string | null
  Email?: string | null
  FirstName?: string | null
  LastName?: string | null
  EmployeeId?: string | null
  PhoneNumber?: string | null
  IsDisabled?: boolean
  Role?: string | null
  /** @format int32 */
  TenantId?: number
  TenantName?: string | null
  IsCreatedByAdmin?: boolean
  /** @format int32 */
  TotalCount?: number
  ShowHealthData?: boolean
  /** @format uuid */
  IdentityGuid?: string
}

export interface UserAdminUpdateInfoModel {
  /**
   * The username of the user (email, first name, last name, etc.)
   * @minLength 1
   * @maxLength 120
   */
  UserName: string
  /**
   * The email address associated with the account.
   * @format email
   * @minLength 1
   * @maxLength 120
   */
  Email: string
  /**
   * The first name of the user.
   * @maxLength 35
   */
  FirstName?: string | null
  /**
   * The last name of the user.
   * @maxLength 35
   */
  LastName?: string | null
  /**
   * The EmployeeId of the user.
   * @maxLength 128
   * @pattern ^[a-zA-Z0-9_-]+$
   */
  EmployeeId?: string | null
  /** The indicator of whether user was created by Admin or not. */
  IsCreatedByAdmin?: boolean
  Address?: UserAddressRequest
}

export interface UserChatsResponse {
  /** @format int32 */
  SessionId?: number
  /** @format int32 */
  AgentId?: number | null
  AgentName?: string | null
  AgentEmail?: string | null
  AgentAvatarUrl?: string | null
  SessionName?: string | null
  /** @format int32 */
  LastMessageSenderId?: number
  LastMessageSenderName?: string | null
  LastMessageSenderEmail?: string | null
  LastMessageSenderAvatarUrl?: string | null
  /** @format int32 */
  LastMessageId?: number
  /** @format date-time */
  LastMessageCreatedOn?: string | null
  LastMessageText?: string | null
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = Audio
   */
  LastMessageAssetType?: ChatMessageAssetType
  /** @format int32 */
  CountUnread?: number
}

export interface UserChatsSearchModelFiltering {
  SearchQuery?: string | null
}

export interface UserChatsSearchModelSorting {
  Column?: UserChatsSortingColumnEnum
  /**
   *
   *
   * 1 = asc
   *
   * 2 = desc
   */
  Order?: SortingOrder
}

export interface UserChatsSearchRequest {
  Sorting?: UserChatsSearchModelSorting
  Filtering?: UserChatsSearchModelFiltering
  /**
   * @format int32
   * @default 1
   */
  PageNumber?: number
  /**
   * @format int32
   * @default 25
   */
  PageSize?: number
}

/** @format int32 */
export type UserChatsSortingColumnEnum = number

export interface UserContentFormResponse {
  /** @format int32 */
  ContentFormId?: number
  /** @format int32 */
  ContentId?: number
  ContentDescription?: string | null
  /** @format int32 */
  FormId?: number
  FormName?: string | null
  /** @format date-time */
  LastReminderCreatedOn?: string | null
}

/** @format int32 */
export type UserContentFormSortingColumnEnum = number

export interface UserContentFormsSearchModelFiltering {
  SearchQuery?: string | null
}

export interface UserContentFormsSearchModelSorting {
  Column?: UserContentFormSortingColumnEnum
  /**
   *
   *
   * 1 = asc
   *
   * 2 = desc
   */
  Order?: SortingOrder
}

export interface UserContentFormsSearchRequest {
  Sorting?: UserContentFormsSearchModelSorting
  Filtering?: UserContentFormsSearchModelFiltering
  /**
   * @format int32
   * @default 1
   */
  PageNumber?: number
  /**
   * @format int32
   * @default 25
   */
  PageSize?: number
}

export interface UserIdResponse {
  /** @format int32 */
  UserId?: number
}

export interface UserInfoResponse {
  /** @format uuid */
  IdentityGuid?: string
  /**
   *
   *
   * 0 = Unknown
   *
   * 1 = WebBrowser
   *
   * 2 = AndroidDevice
   *
   * 3 = AppleDevice
   *
   * 4 = AndroidBrowser
   *
   * 5 = IosBrowser
   */
  DeviceType?: DeviceTypes
  IsGuest?: boolean
  IsOwner?: boolean
  IsDeleted?: boolean
  /**
   *
   *
   * 0 = User
   *
   * 1 = Owner
   *
   * 2 = AIUser
   *
   * 3 = Reviewer
   *
   * 4 = Viewer
   *
   * 5 = Labeler
   *
   * 6 = Approver
   *
   * 7 = Guest
   */
  Role?: ContentUserRole
  ChatNotificationsMuted?: boolean
  /** @format int32 */
  Id?: number
  Name?: string | null
  UserName?: string | null
  FirstName?: string | null
  LastName?: string | null
  Email?: string | null
  AvatarUrl?: string | null
  IsAccountDeleted?: boolean
}

export interface UserSearchInfoResponse {
  /** @format int32 */
  Id?: number
  /** @format uuid */
  IdentityGuid?: string
  Name?: string | null
  UserName?: string | null
  AvatarUrl?: string | null
  Email?: string | null
  PhoneNumber?: string | null
  IsFindByPhone?: boolean
}

export interface UtilityInfrastructureAnalysisRequest {
  /** @minItems 1 */
  UniqueAiObjectsIds?: number[] | null
  /** @format int32 */
  ContentId: number
}

export interface VQAModelCreateRequest {
  /**
   * The URL to the model.
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   * The name of the model.
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * The description of the model.
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
}

export interface VQAModelResponse {
  /**
   *
   *
   * 0 = External
   *
   * 1 = InternalTrained
   */
  Type?: VQAModelType
  /**
   * @format uri
   * @minLength 1
   */
  Url: string
  /**
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
  /** @format int32 */
  TenantId: number
  /** @format date-time */
  CreatedOn?: string | null
  /** @format int32 */
  Id?: number
}

/**
 *
 *
 * 0 = External
 *
 * 1 = InternalTrained
 * @format int32
 */
export enum VQAModelType {
  External = 0,
  InternalTrained = 1,
}

export interface VQAModelUpdateRequest {
  /**
   * The URL to the model.
   * @format uri
   */
  Url?: string | null
  /**
   * Unique id of the model within the system
   * @format int32
   */
  Id?: number
  /**
   * The name of the model.
   * @minLength 1
   * @maxLength 500
   */
  Name: string
  /**
   * The description of the model.
   * @minLength 1
   * @maxLength 1000
   */
  Description: string
}
