export default {
  root: {
    margin: 8,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    background: '#ECECEC',
    boxShadow: 'none',
    borderRadius: '10px',
    height: 42,
  },
  input: {
    flex: 1,
    padding: 8,
  },
  iconButton: {
    padding: 10,
    marginRight: -8,
  },
  removeFileButton: {
    padding: 0,
    color: '#FFFFFF',
  },
  stopIcon: {
    color: '#E67264',
  },
  audioFile: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '2px 10px',
    margin: '5px 10px 5px 0',
    color: '#FFFFFF',
    background: '#0089CF',
    borderRadius: '15px',
  },
}
