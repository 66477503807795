import { TenantConfig } from '@tabeeb/contracts/microservices/swagger-tenant-service'
import { OverlayPosition } from '@tabeeb/enums'
import { DEFAULT_SESSIONS_TITLE, DEFAULT_TRAINING_MATERIALS_TITLE } from '@tabeeb/modules/shared/constants'

export const defaultConfig: TenantConfig = {
  bannerUrl: '',
  logoUrl: '',
  introImageUrl: '',
  introImageTextColor: '',
  name: '',
  subdomain: 'www',
  isGalleryExpansionEnabled: false,
  showStructureModel: false,
  showTowerSideProfile: false,
  show2dLineDrawing: false,
  isDetectObjects: false,
  showBentleyAnalyticsReport: false,
  sessionsNavbarTitle: DEFAULT_SESSIONS_TITLE,
  customIframeNomenclature: '',
  customIframeUrl: '',
  mobilePrimaryColor: '',
  mobileBackgroundUrl: '',
  isBillingEnabled: false,
  isCustomIframeEnabled: false,
  IsDetectLicensePlate: false,
  isUploadCustomVideo: false,
  homeBanner: '',
  mission: '',
  loginColor: '',
  homeTextColor: '',
  loginBackgroundColor: '',
  bullets: [],
  bannerLink: '',
  isSessionApprovalEnabled: '',
  isUploadCsvReport: false,
  IsUploadZipImages: false,
  showMenuGeosearch: false,
  showGoogleButton: false,
  showAppleButton: false,
  showNokiaButton: false,
  showEricssonButton: false,
  showTmobileButton: false,
  showImmucorButton: false,
  isOfflineAvailable: false,
  videoBridgeRegions: [],
  showSwitchToAttachedFormDialog: false,
  isSessionSharingEnabled: false,
  isSessionPublishingEnabled: false,
  isChatTranslationEnabled: false,
  isFormsAndUsersTabCollapsed: false,
  isProModeAvailable: false,
  isSessionReviewingEnabled: false,
  isBulkCopyEnabled: false,
  showMenuAnalytics: false,
  createFolderButtonColor: '',
  exportButtonColor: '',
  addButtionColor: '',
  mainSelectedTextColor: '',
  mainTextColor: '',
  mainBanner: '',
  notificationColor: '',
  mainBGColor: '',
  isInSessionReviewEnabled: false,
  showTabletsAssignation: false,
  isLocalRecordingEnabled: false,
  isCloudRecordingEnabled: false,
  isPowerBiEnabled: false,
  showBentleyModel: false,
  userTitle: '',
  showPageInfoOverlay: false,
  showSessionNameInOverlay: false,
  pageInfoOverlayPosition: OverlayPosition.TopLeft,
  showExportFormToPdfButton: false,
  showPdfRecieversPopup: false,
  showImageRecieversPopup: false,
  showVideobridgeRegionDropdown: false,
  isConditionsEnabled: false,
  isActivityDataReportsEnabled: false,
  isLastActivityDataReportsEnabled: false,
  isActionsOverUsersReportsEnabled: false,
  isFormAnswersReportsEnabled: false,
  isAllSessionsDataReportsEnabled: false,
  isCheckInCheckOutReportsEnabled: false,
  showMenuAI: false,
  showMenuAnalyticsReports: false,
  showFormUsers: false,
  isControlInfoEnabled: false,
  show360View: false,
  show3dModel: false,
  showPredictionService: false,
  isDetectText: false,
  isChatSpeechToTextEnabled: false,
  isRecordingEnabled: false,
  isAlertOwnerAvailable: false,
  isAnswersAiApproveEnabled: false,
  isAnswersAiApproveByVQAPolicyEnabled: false,
  isAiAutoAnswerEnabled: false,
  is3DModelCreationLimitEnabled: false,
  isPageTagsDetectionEnabled: false,
  isMobileTrackingEnabled: false,
  isTrackingMapEnabled: false,
  isMobileFormViewSwitchEnabled: false,
  isFormAnswerConfirmationEnabled: false,
  isFormActionEnabled: false,
  isFormRecordEnabled: false,
  sasExpirationInMinutesForContent: 0,
  sasExpirationInMinutesForReport: 0,
  loginBackgroundImage: '',
  showReminders: false,
  openNamingFormForCopiedSessions: false,
  showAllSessionsOriginalTenantCol: false,
  isFormPublishingEnabled: false,
  showHealthData: false,
  selectedTenantsForFormPublishing: [],
  selectedTenantsForSharing: [],
  selectedTenantsForReviewing: [],
  isContentMetadataEnabled: false,
  isSpreadsheetsImportEnabled: false,
  showAdvancedFilterButton: false,
  isAIExpressionsEnabled: false,
  isModelTrainingEnabled: false,
  isLidarScanningEnabled: false,
  isContentReviewAutoAcceptEnabled: false,
  isSearchTargetContentByTenantEnabled: false,
  mobileLogoUrl: '',
  isNewMainPageViewEnabled: false,
  isBentleyBoundingBoxManualInputEnabled: false,
  isMergeForReviewEnabled: false,
  contentMetadataButtonLabel: '',
  contentMetadataPosition: '',
  isDeviceSwitchingEnabled: false,
  soundNotificationOnCallJoin: false,
  showGridViewButton: false,
  useSessionNameConvention: false,
  isNamingConventionForReviewEnabled: false,
  isCalendarEnabled: false,
  isInContentReviewEnabled: false,
  isCreatePublishFormRestrictionEnabled: false,
  guestRoleDisplayName: '',
  isSessionGuestRoleEnabled: false,
  isViewingAdminTenantRoleEnabled: false,
  isBillingSettingsRoleEnabled: false,
  isSendApproveOrRejectNotificationEnabled: false,
  sessionGuestRoleDisplayName: '',
  isAccountDeletionEnabled: false,
  isChatEnabled: false,
  isVideoCallsEnabled: false,
  isTenantContentsCustomDashboardsEnabled: false,
  isAutodeskViewerEnabled: false,
  isPointCloudModelsEnabled: false,
  isAdvancedInviteSettingsEnabled: false,
  isMfaEnabled: false,
  isPageFolderAuthorizationEnabled: false,
  formsTitle: '',
  showCertificates: false,
  isAdvancedFormSettingsEnabled: false,
  certificateExpirationReminderInDays: 0,
  showProcessingByVQAModelButton: false,
  isContentLinkingEnabled: false,
  isHospitalsLocationsEnabled: false,
  isSwitchTenantFeatureEnabled: false,
  isTableRecognitionEnabled: false,
  isContentSharingAutoAcceptEnabled: false,
  isFormSignatureEnabled: false,
  showSelectedOnlyOptionsExportFormToPdf: false,
  isExportCustomFormCsvEnabled: false,
  iterationDeploymentLifetimeInHours: 0,
  isTenantAnnouncementsEnabled: false,
  isCallRingNotificationsEnabled: false,
  isClassificationAnnotationsCreationEnabled: false,
  isAIReportEnabled: false,
  aiReportLabel: '',
  isChatBotsEnabled: false,
  useInternalFTP: false,
  internalFTPUsername: '',
  useCustomerFTP: false,
  isIncomingContractsEnabled: false,
  isOutgoingContractsEnabled: false,
  selectedTenantsForContracts: [],
  showQualifications: false,
  isQualificationsForInvitesEnabled: false,
  showTimeZoneOnUploadAnswers: false,
  isTrainingMaterialsEnabled: false,
  trainingMaterialsTitle: DEFAULT_TRAINING_MATERIALS_TITLE,
  isUtilityInfrastructureAnalysisEnabled: false,
  utilityInfrastructureAnalysisWorkflowId: '',
  showSplatModel: false,
  isBulkImportEnabled: false,
  contentCreationRestricted: false,
  isUnreadSessionChatsIndicatorEnabled: false,
}
