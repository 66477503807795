import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@tabeeb/uikit'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { getFormsTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import {
  getDataForSharingByForms,
  setIsShareContentByFormDialogOpen,
} from '@tabeeb/modules/shareSessionPagesList/actions'
import ShareByFormSelector from '../ShareByFormSelector'

import './styles.less'

const ShareSessionByFormDialog = () => {
  const dispatch = useDispatch()
  const { isOpen, selectedForms, isLoading } = useSelector(
    (state) => state.shareSessionPagesList.shareByFormDialogState
  )
  const formsTitle = useSelector(getFormsTitleLowerCase)

  return (
    <Dialog
      open={isOpen}
      onClose={() => dispatch(setIsShareContentByFormDialogOpen({ isOpen: false }))}
      disableEscapeKeyDown
      className='share-by-form-dialog'
    >
      <DialogTitle className='share-by-form-dialog-title'>Which {formsTitle} would you like to include?</DialogTitle>
      <DialogContent className='share-by-form-dialog-content'>
        {isLoading ? <CircularProgressBar /> : <ShareByFormSelector />}
      </DialogContent>
      <DialogActions className='share-by-form-dialog-actions'>
        <Button
          onClick={() => dispatch(getDataForSharingByForms())}
          color='primary'
          disabled={selectedForms?.length === 0 || isLoading}
          className='share-by-form-dialog-btn'
        >
          Continue
        </Button>
        <Button
          onClick={() => dispatch(setIsShareContentByFormDialogOpen({ isOpen: false }))}
          color='default'
          disabled={isLoading}
          className='share-by-form-dialog-btn'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(ShareSessionByFormDialog)
