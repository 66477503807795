import { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import { notificationActions } from '@tabeeb/modules/notification'
import { AssetType } from '@tabeeb/enums'
import { hostNameMatterPort, modelIdParamName } from '../../constants'
import * as contentViewerActions from '../../actions'
import { getIsWebPageIframeHidden } from '../../selectors'

const WebPageViewer = ({ contentViewerActions, pageId, url, iframeKey, notificationsActions }) => {
  const isIframeHidden = useSelector(getIsWebPageIframeHidden)
  useEffect(() => {
    try {
      const uri = new URL(url)
      const modelId = uri.searchParams.get(modelIdParamName)

      if (uri?.hostname.includes(hostNameMatterPort) && modelId != null) {
        const data = {
          pageId,
        }
        contentViewerActions.sendUnarchiveModelRequest(data)
      } else {
        contentViewerActions.showWebPageIframe()
      }
    } catch (e) {
      const { onAddErrorNotification } = notificationsActions
      onAddErrorNotification({ message: `Cannot open provided URL: ${url}` })
    }
    return () => {
      contentViewerActions.resetWebPageState()
    }
  }, [contentViewerActions, pageId, url])

  const handleLoad = useCallback(() => {
    contentViewerActions.resetLoadingInProgress()
  }, [contentViewerActions])

  return (
    !isIframeHidden && (
      <iframe
        src={url}
        title='Web page'
        width='100%'
        height='100%'
        frameBorder='0'
        key={iframeKey}
        onLoad={handleLoad}
      />
    )
  )
}

WebPageViewer.propTypes = {
  pageId: PropTypes.number,
  url: PropTypes.string,
  contentViewerActions: PropTypes.shape({
    resetLoadingInProgress: PropTypes.func.isRequired,
    sendUnarchiveModelRequest: PropTypes.func.isRequired,
    showWebPageIframe: PropTypes.func.isRequired,
    resetWebPageState: PropTypes.func.isRequired,
  }).isRequired,
  notificationsActions: PropTypes.shape({
    onAddErrorNotification: PropTypes.func.isRequired,
  }),
  iframeKey: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
  const selectedGalleryItem = gallerySelectors.getSelectedGalleryItem(state)
  const webPageAsset =
    selectedGalleryItem && selectedGalleryItem.assets
      ? selectedGalleryItem.assets.find((asset) => asset.Type === AssetType.WebPage && asset.Url)
      : null
  return {
    pageId: selectedGalleryItem.id,
    url: webPageAsset ? webPageAsset.Url : null,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contentViewerActions: bindActionCreators(contentViewerActions, dispatch),
    notificationsActions: bindActionCreators(notificationActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebPageViewer)
