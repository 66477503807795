import { put, takeLatest, select, all } from 'redux-saga/effects'
import { getCurrentUserEmail } from '@tabeeb/modules/account/selectors'
import { getIsPdfExportEmailRecipientsEnabled } from '@tabeeb/modules/appConfigState/selectors'

import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'

function* selectPdfRecipients() {
  const isPdfExportEmailRecipientsEnabled = yield select((state) => getIsPdfExportEmailRecipientsEnabled(state))

  if (isPdfExportEmailRecipientsEnabled) {
    yield put(rawActions.setEmailRecipientsDialogState('pdf'))
  } else {
    const currentUserEmail = yield select((state) => getCurrentUserEmail(state))
    yield put(rawActions.exportAsPdf({ recipients: [currentUserEmail] }))
  }
}

function* showExportPdfNotification(action) {
  const message = 'An email will be sent to you shortly with the PDF download link.'
  yield put(notificationActions.onAddInfoNotification({ message }))
}

function* onExportAsPdf(action) {
  const { recipients } = action.payload
  const contentId = yield select((state) => state.contentState.contentId)
  const exportOption = yield select((state) => state.gallery.pdfOptionsDialogState)
  const exportGalleryOption = yield select((state) => state.gallery.exportGalleryOption)
  const selectedFolderId = yield select((state) => state.gallery.galleryState.selectedFolderId)

  yield put(
    rawActions.exportAsPdfRequest({
      contentId,
      emails: recipients,
      folderId: selectedFolderId,
      exportOption,
      galleryOption: exportGalleryOption,
    })
  )
}

function* exportAsPdfSaga() {
  yield all([
    takeLatest(rawActions.selectPdfRecipients, selectPdfRecipients),
    takeLatest(rawActions.exportAsPdf, onExportAsPdf),
    takeLatest(rawActions.exportAsPdfRequest, showExportPdfNotification),
  ])
}

export default exportAsPdfSaga
