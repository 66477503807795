import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import MarkdownEditor from '../../components/MarkdownEditor'

const FormikMarkdownEditor = ({ field, form, ...props }) => {
  const { name } = field
  const { errors, setFieldValue } = form

  const error = get(errors, name)

  const onChange = useCallback((newValue) => setFieldValue(name, newValue), [name, setFieldValue])

  return <MarkdownEditor {...field} {...props} error={error} onChange={onChange} />
}

FormikMarkdownEditor.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.instanceOf(Object).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
}

export default FormikMarkdownEditor
