import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Help } from '@mui/icons-material'
import { FormHelperText, Slider, Tooltip } from '@mui/material'

import { OutlinedSliderFormControl, OutlinedSliderFormControlLabel } from './styles'

const FormikSlider = ({ disabled, field, form, min, max, step, label, help }) => {
  const { value, name } = field
  const { errors, touched, setFieldValue } = form

  const error = touched[name] && errors[name]

  const onChangeCommited = useCallback(
    (e, newValue) => {
      setFieldValue(name, newValue)
    },
    [name, setFieldValue]
  )

  return (
    <OutlinedSliderFormControl disabled={disabled} fullWidth component='fieldset' variant='outlined'>
      <OutlinedSliderFormControlLabel disabled={disabled}>
        {label}
        {help && (
          <Tooltip title={help}>
            <Help color='info' fontSize='inherit' sx={{ cursor: 'help', ml: 0.5 }} />
          </Tooltip>
        )}
      </OutlinedSliderFormControlLabel>
      <Slider
        disabled={disabled}
        name={name}
        min={min}
        max={max}
        step={step}
        size='small'
        defaultValue={value}
        onChangeCommitted={onChangeCommited}
        valueLabelDisplay='auto'
        marks={[
          {
            value: min,
            label: min,
          },
          {
            value: max,
            label: max,
          },
        ]}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </OutlinedSliderFormControl>
  )
}

FormikSlider.defaultProps = {
  disabled: false,
}

FormikSlider.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.instanceOf(Object).isRequired,
    touched: PropTypes.instanceOf(Object).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  label: PropTypes.node,
  help: PropTypes.string,
}

export default memo(FormikSlider)
