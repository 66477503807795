import { combineReducers } from 'redux'

import aiAssistantChatBot from './aiAssistantChatBot'
import chats from './chats'
import languages from './languages'

export default combineReducers({
  aiAssistantChatBot,
  chats,
  languages,
})
