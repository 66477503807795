export const attachDialogStyles = {
  width: '310px',
  maxWidth: 'none',
}

export const closeButtonStyles = {
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 0,
  width: '26px',
  height: '26px',
}
