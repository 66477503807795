import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import * as rawActions from '../actions/index'
import ExportGalleryOptionsDialog from '../components/ExportGalleryOptionsDialog'
import { ExportGalleryOptions } from '../../../Enums'

const ExportGalleryOptionsDialogContainer = ({
  isExportGalleryOptionsDialogOpen,
  exportGalleryOptionsDialogState,
  exportGalleryOption,
  actions: { exportImagesIfTheyExist, selectPdfRecipients, closeExportGalleryOptionsDialog, setExportGalleryOption },
}) => {
  const _setExportGalleryOption = (event, value) => {
    setExportGalleryOption(parseInt(value, 10))
  }

  const _next = () => {
    closeExportGalleryOptionsDialog()

    if (exportGalleryOptionsDialogState === 'images') {
      exportImagesIfTheyExist()
    }

    if (exportGalleryOptionsDialogState === 'pdf') {
      selectPdfRecipients()
    }
  }

  const exportOptions = Object.entries(ExportGalleryOptions).map(([key, value]) => ({
    Id: value,
    Value: key.replace(/([A-Z])/g, ' $1'),
  }))

  const props = {
    isExportGalleryOptionsDialogOpen,
    closeDialog: closeExportGalleryOptionsDialog,
    next: _next,
    setExportGalleryOption: _setExportGalleryOption,
    selectedOption: exportGalleryOption,
    exportOptions,
  }

  return <ExportGalleryOptionsDialog {...props} />
}

ExportGalleryOptionsDialogContainer.propTypes = {
  isExportGalleryOptionsDialogOpen: PropTypes.bool.isRequired,
  exportGalleryOptionsDialogState: PropTypes.string,
  exportGalleryOption: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    exportImagesIfTheyExist: PropTypes.func.isRequired,
    setExportGalleryOption: PropTypes.func.isRequired,
    selectPdfRecipients: PropTypes.func.isRequired,
    closeExportGalleryOptionsDialog: PropTypes.func.isRequired,
  }),
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

const mapStateToProps = (state) => {
  return {
    isExportGalleryOptionsDialogOpen: Boolean(state.gallery.exportGalleryOptionsDialogState),
    exportGalleryOptionsDialogState: state.gallery.exportGalleryOptionsDialogState,
    exportGalleryOption: state.gallery.exportGalleryOption,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportGalleryOptionsDialogContainer)
