import { createSelector } from 'reselect'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { accountSelectors } from '../../account'
import { TenantPermission } from '../../../Enums'
import { dialogTypes, itemTypes } from '../services/enums'

export const getHasCopyFormPermission = (state) =>
  permissionsSelectors.hasTenantPermission(state, TenantPermission.CreateForms)
export const getHasCreateFormPermission = (state) =>
  permissionsSelectors.hasTenantPermission(state, TenantPermission.CreateForms)
export const getHasCreatePublishFormWhenRestrictedPermission = (state) =>
  permissionsSelectors.hasTenantPermission(state, TenantPermission.CreatePublishFormWhenRestricted)
export const getHasPublishFormPermission = (state) =>
  permissionsSelectors.hasTenantPermission(state, TenantPermission.PublishForms)
export const getHasPublishFormFolderPermission = (state) =>
  permissionsSelectors.hasTenantPermission(state, TenantPermission.PublishFormFolders)
export const getFormsList = (state) => state.formsPage.formsList
export const getFormFoldersList = (state) => state.formsPage.foldersList
export const getFormsPageSearchValue = (state) => state.formsPage.searchText
export const getIsListLoading = (state) => state.formsPage.isListLoading
export const getItemForPublishing = (state) => state.formsPage.publishDialogState.item
export const getItemPublishingAction = (state) => state.formsPage.publishDialogState.action
export const getItemPublishingType = (state) => state.formsPage.publishDialogState.type
export const getCurrentFolderId = (state) => state.formsPage.currentFolder?.FolderId
export const getCurrentFolderOwnerId = (state) => state.formsPage.currentFolder?.UserId
export const getCachedFolder = (state) => state.formsPage.cachedFolder
export const getCachedFolderId = (state) => state.formsPage.cachedFolder?.FolderId

export const getIsOutsideOtherUserFolder = createSelector(
  [getCurrentFolderOwnerId, accountSelectors.getCurrentUserId],
  (currentFolderOwnerId, currentUserId) => {
    const isInsideFolder = !!currentFolderOwnerId
    return isInsideFolder ? currentUserId === currentFolderOwnerId : true
  }
)

export const getIsCopyButtonVisible = createSelector(
  [
    getHasCopyFormPermission,
    appConfigStateSelectors.getIsCreatePublishFormRestrictionEnabled,
    getHasCreatePublishFormWhenRestrictedPermission,
  ],
  (hasPermission, isCreatePublishFormRestrictionEnabled, hasCreatePublishFormWhenRestrictedPermission) => {
    return isCreatePublishFormRestrictionEnabled ? hasCreatePublishFormWhenRestrictedPermission : hasPermission
  }
)

export const getIsMoveButtonVisible = createSelector(
  [accountSelectors.getCurrentUserId, getFormFoldersList, getIsOutsideOtherUserFolder],
  (currentUserId, foldersList, isOutsideOtherUserFolder) => {
    const isAnyOwnFolderExists = !!foldersList.find((folder) => folder.UserId === currentUserId)
    return isAnyOwnFolderExists && isOutsideOtherUserFolder
  }
)

export const getIsEditButtonVisible = createSelector(
  [accountSelectors.getCurrentUserId, (_, { form }) => form],
  (currentUserId, form) => {
    const isFormOwner = form.UserId === currentUserId
    return isFormOwner
  }
)

export const getIsDeleteButtonVisible = createSelector(
  [accountSelectors.getCurrentUserId, (_, { form }) => form],
  (currentUserId, form) => {
    const isFormOwner = form.UserId === currentUserId
    const formHasAnswers = form.AnswersNumber && form.AnswersNumber > 0

    return isFormOwner && !formHasAnswers
  }
)

export const getIsPublishButtonVisible = createSelector(
  [
    accountSelectors.getCurrentUserId,
    appConfigStateSelectors.getIsFormPublishingEnabled,
    appConfigStateSelectors.getSelectedTenantsForFormPublishing,
    appConfigStateSelectors.getIsCreatePublishFormRestrictionEnabled,
    getHasCreatePublishFormWhenRestrictedPermission,
    (_, { item }) => item,
    (_, { type }) => type,
    (_, { permission }) => permission,
    (state) => state,
  ],
  (
    currentUserId,
    isPublishToOtherTenantsEnabled,
    tenantsForPublishing,
    isCreatePublishFormRestrictionEnabled,
    hasCreatePublishFormWhenRestrictedPermission,
    item,
    type,
    permission,
    state
  ) => {
    const hasPermission = permissionsSelectors.hasTenantPermission(state, permission)
    const isRestrictedAndNoPermission =
      isCreatePublishFormRestrictionEnabled && !hasCreatePublishFormWhenRestrictedPermission
    const isItemOwner = item.UserId === currentUserId
    if (!isItemOwner || !hasPermission || isRestrictedAndNoPermission) {
      return false
    }

    const publishedOnTenants = type === itemTypes.Form ? item.PublishedFormTenants : item.PublishedFolderTenants

    if (isPublishToOtherTenantsEnabled) {
      const availableTenantsToPublish = tenantsForPublishing.filter(
        (t) => !publishedOnTenants.some((pf) => pf.Id === t.Id)
      )
      return availableTenantsToPublish.length > 0
    }
    const isItemPublishedToCurrentTenant = publishedOnTenants.some((t) => t.Id === item.TenantId)
    return !isItemPublishedToCurrentTenant
  }
)

export const getIsUnpublishButtonVisible = createSelector(
  [getHasPublishFormPermission, accountSelectors.getCurrentUserId, (_, { item }) => item, (_, { type }) => type],
  (hasPermission, currentUserId, item, type) => {
    const isItemOwner = item.UserId === currentUserId
    if (!isItemOwner || !hasPermission) return false

    const publishedOnTenants = type === itemTypes.Form ? item.PublishedFormTenants : item.PublishedFolderTenants
    return publishedOnTenants.length > 0
  }
)

export const getFormsListBySearch = createSelector([getFormsList, getFormsPageSearchValue], (forms, searchText) => {
  if (!forms) return []

  if (searchText) forms = forms.filter((form) => form.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)

  return forms
})

export const getPublishDialogTenantsList = createSelector(
  [
    getItemForPublishing,
    getItemPublishingAction,
    getItemPublishingType,
    appConfigStateSelectors.getIsFormPublishingEnabled,
    appConfigStateSelectors.getSelectedTenantsForFormPublishing,
  ],
  (item, action, type, isPublishToOtherTenantsEnabled, tenantsForPublishing) => {
    if (!item || !action) return []

    switch (action) {
      case dialogTypes.UnpublishForm:
        return item.PublishedFormTenants
      case dialogTypes.UnpublishFolder:
        return item.PublishedFolderTenants
    }

    if (isPublishToOtherTenantsEnabled) {
      const publishedOnTenants = type === itemTypes.Form ? item.PublishedFormTenants : item.PublishedFolderTenants
      return tenantsForPublishing.filter((t) => !publishedOnTenants.some((pt) => pt.Id === t.Id))
    }
    return [{ Id: item.TenantId, Name: item.TenantName }]
  }
)

export const getIsDeleteFolderButtonVisible = createSelector(
  [accountSelectors.getCurrentUserId, (_, { folder }) => folder],
  (currentUserId, folder) => {
    return folder.CanBeDeleted
  }
)

export const getCanCreateForms = createSelector(
  [
    getHasCreateFormPermission,
    getIsOutsideOtherUserFolder,
    appConfigStateSelectors.getIsCreatePublishFormRestrictionEnabled,
    getHasCreatePublishFormWhenRestrictedPermission,
  ],
  (
    hasCreateFormPermission,
    isOutsideOtherUserFolder,
    isCreatePublishFormRestrictionEnabled,
    hasCreatePublishFormWhenRestrictedPermission
  ) => {
    const hasTenantPermission = isCreatePublishFormRestrictionEnabled
      ? hasCreatePublishFormWhenRestrictedPermission
      : hasCreateFormPermission

    return hasTenantPermission && isOutsideOtherUserFolder
  }
)
