import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Badge, IconButton, Popover, useTheme } from '@mui/material'
import { Notifications as NotificationsIcon } from '@mui/icons-material'
import { badgeClasses } from '@mui/material/Badge'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import NotificationsTab from '@tabeeb/modules/notificationsPage/components/NotificationsPage'
import {
  closeNotificationsTab,
  getNotificationsCountRequest,
  openNotificationsTab,
} from '@tabeeb/modules/notificationsPage/actions'
import { getNotificationsCount, getNotificationsTabOpen } from '@tabeeb/modules/notificationsPage/selectors'

import useStyles from './styles'

const Notifications = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const buttonRef = useRef()
  const isOpen = useSelector(getNotificationsTabOpen)

  const textColor = useSelector(appConfigStateSelectors.getMainTextColor)
  const notificationColor = useSelector(appConfigStateSelectors.getNotificationColor)
  const notificationsCount = useSelector(getNotificationsCount)

  const handleOpen = useCallback(() => {
    dispatch(openNotificationsTab())
  }, [dispatch])

  const handleClose = useCallback(() => {
    dispatch(closeNotificationsTab())
  }, [dispatch])

  useEffect(() => {
    dispatch(getNotificationsCountRequest())

    return () => {
      dispatch(closeNotificationsTab())
    }
  }, [dispatch])

  const badgeSx = useMemo(
    () => ({
      [`& .${badgeClasses.badge}`]: {
        color: theme.palette.getContrastText(notificationColor),
        backgroundColor: notificationColor,
      },
    }),
    [notificationColor, theme]
  )

  return (
    <>
      <IconButton ref={buttonRef} sx={{ color: textColor }} onClick={handleOpen}>
        <Badge
          overlap='rectangular'
          sx={badgeSx}
          badgeContent={notificationsCount > 0 ? notificationsCount : null}
          max={100}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        PaperProps={{ className: classes.tab }}
      >
        <NotificationsTab />
      </Popover>
    </>
  )
}

export default memo(Notifications)
