import { createSelector } from 'reselect'
import { getIsControlWithoutOptions } from '@tabeeb/modules/createFormPage/helpers/controlTypesHelper'
import {
  getFormTitle,
  getFormTitleLowerCase,
  getIsFormActionEnabled,
  getIsMobileFormViewSwitchEnabled,
  getShowMenuAI,
} from '@tabeeb/modules/appConfigState/selectors'
import {
  FormItemType,
  MobileFormViewMode,
  FormControlType,
  ConditionTypes,
  SendEmailConditionType,
} from '@tabeeb/enums'
import { getTenantWorkflows } from '@tabeeb/modules/workflows/selectors'
import { getAvailableForms } from '@tabeeb/modules/forms/selectors'
import { allQuestionTypes } from '../services/allQuestionsTypes'

export const selectPageMode = (state) => state.createFormPage.mode

export const getControlsList = (state) => state.createFormPage.createForm.controlsList
export const getTabsList = (state) => state.createFormPage.createForm.tabsList
export const getSelectedAIObjectsIds = (state) => state.createFormPage.selectedAIObjectsIds
export const getAvailableAIObjects = (state) => state.createFormPage.availableAIObjects
export const getIsAiEnabled = getShowMenuAI
export const getFormViewMode = (state) => state.createFormPage.createForm.formViewMode
export const getIsFormViewModeSwitchVisible = getIsMobileFormViewSwitchEnabled
export const getErrorsList = (state) => state.createFormPage.inputErrorsList
export const getFormName = (state) => state.createFormPage.createForm.formName
export const getIsFormActionVisible = getIsFormActionEnabled
export const getNlpModels = (state) => state.createFormPage.nlpModels
export const getSelectedNlpModelId = (state) => state.createFormPage.createForm.selectedNLPModelId
export const getIsFormSignatureChecked = (state) => state.createFormPage.createForm.isSignatureEnabled
export const getIsSignatureEnabled = (state) => state.createFormPage.createForm.isSignatureEnabled
export const getFilterValue = (state) => state.createFormPage.filterValue
export const getFormToEdit = (state) => state.createFormPage.formToEdit

export const getIsControlDisabled = createSelector(
  [getControlsList, getTabsList, getAvailableAIObjects, getFormViewMode, (_, { control }) => control],
  (controlsList, tabsList, availableAIObjects, formViewMode, control) => {
    let isDisabled = false

    const counterAdded = controlsList && controlsList.some((ctrl) => ctrl.FormItemType === FormItemType.Counter)

    if (control === 'Counter' && (!availableAIObjects || availableAIObjects.length === 0 || tabsList.length > 0)) {
      isDisabled = true
    } else if (control === 'Tabs' && (counterAdded || formViewMode === MobileFormViewMode.List)) {
      isDisabled = true
    }

    return isDisabled
  }
)

export const getIsControlHidden = createSelector(
  [getIsAiEnabled, getIsFormActionVisible, getFormToEdit, (_, { control }) => control],
  (showMenuAI, isFormActionVisible, formToEdit, control) => {
    if (
      (!showMenuAI && control === 'Counter') ||
      (!isFormActionVisible && control === 'Action') ||
      (formToEdit.HasAnswers && control === 'Tabs')
    ) {
      return true
    }
    return false
  }
)

export const getIsFormViewSwitchChecked = createSelector([getFormViewMode], (formViewMode) => {
  return formViewMode === MobileFormViewMode.List
})

export const getIsFormViewSwitchDisabled = createSelector([getTabsList], (tabsList) => {
  const isTabAdded = tabsList && tabsList.length > 0
  return isTabAdded
})

export const getIsFormViewSwitchVisible = createSelector([getIsFormViewModeSwitchVisible], (isVisible) => {
  const visibility = typeof isVisible === 'undefined' ? false : isVisible
  return visibility
})

export const getAvailableQuestionTypes = createSelector(
  [getControlsList, (_, { control }) => control],
  (controlsList, control) => {
    const isTemperatureControlAlreadyExists = controlsList.some((c) => c.Type === FormControlType.BodyTemperature)
    const isTemperatureControl = control.Type === FormControlType.BodyTemperature

    const filteredQuestionTypes =
      isTemperatureControlAlreadyExists && !isTemperatureControl
        ? allQuestionTypes.filter((qType) => qType.type !== FormControlType.BodyTemperature)
        : allQuestionTypes

    return filteredQuestionTypes
  }
)

export const getFormBuilderErrorMessage = createSelector(
  [getControlsList, getTabsList, getErrorsList, getFormName, getFormTitle, getFormTitleLowerCase],
  (controlsList, tabsList, errorsList, formName, formTitle, formTitleLower) => {
    let errorMessage = null
    const errorsCount = errorsList.length

    controlsList.some((control) => {
      const isControlWithoutOptions = getIsControlWithoutOptions(control.ControlType)
      let conditions

      if (isControlWithoutOptions) {
        conditions = control.Conditions && control.Conditions.filter((cond) => cond.Type === ConditionTypes.SendEmail)
      } else {
        conditions =
          control.Options &&
          control.Options.filter((opt) => opt.Conditions && opt.Conditions.length !== 0).map(
            (opt) => opt.Conditions.filter((cond) => cond.Type === ConditionTypes.SendEmail)[0]
          )
      }

      if (conditions) {
        const specificUsersEmailConditions = conditions.map(
          (cond) =>
            cond &&
            cond.ConditionsRecipient.filter((recipient) => recipient.Type === SendEmailConditionType.SpecificUsers)[0]
        )
        if (specificUsersEmailConditions.some((cond) => cond && cond.Recipients.length === 0)) {
          errorMessage = `Please add recipients to the email conditions of the "${control.Label}" control`
          return true
        }
      }

      return false
    })

    if (!formName) errorMessage = `${formTitle} name is required`
    else if (tabsList.length > 0 && controlsList.length === 0)
      errorMessage = `Cannot save ${formTitleLower} with only tab components`
    else if (tabsList.length === 0 && controlsList.length === 0)
      errorMessage = `Cannot save ${formTitleLower} without any components`
    else if (errorsCount > 0) {
      errorMessage =
        errorsCount === 1
          ? `There is one invalid input field in the ${formTitleLower}`
          : `There are several invalid input fields in the ${formTitleLower}`
    } else {
      const isEmptyInfo = controlsList.some((control) => control.Info && !control.Info.Description && !control.Info.Url)

      if (isEmptyInfo) {
        errorMessage = `Either a description or a file must be present in ${formTitleLower} control info.`
      }
    }

    return errorMessage
  }
)

export const getControlErrors = createSelector([getErrorsList, (_, { control }) => control], (errorsList, control) => {
  const controlErrors = errorsList.filter(
    (error) => error.ControlType === control.FormItemType && error.ControlId === control.TempId
  )

  return controlErrors
})

export const getSelectedNlpModel = createSelector(
  [getNlpModels, getSelectedNlpModelId],
  (nlpModels, selectedNLPModelId) => {
    const page = nlpModels.find((model) => model.Id === selectedNLPModelId)
    return page
  }
)

export const selectAdvancedFormSettings = (state) => state.createFormPage.createForm.settings.advanced

export const getAvailableConditions = createSelector(
  [getFormTitle, getTenantWorkflows, getAvailableForms],
  (formTitle, tenantWorkflows, availableForms) => {
    let conditions = [
      {
        type: ConditionTypes.AttachForm,
        label: `Attach ${formTitle}`,
      },
      {
        type: ConditionTypes.SendEmail,
        label: 'Send Email',
      },
      {
        type: ConditionTypes.AddVideoByUrl,
        label: 'Add Video By URL',
      },
      {
        type: ConditionTypes.ShowInfoMessage,
        label: 'Show info pop-up',
      },
      {
        type: ConditionTypes.RunWorkflow,
        label: 'Run workflow',
      },
    ]

    if (tenantWorkflows?.length < 1) {
      conditions = conditions.filter((c) => c.type !== ConditionTypes.RunWorkflow)
    }

    if (availableForms?.length < 1) {
      conditions = conditions.filter((c) => c.type !== ConditionTypes.AttachForm)
    }

    return conditions
  }
)

export const getAvailableControls = createSelector([getFilterValue, getTenantWorkflows], (filterValue, workflows) => {
  let paletteControls = []
  switch (filterValue) {
    case 'text':
      paletteControls = ['Textbox', 'Textarea']
      break
    case 'checkbox':
      paletteControls = ['Checkbox List']
      break
    case 'select':
      paletteControls = ['Dropdown', 'Radiobuttons']
      break
    case 'tabs':
      paletteControls = ['Tabs']
      break
    case 'counter':
      paletteControls = ['Counter']
      break
    default:
      paletteControls = ['Tabs', 'Counter', 'Textbox', 'Textarea', 'Checkbox List', 'Radiobuttons', 'Dropdown', 'Date']

      if (workflows.length > 0) {
        paletteControls.push('Action')
      }

      break
  }

  return paletteControls
})
