import { combineActions, handleActions } from 'redux-actions'

import { getDefaultChatBot } from '@tabeeb/modules/assistants/actions'

import { resetChatState } from '../actions'

const defaultState = null

export default handleActions(
  {
    [getDefaultChatBot.success](state, action) {
      return action.response.data
    },
    [combineActions(getDefaultChatBot.failed, resetChatState)](state, action) {
      return defaultState
    },
  },
  defaultState
)
