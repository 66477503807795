import { Sema } from 'async-sema'
import { heicTo, isHeic } from 'heic-to'

const PARALLEL_REQUESTS_COUNT = 50

const semaphore = new Sema(PARALLEL_REQUESTS_COUNT, {
  capacity: PARALLEL_REQUESTS_COUNT,
})

const loadImage = (src) => {
  return new Promise((resolve) => {
    const image = new Image()

    image.onload = () => {
      resolve(image)
    }

    image.src = src
  })
}

export const getImageByUrl = async ({ src, abortController = null }) => {
  await semaphore.acquire()

  try {
    const url = new URL(src)

    // eslint-disable-next-line compat/compat
    const response = await fetch(url, {
      signal: abortController?.signal,
    })

    let blob = await response.blob()

    if (await isHeic(blob)) {
      try {
        blob = await heicTo({
          blob,
          type: 'image/jpeg',
          quality: 0.8,
        })
      } catch (e) {
        console.error(e)
      }
    }

    if (abortController?.signal.aborted) {
      return null
    }
    const image = await loadImage(URL.createObjectURL(blob))

    return image
  } finally {
    semaphore.release()
  }
}
