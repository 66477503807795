import { createSelector } from 'reselect'

import {
  FolderRoleDisplayName,
  FolderUserRole,
  SessionRoleDisplayName,
  UserRole,
  UserTenantRole,
  UserTenantRoleDisplayName,
  UserTenantRoleId,
} from '@tabeeb/enums'
import { isMobileOnly } from 'react-device-detect'
import { camelCase, toUpper } from 'lodash'
import domainService from '@tabeeb/services/domainService'
import { DEFAULT_SESSIONS_TITLE, DEFAULT_TRAINING_MATERIALS_TITLE } from '@tabeeb/shared/constants'
import { RootState } from '@tabeeb/store'

const _ = require('lodash')
_.mixin(require('lodash-inflection'))

export const getConfig = (state: RootState) => state.appConfigState

/**
 * @deprecated use `domainService.subdomain` instead. This selector is only used for a backwards compatibility
 */
export const getSubdomain = () => domainService.subdomain
export const getTenantName = (state: RootState) => state.appConfigState.name
export const getBannerUrl = (state: RootState) => state.appConfigState.bannerUrl
export const getBannerLink = (state: RootState) => state.appConfigState.bannerLink
export const getMainBannerColor = (state: RootState) => state.appConfigState.mainBanner || '#101010'
export const getMainBackgroundColor = (state: RootState) => state.appConfigState.mainBGColor || '#FFFFFF'
export const getMainTextColor = (state: RootState) => state.appConfigState.mainTextColor || '#A0A0A0'
export const getMainSelectedTextColor = (state: RootState) => state.appConfigState.mainSelectedTextColor || '#FFFFFF'

export const getIsSwitchTenantFeatureEnabled = (state: RootState) =>
  String(state.appConfigState.isSwitchTenantFeatureEnabled).toLowerCase() === 'true'

export const getLogoUrl = (state: RootState) => state.appConfigState.logoUrl
export const getIntroImageUrl = (state: RootState) => state.appConfigState.introImageUrl

export const getLoginBackgroundImage = (state: RootState) => state.appConfigState.loginBackgroundImage
export const getLoginButtonColor = (state: RootState) => state.appConfigState.loginColor
export const getLoginPageTextColor = (state: RootState) => state.appConfigState.homeTextColor
export const getLoginPageBackgroundColor = (state: RootState) => state.appConfigState.loginBackgroundColor
export const getLoginBoudingBoxBackgroundColor = (state: RootState) => state.appConfigState.homeBanner

export const getNotificationColor = (state: RootState) => state.appConfigState.notificationColor || '#0089cf'

export const getAddButtonColor = (state: RootState) => state.appConfigState.addButtionColor || '#e0e0e0'
export const getExportButtonColor = (state: RootState) => state.appConfigState.exportButtonColor || '#e0e0e0'

export const getIsFormSignatureEnabledInTenant = (state: RootState) =>
  Boolean(state.appConfigState.isFormSignatureEnabled)

export const getSessionTitle = createSelector(
  [(state: RootState) => state.appConfigState.sessionsNavbarTitle],
  (sessionsNavbarTitle) => {
    let title = sessionsNavbarTitle
    if (title) {
      title = _.trim(sessionsNavbarTitle)
    }

    return title || DEFAULT_SESSIONS_TITLE
  }
)

export const getIsAccountDeletionEnabled = (state: RootState) => Boolean(state.appConfigState.isAccountDeletionEnabled)

export const getIsGeosearchEnabled = (state: RootState) => Boolean(state.appConfigState.showMenuGeosearch)

export const getIsTrackingMapEnabled = (state: RootState) => Boolean(state.appConfigState.isTrackingMapEnabled)
export const getIsSessionPublishingEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isSessionPublishingEnabled)

export const getIsAppConfigBillingEnabled = (state: RootState) => state.appConfigState.isBillingEnabled

export const getIsBillingEnabled = createSelector([getIsAppConfigBillingEnabled], (isBillingEnabled) => {
  return String(isBillingEnabled).toLowerCase() === 'true'
})
export const getIsChatEnabled = (state: RootState) => Boolean(state.appConfigState.isChatEnabled)
export const getIsChatBotsFeatureEnabled = (state: RootState) => Boolean(state.appConfigState.isChatBotsEnabled)
export const getIsChatTranslationEnabled = (state: RootState) => Boolean(state.appConfigState.isChatTranslationEnabled)

export const getShowMenuAI = (state: RootState) => Boolean(state.appConfigState.showMenuAI)
export const getShowAnalyticsMenu = (state: RootState) => Boolean(state.appConfigState.showMenuAnalytics)
export const getShowStructureModel = (state: RootState) => Boolean(state.appConfigState.showStructureModel)
export const getShowBentleyAnalyticsReport = (state: RootState) =>
  Boolean(state.appConfigState.showBentleyAnalyticsReport)
export const getShow2dLineDrawing = (state: RootState) => Boolean(state.appConfigState.show2dLineDrawing)
export const getIs3DModelCreationLimitEnabled = (state: RootState) =>
  Boolean(state.appConfigState.is3DModelCreationLimitEnabled)

export const getIsDetectObjects = (state: RootState) => Boolean(state.appConfigState.isDetectObjects)

export const getIsFormPublishingEnabled = (state: RootState) => Boolean(state.appConfigState.isFormPublishingEnabled)
export const getSelectedTenantsForFormPublishing = (state: RootState) =>
  state.appConfigState.selectedTenantsForFormPublishing || []

export const getSelectedTenantsForContentSharing = (state: RootState) =>
  state.appConfigState.selectedTenantsForSharing || []

export const getShowProcessingByVQAModelButton = (state: RootState) =>
  Boolean(state.appConfigState.showProcessingByVQAModelButton)

export const getIsAIExpressionsEnabled = (state: RootState) => Boolean(state.appConfigState.isAIExpressionsEnabled)

export const getIsVideoCallsEnabled = (state: RootState) => Boolean(state.appConfigState.isVideoCallsEnabled)

export const getIsContentMetadataEnabled = (state: RootState) => Boolean(state.appConfigState.isContentMetadataEnabled)
export const getContentMetadataButtonLabel = (state: RootState) =>
  state.appConfigState.contentMetadataButtonLabel || 'Content Metadata'
export const getContentMetadataPosition = (state: RootState) => state.appConfigState.contentMetadataPosition

export const getIsDeviceSwitchingEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isDeviceSwitchingEnabled) && !isMobileOnly
export const getIsSoundNotificationOnCallJoinEnabled = (state: RootState) =>
  Boolean(state.appConfigState.soundNotificationOnCallJoin)
export const getShowGridViewButton = (state: RootState) => Boolean(state.appConfigState.showGridViewButton)

export const getFormsTitle = (state: RootState) =>
  camelCase(state.appConfigState.formsTitle).replace(/^(.)/, toUpper) || 'Forms'
export const getFormsTitleLowerCase = createSelector([getFormsTitle], (formsTitle) => {
  return formsTitle.toLowerCase()
})
export const getFormTitle = createSelector([getFormsTitle], (formsTitle) => {
  return _.singularize(formsTitle)
})
export const getFormTitleLowerCase = createSelector([getFormTitle], (formTitle) => {
  return formTitle.toLowerCase()
})

export const getIsCalendarEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isCalendarEnabled && !isMobileOnly)

export const getIsPointCloudModelsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isPointCloudModelsEnabled)

export const getIsAutodeskViewerEnabled = (state: RootState) => Boolean(state.appConfigState.isAutodeskViewerEnabled)

export const getIsRecordingEnabled = (state: RootState) => Boolean(state.appConfigState.isRecordingEnabled)
export const getIsLocalRecordingEnabled = (state: RootState) => Boolean(state.appConfigState.isLocalRecordingEnabled)
export const getIsCloudRecordingEnabled = (state: RootState) => Boolean(state.appConfigState.isCloudRecordingEnabled)
export const getIsMultipleRecordingsAvailable = (state: RootState) =>
  Boolean(getIsLocalRecordingEnabled(state) && getIsCloudRecordingEnabled(state))

export const getIsInContentReviewEnabled = (state: RootState) => Boolean(state.appConfigState.isInContentReviewEnabled)

export const getIsCreatePublishFormRestrictionEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isCreatePublishFormRestrictionEnabled)

export const getIsSessionGuestRoleEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isSessionGuestRoleEnabled)

export const getisViewingAdminTenantRoleEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isViewingAdminTenantRoleEnabled)

export const getisBillingSettingsTenantRoleEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isBillingSettingsRoleEnabled)

export const getShowHealthData = (state: RootState) => Boolean(state.appConfigState.showHealthData)
export const getShowTabletsAssignation = (state: RootState) => Boolean(state.appConfigState.showTabletsAssignation)

export const getIsAdvancedFormSettingsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isAdvancedFormSettingsEnabled)

export const getIsInviteSettingsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isAdvancedInviteSettingsEnabled)

export const getIsMfaEnabled = (state: RootState) => Boolean(state.appConfigState.isMfaEnabled)

export const getShowCertificates = (state: RootState) => Boolean(state.appConfigState.showCertificates)

export const getQualificationsEnabled = (state: RootState) => Boolean(state.appConfigState.showQualifications)
export const getQualificationsForInvitesEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isQualificationsForInvitesEnabled)

export const getIsContentSharingSettingEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isSessionSharingEnabled)
export const getIsContentReviewingSettingEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isSessionReviewingEnabled)
export const getIsContentsLinkingSettingEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isContentLinkingEnabled)
export const getIsShowFormUsersFilter = (state: RootState) => Boolean(state.appConfigState.showFormUsers)
export const getIsMergeForReviewEnabled = (state: RootState) => Boolean(state.appConfigState.isMergeForReviewEnabled)

export const getCustomTenantRoleDisplayNames = (state: RootState): { [key in UserTenantRole]?: string } => ({
  [UserTenantRole.Guest]: state.appConfigState.guestRoleDisplayName,
})

const getContentGuestRoleName = (state: RootState) => state.appConfigState.sessionGuestRoleDisplayName

const getCustomSessionRoleDisplayNames = createSelector(getContentGuestRoleName, (guestName) => ({
  [UserRole.SessionGuest]: guestName,
}))

const getCustomFolderRoleDisplayNames = (state: RootState) => ({
  [FolderUserRole.SessionGuest]: state.appConfigState.sessionGuestRoleDisplayName,
})

export const getTenantRoleDisplayName = (state: RootState, { Role }: { Role: UserTenantRole }) => {
  const originalRoleName = UserTenantRoleDisplayName[Role]
  const customRoleName = getCustomTenantRoleDisplayNames(state)[Role]

  return customRoleName || originalRoleName
}

export const getContentRoles = createSelector(getCustomSessionRoleDisplayNames, (customNames) => {
  const contentRoles = Object.entries(SessionRoleDisplayName).map(([roleId, roleName]) => ({
    id: roleId,
    name: customNames[parseInt(roleId, 10)] || roleName,
  }))
  return _.keyBy(contentRoles, 'id')
})

export const getSessionRoleDisplayName = createSelector(
  [getContentRoles, (state, { Role: roleId }) => roleId],
  (roles, roleId) => {
    return roles[roleId].name
  }
)

export const getFolderRoleDisplayName = (state: RootState, { Role }: { Role: number }) => {
  const originalRoleName = FolderRoleDisplayName[Role]
  const customRoleName = getCustomFolderRoleDisplayNames(state)[Role]

  return customRoleName || originalRoleName
}

export const getTenantRoles = (state: RootState) => {
  return [
    UserTenantRole.BillingAdmin,
    UserTenantRole.TenantAdmin,
    UserTenantRole.SessionAdmin,
    UserTenantRole.AIAdmin,
    UserTenantRole.LabelingAdmin,
    UserTenantRole.User,
    UserTenantRole.Guest,
    UserTenantRole.ViewingAdmin,
    UserTenantRole.BillingSettingsAdmin,
  ].map((role) => ({
    id: UserTenantRoleId[role],
    name: getTenantRoleDisplayName(state, { Role: role }),
  }))
}

export const getIsPageFolderAuthorizationEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isPageFolderAuthorizationEnabled)

export const getIsModelTrainingSettingEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isModelTrainingEnabled)

export const getShowReminderButton = (state: RootState) => Boolean(state.appConfigState.showReminders)

export const getIsCustomIframeEnabled = (state: RootState) => Boolean(state.appConfigState.isCustomIframeEnabled)
export const getCustomIframeUrl = (state: RootState) => state.appConfigState.customIframeUrl
export const getCustomIframeTitle = (state: RootState) => state.appConfigState.customIframeNomenclature

export const getIsAIReportEnabled = (state: RootState) => Boolean(state.appConfigState.isAIReportEnabled)
export const getAIReportLabel = (state: RootState) => state.appConfigState.aiReportLabel || 'Inventory Analysis'
export const getIsTableRecognitionEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isTableRecognitionEnabled)

export const getIsExportCustomFormCsvEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isExportCustomFormCsvEnabled)

export const getIsTenantAnnouncementsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isTenantAnnouncementsEnabled)

export const getIsPdfExportEmailRecipientsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.showPdfRecieversPopup)
export const getIsImagesExportEmailRecipientsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.showImageRecieversPopup)

export const getIsSessionSharingEnabled = (state: RootState) => Boolean(state.appConfigState.isSessionSharingEnabled)
export const getIsBulkCopyEnabled = (state: RootState) => Boolean(state.appConfigState.isBulkCopyEnabled)

export const getIsIncomingContractsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isIncomingContractsEnabled)
export const getIsOutgoingContractsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isOutgoingContractsEnabled)

export const getSelectedTenantsForContracts = (state: RootState) =>
  state.appConfigState.selectedTenantsForContracts || []

export const getShowTimeZoneOnUploadAnswers = (state: RootState) =>
  Boolean(state.appConfigState.showTimeZoneOnUploadAnswers)

export const getIsTrainingMaterialsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isTrainingMaterialsEnabled)
export const getIsBulkImportEnabled = (state: RootState) => Boolean(state.appConfigState.isBulkImportEnabled)

export const getTrainingMaterialsTitle = (state: RootState) =>
  _.trim(state.appConfigState.trainingMaterialsTitle || DEFAULT_TRAINING_MATERIALS_TITLE)

export const getIsSpreadsheetsImportEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isSpreadsheetsImportEnabled)

export const getContentCreationRestricted = (state: RootState) =>
  Boolean(state.appConfigState.contentCreationRestricted)

export const getIsUnreadSessionChatsIndicatorEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isUnreadSessionChatsIndicatorEnabled)

export const getCreateFolderButtonColor = (state: RootState) => state.appConfigState.createFolderButtonColor

export const getIsGalleryExpansionEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isGalleryExpansionEnabled)

export const getIsBentleyBoundingBoxManualInputEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isBentleyBoundingBoxManualInputEnabled)

export const getVideoBridgeRegions = (state: RootState) => state.appConfigState.videoBridgeRegions

export const getShowVideobridgeRegionDropdown = (state: RootState) =>
  Boolean(state.appConfigState.showVideobridgeRegionDropdown)

export const getUtilityInfrastructureAnalysisWorkflowId = (state: RootState) =>
  state.appConfigState.utilityInfrastructureAnalysisWorkflowId

export const getIsConditionsEnabled = (state: RootState) => Boolean(state.appConfigState.isConditionsEnabled)

export const getShowSwitchToAttachedFormDialog = (state: RootState) =>
  Boolean(state.appConfigState.showSwitchToAttachedFormDialog)

export const getIsTenantContentsCustomDashboardsEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isTenantContentsCustomDashboardsEnabled)

export const getShowAllSessionsOriginalTenantColumn = (state: RootState) =>
  Boolean(state.appConfigState.showAllSessionsOriginalTenantCol)

export const getShowAdvancedFilterButton = (state: RootState) => Boolean(state.appConfigState.showAdvancedFilterButton)

export const getUseSessionNameConvention = (state: RootState) => Boolean(state.appConfigState.useSessionNameConvention)

export const getIsFormsAndUsersTabCollapsed = (state: RootState) =>
  Boolean(state.appConfigState.isFormsAndUsersTabCollapsed)

export const getOpenNamingFormForCopiedSessions = (state: RootState) =>
  Boolean(state.appConfigState.openNamingFormForCopiedSessions)

export const getIsFormActionEnabled = (state: RootState) => Boolean(state.appConfigState.isFormActionEnabled)

export const getIsMobileFormViewSwitchEnabled = (state: RootState) =>
  Boolean(state.appConfigState.isMobileFormViewSwitchEnabled)

export const getContextCaptureGigapixelLimit = (state: RootState) => state.appConfigState.contextCaptureGigapixelLimit

export const getShowExportFormToPdfButton = (state: RootState) =>
  Boolean(state.appConfigState.showExportFormToPdfButton)

export const getIsFormRecordsEnabled = (state: RootState) => Boolean(state.appConfigState.isFormRecordEnabled)

export const getIsControlInfoEnabled = (state: RootState) => Boolean(state.appConfigState.isControlInfoEnabled)
