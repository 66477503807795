import { memo, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { UserRole } from '@tabeeb/enums'
import { usersSelectors } from '@tabeeb/modules/../users'
import { contentStateSelectors } from '@tabeeb/shared/content'
import useAudioRecorder from '@tabeeb/shared/utils/hooks/useAudioRecorder'
import { uploadAudioFileToChatBlobStorageRequest, sendMessage } from '../actions'
import ChatInput from '../components/ChatInput'

import { useChatContext } from '../contexts'
import { getAIAssistantChatBot } from '../selectors'

const ENTER_KEY = 13
const KEY_DOWN_EVENT = 'keydown'
const RECORDING_LIMIT = 60000

const ChatInputContainer = () => {
  const dispatch = useDispatch()
  const [currentMessage, setCurrentMessage] = useState('')
  const [audioFile, setAudioFile] = useState(null)

  const { userId: receiverId, topicId, topicType } = useChatContext()

  const contentId = useSelector(contentStateSelectors.getContentId)
  const disabled = useSelector((state) => {
    const user = usersSelectors.getUserById(state, { Id: receiverId })
    if (!user) {
      return false
    }

    if (user.role === UserRole.AIUser) {
      return !getAIAssistantChatBot(state)
    }

    return user.isDeleted || user.isAccountDeleted
  })

  const supportAudioMessages = useSelector((state) => {
    const user = usersSelectors.getUserById(state, { Id: receiverId })
    if (user?.role === UserRole.AIUser) {
      return false
    }

    return true
  })

  useEffect(() => {
    if (audioFile) {
      const sendFile = (event) => {
        event.preventDefault()

        if (event.keyCode === ENTER_KEY) {
          dispatch(
            uploadAudioFileToChatBlobStorageRequest({
              fileData: audioFile.data,
              fileDuration: audioFile.duration,
              receiverId,
              contentId,
              topicId,
              topicType,
            })
          )

          setAudioFile(null)
        }
      }

      document.addEventListener(KEY_DOWN_EVENT, sendFile)

      return () => {
        document.removeEventListener(KEY_DOWN_EVENT, sendFile)
      }
    }
  }, [dispatch, audioFile, receiverId, contentId, topicId, topicType])

  const onRecordingCompleteHandler = useCallback(({ isSuccess, file, error }) => {
    if (isSuccess) {
      setAudioFile(file)
    } else {
      console.log(error)
    }
  }, [])

  const [isRecording, recordingDuration, startRecording, stopRecording] = useAudioRecorder({
    onComplete: onRecordingCompleteHandler,
    limit: RECORDING_LIMIT,
  })

  const onStartRecordingHandler = useCallback(() => {
    setAudioFile(null)
    startRecording()
  }, [startRecording])

  const onStopRecordingHandler = useCallback(() => {
    stopRecording()
  }, [stopRecording])

  const onRemoveAudioFileHandler = useCallback(() => {
    setAudioFile(null)
  }, [])

  const onKeyPressHandler = useCallback(
    (event) => {
      if (event.which === ENTER_KEY) {
        event.preventDefault()
        if (currentMessage.length > 0) {
          dispatch(sendMessage({ message: currentMessage, receiverId, topicId, topicType }))
          setCurrentMessage('')
        }
      }
    },
    [dispatch, currentMessage, receiverId, topicId, topicType]
  )

  const onChangeTextHandler = useCallback((event) => {
    setCurrentMessage(event.target.value)
  }, [])

  if (disabled) {
    return null
  }

  return (
    <ChatInput
      {...{
        currentMessage,
        isRecording,
        isFileReady: Boolean(audioFile),
        supportAudioMessages,
        recordingDuration: moment(audioFile?.duration || recordingDuration).format('mm:ss.S'),
        onStartRecording: onStartRecordingHandler,
        onStopRecording: onStopRecordingHandler,
        onKeyPress: onKeyPressHandler,
        onChangeText: onChangeTextHandler,
        onRemoveAudioFile: onRemoveAudioFileHandler,
      }}
    />
  )
}

export default memo(ChatInputContainer)
