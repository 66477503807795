import { heicTo } from 'heic-to'

export async function rotateImage(image) {
  try {
    const { default: loadImage } = await import('blueimp-load-image')

    const loadResult = await loadImage(image, { orientation: true, canvas: true })

    const getCanvasBlob = (canvas, type) => {
      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob)
          } else {
            reject(new Error(`Cannot convert image '${image.name}' into blob`))
          }
        }, type)
      })
    }

    const blob = await getCanvasBlob(loadResult.image, image.type)
    blob.name = image.name

    URL.revokeObjectURL(image.src)
    delete loadResult.image.src

    return blob
  } catch {
    return image
  }
}

export async function convertHeicHeifFileToJpeg(imageFile) {
  try {
    const convertedBlob = await heicTo({
      blob: imageFile,
      type: 'image/jpeg',
      quality: 0.8,
    })

    convertedBlob.name = imageFile.name.replace(/\.[^.]+$/, '.jpeg')

    return convertedBlob
  } catch {
    return imageFile
  }
}

export async function getFileMetaInfo(file) {
  const { default: exifr } = await import('exifr')

  const [gps, orientation] = await Promise.all([exifr.gps(file), exifr.orientation(file)])

  return { latitude: gps?.latitude, longitude: gps?.longitude, orientation }
}
