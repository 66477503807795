import { put, takeLatest, select, all } from 'redux-saga/effects'
import { FormItemType } from '@tabeeb/enums'
import { onAddInfoNotification } from '@tabeeb/modules/notification/actions'
import * as rawActions from '../actions'
import { hasControlBeenUpdated } from '../services/hasControlBeenUpdated'

function* setFormControls(action) {
  const { data } = action.response
  const { contentId } = yield select((state) => state.contentState)

  if (data) {
    const controls = data.Controls
    const counters = data.Counters.map((counter) => ({ ...counter, FormItemType: FormItemType.Counter }))
    const actions = data.Actions.map((act) => ({ ...act, FormItemType: FormItemType.Action }))
    data.Controls = [...controls, ...counters, ...actions].sort((a, b) => a.Order - b.Order)
    yield put(rawActions.updateForm(data))

    const { contentForms } = yield select((state) => state.forms)
    const isExist = contentForms.some((e) => e.ContentFormId === action.payload.contentFormId)

    if (isExist) {
      yield put(rawActions.getAnswersStatusesCountRequest({ contentFormId: data.Id }))
    } else if (contentForms.length > 0) {
      yield put(rawActions.getFormControls.request({ contentFormId: contentForms[0].Id }))
    }
    if (contentId) {
      if (data.Tabspanels && data.Tabspanels.length > 0) {
        yield put(rawActions.setSelectedTabs(data.Tabspanels.map((panel) => panel.Tabs[0].Id)))
      } else yield put(rawActions.setSelectedTabs([]))
    }
  }
}

function* getContentFormControls(action) {
  const { data } = action.response
  if (data) {
    yield put(rawActions.updateContentForm(data))
  }
  const { contentForms } = yield select((state) => state.forms)
  const updatedForm = contentForms.find((form) => form.Id === data.Id)
  const controlHasBeenChanged = hasControlBeenUpdated(updatedForm)
  if (controlHasBeenChanged) yield put(onAddInfoNotification({ message: `'${updatedForm.Name}' has been updated` }))
}

function* formControls() {
  yield all([
    takeLatest(rawActions.getFormControls.success, setFormControls),
    takeLatest(rawActions.getContentFormControlsSuccess, getContentFormControls),
  ])
}

export default formControls
