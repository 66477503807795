import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { put, takeLatest, select, all, take } from 'redux-saga/effects'
import { updateUserSignature } from '@tabeeb/modules/account/actions'
import { onAddSuccessNotification } from '@tabeeb/modules/notification/actions'
import { ContentFormRecordType } from '@tabeeb/enums'
import * as rawActions from '../actions'
import { getCurrentForm } from '../selectors'

function* setCurrentTabs(action) {
  const { payload } = action
  const form = yield select(getCurrentForm)
  const selectedTabs = payload || form.Tabspanels.map((panel) => panel.Tabs[0].Id)

  yield put(rawActions.setSelectedTabs(selectedTabs))
}

function* openForm(action) {
  const contentFormId = action.payload
  yield put(rawActions.setCurrentForm({}))
  yield put(rawActions.getFormControls.request({ contentFormId }))
}

function* saveFormRecord() {
  const form = yield select(getCurrentForm)
  const currentUserId = yield select(getCurrentUserId)

  const data = {
    contentFormId: form.Id,
    userId: currentUserId,
    type: ContentFormRecordType.Manual,
  }

  yield put(rawActions.saveFormRecordRequest(data))
}

function* createUserSignature({ payload }) {
  const form = yield select(getCurrentForm)
  const currentUserId = yield select(getCurrentUserId)

  yield put(updateUserSignature.request(payload))
  yield take(updateUserSignature.success)

  const data = {
    contentFormId: form.Id,
    userId: currentUserId,
    type: ContentFormRecordType.UserSignature,
  }

  yield put(rawActions.saveFormRecordRequest(data))
}

function* signForm() {
  const form = yield select(getCurrentForm)
  const currentUserId = yield select(getCurrentUserId)

  const data = {
    contentFormId: form.Id,
    userId: currentUserId,
    type: ContentFormRecordType.UserSignature,
  }

  yield put(rawActions.saveFormRecordRequest(data))
}

function* saveFormRecordSuccess() {
  yield put(onAddSuccessNotification({ message: 'The form record created successfully' }))
}

function* updateUserSignatureSuccess() {
  yield put(onAddSuccessNotification({ message: 'The signature updated successfully' }))
}

function* currentForm() {
  yield all([
    takeLatest(rawActions.openForm, openForm),
    takeLatest(rawActions.setCurrentTabs, setCurrentTabs),
    takeLatest(rawActions.saveFormRecord, saveFormRecord),
    takeLatest(rawActions.createUserSignature, createUserSignature),
    takeLatest(rawActions.signForm, signForm),
    takeLatest(rawActions.saveFormRecordSuccess, saveFormRecordSuccess),
    takeLatest(updateUserSignature.success, updateUserSignatureSuccess),
  ])
}

export default currentForm
