import { put, takeLatest, select, all } from 'redux-saga/effects'
import { head } from 'lodash'

import * as rawActions from '../actions'
import { exportFormToPdfFailed, exportFormToPdfRequest, exportFormToPdfSuccess } from '../../forms/actions'
import updateOrAddGallery from './updateOrAddGallery'
import * as notificationActions from '../../notification/actions'
import { contentNotificationService } from '../../shared/content'
import { getInsertionIndex } from '../selectors'

function* addGalleryUploadedPdf(action) {
  const pdfDocument = head(action.payload)
  const { selectedFolderId } = yield select((state) => state.gallery.galleryState)
  const contentId = yield select((state) => state.contentState.contentId)
  const insertionIndex = yield select((state) => getInsertionIndex(state))

  const timeOffset = new Date().getTimezoneOffset()
  const data = {
    contentId,
    insertionIndex,
    url: pdfDocument.url,
    folderId: selectedFolderId,
    timeZoneOffset: -timeOffset,
  }

  yield put(rawActions.addGalleryPdfDocumentRequest(data))
}

function* addGalleryUploadPdf(action) {
  const pages = action.response.data
  const { suppressMessage, disableNotificationMessage } = action.payload
  const isSelectAddedGalleryItem = false
  yield updateOrAddGallery(isSelectAddedGalleryItem, pages, suppressMessage)
  yield contentNotificationService.notifyOfNewPages(pages, disableNotificationMessage)
}

function* notifyExportFormToPdfRequestSent() {
  yield put(
    notificationActions.onAddInfoNotification({ message: 'An exported PDF will be added to the Gallery shortly' })
  )
}

function* notifyExportFormToPdfRequestFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to create a PDF page.' }))
}

function* addPdf() {
  yield all([
    takeLatest(rawActions.addGalleryPdfDocumentsUploadFilesSuccess, addGalleryUploadedPdf),
    takeLatest(exportFormToPdfRequest, notifyExportFormToPdfRequestSent),
    takeLatest(exportFormToPdfFailed, notifyExportFormToPdfRequestFailed),
    takeLatest([rawActions.addGalleryPdfDocumentSuccess, exportFormToPdfSuccess], addGalleryUploadPdf),
  ])
}

export default addPdf
